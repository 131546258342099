import { useQuery } from "@tanstack/react-query";
import { signInApi, getCurrentUser } from "../api/Api";

export const authHook = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  token: localStorage.getItem('token'),
  async signIn(email, password) {
    const response = await signInApi({ email, password });
    const { data } = response;
    const { access_token } = data;

    if (access_token) {
      localStorage.setItem('token', access_token)
      this.isAuthenticated = true;
      this.token = access_token;
    } else {
      throw Error('Something went wrong')
    }
  },
  autoSignInAfterRegister(access_token) {
    if (access_token) {
      localStorage.setItem('token', access_token)
      this.isAuthenticated = true;
      this.token = access_token;
    } else {
      throw Error('Something went wrong')
    }
  }
}

const STALE_TIME = 60 * 60 * 1000; // 1hr

const fetchCurrentUser = async () => {
  const response = await getCurrentUser();
  const { user } = response.data;

  return user ?? null;
}

const useCurrentUser = () => {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: () => fetchCurrentUser(),
    staleTime: STALE_TIME
  })
}

export { useCurrentUser }
