import React, { useState } from "react";
import "./createevent-popups.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CirclePicker } from "react-color";
import { CalendarColors } from "../../../../constants/colors";
import {
  updateCalendar as updateCalendarApi,
  deleteCalendar as deleteCalendarApi,
  transferCalendar as transferCalendarApi,
} from "../../../../api/Api";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const SettingPopup = ({
  handleCloseSettingPopup,
  selectedCategory,
  fetchCalendars,
  fetchEvents,
  currentProject,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(selectedCategory.text);
  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const [color, setColor] = useState(selectedCategory.color);
  const handleColor = (event) => {
    setColor(event.target.value);
  };

  const [openDeleteCalendar, setOpendeleteCalendarModal] = useState(false);

  const handleDeleteCalendar = async () => {
    await deleteCalendarApi(selectedCategory.id);
    await fetchCalendars();
    await fetchEvents();
    setOpendeleteCalendarModal(false);
    handleCloseSettingPopup();
  };

  const handleTransferCalendar = async () => {
    await transferCalendarApi(selectedCategory.id, currentProject.id);
    await fetchCalendars();
    await fetchEvents();
    setOpendeleteCalendarModal(false);
    handleCloseSettingPopup();
  };

  const handleSubmitEdit = async () => {
    await updateCalendarApi(selectedCategory.id, {
      color: color,
      text: title,
      active: true,
    });
    await fetchCalendars();
    await fetchEvents();
    handleCloseSettingPopup();
  };

  return (
    <div className="settingpopup">
      <div className="settingpopup-header">
        <div className="settingpopup-header-title">
          <p>{t("SERVICES.CALENDAR.EDIT_CALENDAR")}</p>
        </div>

        <div className="settingpopup-header-btn">
          <IconButton onClick={handleCloseSettingPopup}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className="settingpopup-list">
        {!selectedCategory.isDefault && (
          <div className="settingpopup-item flex">
            <p className="settingpopup-item-title">
              {t("SERVICES.CALENDAR.CALENDAR_FORM.TITLE")}
            </p>

            <FormControl fullWidth>
              <TextField
                className="settingpopup-item-input"
                value={title}
                onChange={handleTitle}
              />
            </FormControl>
          </div>
        )}

        <div className="settingpopup-item flex">
          <p className="settingpopup-item-title">
            {t("SERVICES.CALENDAR.CALENDAR_FORM.COLOR")}
          </p>

          <FormControl fullWidth>
            <Select
              className="settingpopup-item-select"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={color}
              onChange={handleColor}
            >
              <MenuItem value={color}>
                <div className="settingpopup-item-select-container">
                  {color}{" "}
                  <span
                    style={{ backgroundColor: color }}
                    className="bullet"
                  ></span>
                </div>
              </MenuItem>

              <MenuItem
                disableRipple={true}
                className="settingpopup-item-select-color"
              >
                <CirclePicker
                  colors={CalendarColors}
                  onChangeComplete={(e) => {
                    setColor(e.hex);
                    console.log(color);
                  }}
                />
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="settingpopup-btn">
          {!selectedCategory.isDefault && (
            <Button
              className="settingpopup-btn-reset"
              onClick={() => setOpendeleteCalendarModal(true)}
            >
              {t("ACTIONS.DELETE")}
            </Button>
          )}
          <Button
            className="settingpopup-btn-submit"
            onClick={handleSubmitEdit}
          >
            {t("ACTIONS.SAVE")}
          </Button>
        </div>
      </div>

      <Modal
        hideBackdrop
        open={openDeleteCalendar}
        onClose={() => setOpendeleteCalendarModal(false)}
        className="cloud-page__header_invoice-manager-details_add-modal"
      >
        <Box
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 3,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ marginBottom: 3 }}
            className="mb-5"
            variant="h6"
            component="h6"
          >
            {t("SERVICES.CALENDAR.CALENDAR_FORM.DELETE_CONFIRM")}
          </Typography>
          <Button
            sx={{ marginRight: 2 }}
            variant="outlined"
            color="inherit"
            onClick={() => setOpendeleteCalendarModal(false)}
          >
            {t("ACTIONS.CANCEL")}
          </Button>
          <Button
            sx={{ marginRight: 2 }}
            variant="outlined"
            color="error"
            onClick={() => handleDeleteCalendar()}
          >
            {t("ACTIONS.DELETE")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleTransferCalendar()}
          >
            {t("ACTIONS.TRANSFER")}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default SettingPopup;
