import { useQuery } from "@tanstack/react-query";
import { getNotifications } from "../api/Api"

const fetchNotifications = async () => {
  const response = await getNotifications();
  const { notifications } = response.data;

  return notifications ?? [];
}

const useNotifications = () => {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => fetchNotifications(),
  })
}

export { useNotifications }
