import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "https://51.91.151.225/";

const api = axios.create({
  baseURL: API_BASE_URL,
});

const authHeaders = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export const signInApi = (payload) => {
  return api.post("/auth/login", payload);
};

export const verifyAccessCode = (code) => {
  return api.post("/auth/verifyCode", { code });
};

export const verifyEmail = (email) => {
  return api.post("/auth/verifyEmail", { email });
};

export const resgiter = (payload) => {
  return api.post("/auth/register", payload);
};

export const requestAccess = (payload) => {
  return api.post("/auth/requestAccess", payload);
};

export const getEvents = (payload) => {
  return api.get("/events", {
    params: {
      projectId: payload?.projectId,
      from: payload?.from,
      to: payload?.to,
      calendars: payload?.calendars,
    },
    headers: authHeaders,
  });
};

export const updateEvent = (id, eventData) => {
  return api.put(`/events/${id}`, eventData, { headers: authHeaders });
};

export const deleteEvent = (id) => {
  return api.delete(`/events/${id}`, { headers: authHeaders });
};

export const createEvent = (eventData) => {
  return api.post("/events", eventData, { headers: authHeaders });
};

export const getCalendars = (projectId) => {
  return api.get(`/calendars?projectId=${projectId}`, { headers: authHeaders });
};

export const updateCalendar = (id, calendarData) => {
  return api.put(`/calendars/${id}`, calendarData, { headers: authHeaders });
};

export const deleteCalendar = (id) => {
  return api.delete(`/calendars/${id}`, { headers: authHeaders });
};

export const transferCalendar = (id, projectId) => {
  return api.post(
    `/calendars/${id}/transfer`,
    { projectId },
    { headers: authHeaders }
  );
};

export const createCalendar = (calendarData) => {
  return api.post("/calendars", calendarData, { headers: authHeaders });
};

export const getUnits = () => {
  return api.get("/units", { headers: authHeaders });
};

export const getSections = () => {
  return api.get("/sections", { headers: authHeaders });
};

export const getCurrentUser = () => {
  return api.get("/auth/me", { headers: authHeaders });
};

export const getProjects = () => {
  return api.get("/projects", { headers: authHeaders });
};

export const getProjectServices = (id) => {
  return api.get(`/projects/${id}/services`, { headers: authHeaders });
};

export const createMeetingRoom = (meetingRoomData) => {
  return api.post("/meetingRooms", meetingRoomData, { headers: authHeaders });
};

export const getProjectUsers = (projectId, includesCurrentUser = false) => {
  return api.get(
    `/projects/${projectId}/users${
      includesCurrentUser ? "?includesCurrentUser=true" : ""
    }`,
    { headers: authHeaders }
  );
};

export const getMeetingRoomByRoomUid = (roomUid) => {
  return api.get(`/meetingRooms/roomUid/${roomUid}`);
};

export const verifyPasswordMeetingRoom = (roomId, password) => {
  return api.post(`/meetingRooms/${roomId}/verify-password`, { password });
};

export const getMeetingRooms = (filter) => {
  return api.get(`/meetingRooms`, {
    params: {
      ...filter,
    },
    headers: authHeaders,
  });
};

export const updateMeetingRoom = (roomId, payload) => {
  return api.put(`/meetingRooms/${roomId}`, payload, { headers: authHeaders });
};

export const getMeetingHistory = (filter) => {
  return api.get(`/meetingRooms/get-history`, {
    params: {
      ...filter,
    },
    headers: authHeaders,
  });
};

export const getUpcomingMeetings = (filter) => {
  return api.get(`/meetingRooms/get-upcoming`, {
    params: {
      ...filter,
    },
    headers: authHeaders,
  });
};

export const deleteMeetingRoom = (roomId) => {
  return api.delete(`/meetingRooms/${roomId}`, { headers: authHeaders });
};

export const getNotifications = () => {
  return api.get("/notifications", { headers: authHeaders });
};

export const stripeCreateCheckoutSession = (payload) => {
  return api.post("/stripe/topup", payload, { headers: authHeaders });
};

export const createHostingSession = (payload) => {
  return api.post("/hosting/user-session", payload, { headers: authHeaders });
};

export const createAlphaHostingSession = (payload) => {
  return api.post("/alpha-hosting/user-session", payload, {
    headers: authHeaders,
  });
};

export const getProjectTask = (projectId) => {
  return api.get(`/tasks?projectId=${projectId}`, { headers: authHeaders });
};

export const createProjectTask = (projectId, taskInit) => {
  return api.post(
    `/tasks?projectId=${projectId}`,
    { task: taskInit },
    { headers: authHeaders }
  );
};

export const updateProjectTask = (taskId, taskData) => {
  return api.put(
    `/tasks/${taskId}`,
    { task: taskData },
    { headers: authHeaders }
  );
};

export const generateUploadUrl = (taskId, fileName, resource) => {
  return api.post(
    "/attachments/gernate-upload-url",
    {
      taskId,
      fileName,
      resource
    },
    { headers: authHeaders }
  );
};

export const listNotes = (projectId) => {
  return api.get(`/notes?projectId=${projectId}`, {
    headers: authHeaders,
  });
};

export const createNote = (payload) => {
  return api.post("/notes", payload, {
    headers: authHeaders,
  });
};

export const updateNote = (noteId, payload) => {
  return api.put(`/notes/${noteId}`, payload, {
    headers: authHeaders,
  });
};

export const deleteNote = (noteId) => {
  return api.delete(`/notes/${noteId}`, {
    headers: authHeaders,
  });
};

export const verifyPasswordNote = (noteId, password) => {
  return api.post(
    `/notes/${noteId}/verify-password`,
    {
      password,
    },
    {
      headers: authHeaders,
    }
  );
};
