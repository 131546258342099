import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box } from "@mui/material";
import { authHook } from "../../../hooks/auth.hook";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LoadingButton from "@mui/lab/LoadingButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { useTranslation } from "react-i18next";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { styled } from "@mui/material/styles";

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 8,
      marginTop: 15,
      fontSize: 14,
      fontWeight: 400,
      // padding: '5px',
      border: '1px solid #DEDFE2',
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "0px 4px 10px 1px rgba(0, 0, 0, 0.10)",
      '& .MuiMenuItem-root': {
        fontSize: 14,
        fontWeight: 400,
        paddingLeft: 15,
        paddingRight: 15
      },
      '& .MuiMenuItem-root:first-child': {
        marginBottom: 10
      },
    },
  })
);

const schema = yup
  .object({
    email: yup.string().email().required("Please enter a valid email address"),
    password: yup
      .string()
      .min(8, "Password must contain at least 8 characters")
      .max(32)
      .required(),
  })
  .required();

function SigninFormLoginStage() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const open = Boolean(anchorEl);
  const id = open ? "trouble-shooting-popover" : undefined;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (payload) => {
    try {
      setLoading(true);
      const { email, password } = payload;
      await authHook.signIn(email, password);
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } catch (error) {
      setLoading(false);
      // TODO: use toast add global config
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="signin__form-group" style={{ marginBottom: 30 }}>
        <label className="site-input-label">
          {t("SIGNIN.EMAIL_OR_USERNAME")}
        </label>
        <OutlinedInput
          style={{ width: "100%" }}
          error={!!errors.email}
          type="text"
          {...register("email")}
        />
        {errors.email?.message && (
          <Box sx={{ display: "flex", alignItems: "center" }} mt={1}>
            <ErrorOutlineIcon
              fontSize="14"
              sx={{ mr: "5px", color: "#EF4051" }}
            />
            <p className="site-input-error">{errors.email?.message}</p>
          </Box>
        )}
      </div>
      <div className="signin__form-group" style={{ marginBottom: 25 }}>
        <label className="site-input-label">{t("SIGNIN.PASSWORD")}</label>
        <OutlinedInput
          style={{ width: "100%" }}
          error={!!errors.password}
          type={showPassword ? "text" : "password"}
          {...register("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                style={{ fontSize: 16, padding: 0 }}
                onClick={toggleShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? (
                  <VisibilityOutlined style={{ fontSize: 16 }} />
                ) : (
                  <VisibilityOffOutlined style={{ fontSize: 16 }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors.password?.message && (
          <Box sx={{ display: "flex", alignItems: "center" }} mt={1}>
            <ErrorOutlineIcon
              fontSize="14"
              sx={{ mr: "5px", color: "#EF4051" }}
            />
            <p className="site-input-error">{errors.password?.message}</p>
          </Box>
        )}
      </div>

      <Box sx={{ mb: 1 }}>
        <FormControlLabel
          style={{ padding: 1 }}
          control={<Checkbox defaultChecked={false} />}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {t("SIGNIN.KEEP_ME_SIGNIN")}{" "}
              <ErrorOutlineIcon
                sx={{ ml: "5px", color: "#51A3FF", fontSize: 18 }}
              />
            </Box>
          }
        />
      </Box>

      <div className="signin__form-group login-submit">
        <LoadingButton
          className="btn btn--w-100"
          type="submit"
          loading={loading}
          variant="contained"
          sx={{
            borderRadius: 35,
            fontSize: 15,
            color: "#FFF",
            fontWeight: "500 !important",
            height: 45,
            background: "#51A3FF",
            textTransform: "none",
            boxShadow: "none",
          }}
        >
          {t("SIGNIN.SUBMIT_BUTTON")}
        </LoadingButton>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
          gap: 1,
          fontSize: 14,
        }}
      >
        <span>{t("SIGNIN.NEW_TO_UNLYME")} </span>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://reg.unlyme.com"
          style={{ textDecoration: "underline" }}
        >
          {t("SIGNIN.CREATE_ACCOUNT")}
        </a>
      </Box>

      <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          aria-describedby={id}
          sx={{ textTransform: "none", fontSize: 14 }}
          onClick={handleClick}
          style={{ textDecoration: "underline" }}
        >
          {t("SIGNIN.TROUBLE_SIGNGIN_IN")}
        </Button>
        <StyledMenu
          className="trouble-shooting-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            {" "}
            <ListItemIcon>
              <AccountCircleOutlinedIcon height="18px" width="18px" />
            </ListItemIcon>
            <ListItemText>{t("SIGNIN.RESET_PASSWROD")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {" "}
            <ListItemIcon>
              <VpnKeyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("SIGNIN.FORGOT_USERNAME")}</ListItemText>
          </MenuItem>
        </StyledMenu>
      </Box>
    </form>
  );
}

export default SigninFormLoginStage;
