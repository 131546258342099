import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { authHook } from "../../../hooks/auth.hook";

function VerficationPage() {
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      authHook.autoSignInAfterRegister(token)
      window.location.href = '/';
    }
  }, [searchParams])

  return (<></>)
}

export default VerficationPage;
