import {
  Box,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  MenuList,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItemIcon,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProjectUsers as getProjectUsersApi } from "../../../api/Api";
import { useCurrentProject } from "../../../hooks/projects";
import { renderFullName } from "../../../utils/UserUtil";
import { stringAvatar } from "../../../utils/AvatarUtil";
import DoneIcon from "@mui/icons-material/Done";

const AttendeeListSelect = ({ attendees, setAttendees }) => {
  const { t } = useTranslation();
  const { data: currentProject } = useCurrentProject();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(attendees);

  useEffect(() => {
    const getProjectUsers = async () => {
      const response = await getProjectUsersApi(currentProject.id);

      if (response.data?.users) {
        setUsers(response.data?.users);
      }
    };

    if (currentProject) {
      getProjectUsers();
    }
  }, [currentProject]);

  useEffect(() => {
    setAttendees(selectedUsers);
  }, [selectedUsers])

  const handleSelectUser = (user) => {
    const fUser = selectedUsers.find((u) => u.id === user.id);
    if (fUser) {
      const fUsers = selectedUsers.filter((u) => u.id !== user.id);
      setSelectedUsers(fUsers);
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  return (
    <Card sx={{ width: 300 }}>
      <CardHeader
        sx={{ textAlign: "center" }}
        title={
          <Box sx={{ fontWeight: 500, fontSize: 14 }}>
            {t("VIDEO_CONFERENCING_PAGE.ATTENDEE")}
          </Box>
        }
      />
      <CardContent sx={{ px: 0, pt: 0 }}>
        <Box sx={{ px: 2 }}>
          <TextField
            sx={{ width: "100%" }}
            size="small"
            id="outlined-search"
            label={t("VIDEO_CONFERENCING_PAGE.SEARCH_MEMBERS")}
            type="search"
          />
        </Box>
        <MenuList>
          {users.map((user) => {
            const fullName = renderFullName(user);
            const isSelected = selectedUsers.find((u) => u.id === user.id);

            return (
              <MenuItem onClick={() => handleSelectUser(user)}>
                <ListItemAvatar>
                  <Avatar
                    {...stringAvatar(fullName)}
                    sx={{ width: 30, height: 30, fontSize: 14 }}
                  />
                </ListItemAvatar>
                <ListItemText>{fullName}</ListItemText>
                <ListItemIcon>{isSelected && <DoneIcon />}</ListItemIcon>
              </MenuItem>
            );
          })}
        </MenuList>
      </CardContent>
    </Card>
  );
};

export default AttendeeListSelect;
