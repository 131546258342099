import React from "react";
import MeetingPageMain from "./MeetingPageMain";

const MeetingPageContent = ({ isGuest }) => {
  return (
    <div className="meeting-page">
      <div className="meeting-page_main">
        <MeetingPageMain isGuest={isGuest} />
      </div>
    </div>
  );
};

export default MeetingPageContent;
