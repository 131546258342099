import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { user_info } from "../../../../mocks/mocks";
import lightIcon from "../../../../assets/images/header/theme_light.svg";
import darkIcon from "../../../../assets/images/header/theme_dark.svg";
import autoIcon from "../../../../assets/images/header/theme_auto.svg";
import languuageIcon from "../../../../assets/images/header/new-icons/language.svg";
import arrowDownIcon from "../../../../assets/images/header/new-icons/arrow-down.svg";
import arrowLeftIcon from "../../../../assets/images/header/new-icons/arrow-left.svg";
import closeIcon from "../../../../assets/images/header/new-icons/close.svg";
import userIcon from "../../../../assets/images/header/new-icons/user-img.svg";
import eyeOffIcon from "../../../../assets/images/header/new-icons/eye-off.svg";
import logOutIcon from "../../../../assets/images/header/new-icons/log-out.svg";
import visacardIcon from "../../../../assets/images/header/new-icons/visacard.svg";
import mastercardIcon from "../../../../assets/images/header/new-icons/mastercard.svg";
import settingsIcon from "../../../../assets/images/header/new-icons/settings.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { signoutUser } from "../../../../redux/app/appSlice";
import { asyncToggleTheme } from "../../../../redux/app/themeSlice";
import { toggleSettingPopup } from "../../../../redux/app/popupSlice";
import _ from "lodash";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { ISO_SUPPORTED_LANGUAGES, LOCAL_STORAGE_LOCALE, SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGE_NAMES } from "../../../../types";
import { useCurrentUser } from "../../../../hooks/auth.hook";
import { renderFullName } from "../../../../utils/UserUtil";
import { stringAvatar } from "../../../../utils/AvatarUtil";
import { stripeCreateCheckoutSession } from "../../../../api/Api";
import { useSearchParams } from "react-router-dom";

const HeaderSettingPopup = ({}) => {
  const { t, i18n } = useTranslation();
  let [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const { data: currentUser, refetch: refetchCurrentUser } = useCurrentUser();

  const darkMode = useSelector((state) => state.theme.darkMode);
  const settingPopup = useSelector((state) => state.popup.settingPopupToggler);

  const [mode, setMode] = useState(darkMode ? "dark" : "light");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const firstname = user_info.firstname;
  const lastname = user_info.lastname;
  const mail = user_info.mail;
  const avatar = user_info.avatar;

  // start changing theme
  const handleChangeTheme = (event, newTheme) => {
    if (newTheme !== null) {
      dispatch(asyncToggleTheme());
      setMode(newTheme);
    }
  };
  // end changing theme

  const onChangeLang = (language) => {
    const userLang = language;
    const isSupported =
      !!ISO_SUPPORTED_LANGUAGES.find((sl) => sl === userLang) ||
      !!SUPPORTED_LANGUAGES.find((sl) => sl === userLang);
    if (userLang && isSupported) {
      i18n.changeLanguage(userLang, () => {
        localStorage.setItem(LOCAL_STORAGE_LOCALE, userLang);
      });
    }
  }

  // start language menu
  const [anchorLangMenu, setAnchorLangMenu] = useState(null);
  const openLangMenu = Boolean(anchorLangMenu);
  const handleOpenLangMenu = (event) => {
    setAnchorLangMenu(event.currentTarget);
  };
  const handleCloseLangMenu = () => {
    setAnchorLangMenu(null);
  };
  // end language menu

  const [showAmount, setShowAmount] = useState(false);

  const sliderRef = useRef(null);

  const goToTopup = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideTo(1);
    }
  };

  const goToPopup = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideTo(0);
    }
  };

  const [topupAmount, setTopupAmount] = useState(500);

  const handleCloseHeaderSettingPopup = () => {
    dispatch(toggleSettingPopup());
  }

  const handlePay = async () => {
    try {
      const payload = {
        currency: 'CHF',
        amount: topupAmount
      }
      const response = await stripeCreateCheckoutSession(payload);
      const { sessionUrl } = response.data;
      window.location.href = sessionUrl;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Swiper
      ref={sliderRef}
      spaceBetween={50}
      slidesPerView={1}
      className="header-popup setting-popup"
    >
      <SwiperSlide className="setting-popup-list" style={{width: 350, marginRight: 50}}>
        <div className="setting-popup-list__header">
          <div className="setting-popup-list__header-title">Settings</div>

          <div className="setting-popup-list__header-actions">
            <div className="setting-popup-list__header-actions_language-selector">
              <Button
                variant="outlined"
                startIcon={<img src={languuageIcon} alt="language" />}
                endIcon={<img src={arrowDownIcon} alt="arrow down" />}
                onClick={handleOpenLangMenu}
              >
                {SUPPORTED_LANGUAGE_NAMES[i18n.language]}
              </Button>

              <Menu
                // id="long-menu"
                className="header-setting-popup__language-menu"
                anchorEl={anchorLangMenu}
                open={openLangMenu}
                onClose={handleCloseLangMenu}
                disableScrollLock={true}
              >
                {SUPPORTED_LANGUAGES.map((sl) => (
                  <MenuItem
                    className="header-setting-popup__language-menu_item"
                    onClick={() => {
                      onChangeLang(sl)
                      handleCloseLangMenu();
                    }}
                  >
                    <Button variant="outlined" disableRipple>
                      {SUPPORTED_LANGUAGE_NAMES[sl]}
                    </Button>
                  </MenuItem>
                ))}

              </Menu>
            </div>

            <div className="header-setting-popup__header-actions_close">
              <IconButton
                onClick={() =>
                  // setSettingPopupToggler(false)
                  // dispatch(toggleSettingPopup())
                  handleCloseHeaderSettingPopup()
                }
              >
                <img src={closeIcon} alt="close button" />
              </IconButton>
            </div>
          </div>
        </div>

        <div className="header-setting-popup__content">
          <div className="header-setting-popup__content-user">
            <Avatar
              className="header-setting-popup__content-user_img"
              {...stringAvatar(renderFullName(currentUser))}
              sx={{ width: 65, height: 65, fontSize: 24 }}
            />

            {/* <img
              className="header-setting-popup__content-user_img"
              src={userIcon}
              alt="user image"
            /> */}
            <p className="header-setting-popup__content-user_name">
              {renderFullName(currentUser)}
            </p>
            <p className="header-setting-popup__content-user_email">
              {currentUser?.email}
            </p>
          </div>

          <div className="header-setting-popup__content-balance">
            <div className="header-setting-popup__content-balance_info">
              <div className="header-setting-popup__content-balance_info-title">
                Balance
              </div>

              <div className="header-setting-popup__content-balance_info-amount">
                {!showAmount ? currentUser?.balance ? currentUser?.balance : 0 : "***"}
              </div>
            </div>

            <div className="header-setting-popup__content-balance_action">
              <IconButton
                onClick={() => setShowAmount((prevAmount) => !prevAmount)}
              >
                <img src={eyeOffIcon} alt="show amoount" />
              </IconButton>
            </div>
          </div>

          <div className="header-setting-popup__content-topup">
            <Button onClick={goToTopup}>Top Up</Button>
          </div>
        </div>

        <div className="header-setting-popup__footer">
          <div className="header-setting-popup__footer-options">
            {/* <Button startIcon={<img src={settingsIcon} alt="setting" />}>
              {t("SETTING_POPUP.SETTINGS")}
            </Button> */}
          </div>

          <div className="header-setting-popup__footer-options">
            <Button
              startIcon={<img src={logOutIcon} alt="logout" />}
              onClick={() => {
                toast.info("You are about to logging out...", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

                dispatch(signoutUser());
              }}
            >
              {/* {t('SETTING_POPUP.LOGOUT')} */}
              Log out
            </Button>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="setting-popup-topup">
        <div className="setting-popup-topup__header">
          <div className="setting-popup-topup__header_back">
            <IconButton onClick={goToPopup}>
              <img src={arrowLeftIcon} alt="back button" />
            </IconButton>
          </div>

          <div className="setting-popup-topup__header_title">
            Top-Up Balance
          </div>

          <div className="setting-popup-topup__header_close">
            <IconButton onClick={() => handleCloseHeaderSettingPopup()}>
              <img src={closeIcon} alt="close button" />
            </IconButton>
          </div>
        </div>

        <div className="setting-popup-topup__content">
          <div className="setting-popup-topup__content_title">
            Current balance: 100 CHF
          </div>

          <div className="setting-popup-topup__content_amount">
            <div className="setting-popup-topup__content_amount-title">
              <p>Amount</p>

              <p>CHF</p>
            </div>

            <div className="setting-popup-topup__content_amount-number">
              <input
                type="number"
                value={topupAmount}
                onChange={(e) => setTopupAmount(e.target.value)}
              />
            </div>
          </div>

          <div className="setting-popup-topup__content_actions">
            <Button onClick={handlePay} className="setting-popup-topup__content_actions-btn">
              Pay
            </Button>

            <div className="setting-popup-topup__content_actions-flags">
              <img src={visacardIcon} alt="visa card" />
              <img src={mastercardIcon} alt="master card" />
            </div>
          </div>
        </div>

        <div class="setting-popup-topup__footer" bis_skin_checked="1"></div>
      </SwiperSlide>
    </Swiper>

    // <div className='header-popup setting-popup'>
    //   <div className='setting-popup-list'>
    //     <div className='setting-popup-list__header'>
    //       <div className='setting-popup-list__header_user'>
    //         <div className='setting-popup-list__header_user-avatar'>
    //           {
    //             avatar ?
    //               <Avatar src={avatar} />
    //             :
    //               <Avatar>{firstname.split('')[0].toLocaleUpperCase()}{lastname.split('')[0].toLocaleUpperCase()}</Avatar>
    //           }

    //         </div>

    //         <div className='setting-popup-list__header_user-info'>
    //           <p className='setting-popup-list__header_user-info-name'>
    //             {firstname} {lastname}
    //           </p>

    //           <p className='setting-popup-list__header_user-info-mail'>
    //             {mail}
    //           </p>
    //         </div>
    //       </div>

    //       <div className='setting-popup-list__header-actions'>
    //         <div className='setting-popup-list__header-actions_close'>
    //           <IconButton
    //             aria-label="more"
    //             id="long-button"
    //             aria-controls={open ? "long-menu" : undefined}
    //             aria-expanded={open ? "true" : undefined}
    //             aria-haspopup="true"
    //             onClick={() =>
    //               // setSettingPopupToggler(false)
    //               dispatch(toggleSettingPopup())
    //             }
    //           >
    //             <CloseIcon  sx={{ color: '#000000' }}/>
    //           </IconButton>

    //         </div>
    //       </div>
    //     </div>

    //     <div className='setting-popup-list__body'>
    //       <div className='setting-popup-list__body-content'>
    //         <div className='setting-popup-list__body-content_item'>
    //           <div className='setting-popup-list__body-content_item-title'>
    //             {t('SETTING_POPUP.SELECTING')}
    //           </div>

    //           <ToggleButtonGroup
    //             value={mode}
    //             exclusive
    //             onChange={handleChangeTheme}
    //             aria-label="select theme"
    //           >
    //             <ToggleButton className='setting-popup-list__body-content_item-selector' value="light" aria-label="theme light">
    //               <img src={lightIcon} />
    //               <span>{t('SETTING_POPUP.THEME_LIGHT')}</span>
    //             </ToggleButton>
    //             <ToggleButton className='setting-popup-list__body-content_item-selector' value="dark" aria-label="theme dark">
    //               <img src={darkIcon} />
    //               <span>{t('SETTING_POPUP.THEME_DARK')}</span>
    //             </ToggleButton>
    //             {/* <ToggleButton className='setting-popup-list__body-content_item-selector' value="auto" aria-label="theme auto">
    //               <img src={autoIcon} />
    //               <span>{t('SETTING_POPUP.THEME_AUTO')}</span>
    //             </ToggleButton> */}
    //           </ToggleButtonGroup>

    //         </div>
    //       </div>
    //     </div>

    //     <div className='setting-popup-list__footer'>
    //       <div className='setting-popup-list__footer-options'>
    //         <Button className='setting-popup-list__footer-options-setting' startIcon={<SettingsIcon />}>
    //           {t('SETTING_POPUP.SETTINGS')}
    //         </Button>
    //       </div>

    //       <div className='setting-popup-list__footer-options'>
    //         <Button
    //           className='setting-popup-list__footer-options-logout'
    //           startIcon={<LogoutIcon />}
    //           onClick={() => {
    //             toast.info('You are about to logging out...', {
    //               position: "top-center",
    //               autoClose: 2000,
    //               hideProgressBar: true,
    //               closeOnClick: true,
    //               pauseOnHover: false,
    //               pauseOnFocusLoss: false,
    //               draggable: true,
    //               progress: undefined,
    //               theme: "light",
    //               });

    //             dispatch(signoutUser())
    //           }}
    //         >
    //           {t('SETTING_POPUP.LOGOUT')}
    //         </Button>

    //         <ToastContainer />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default HeaderSettingPopup;
