import axios from "axios";

export const S3Upload = async (
  signedUrl,
  file,
  setPercentCallback,
  setUploadSpeedCallback,
  setEstimatedTimeCallback
) => {
  try {
    const cancelTokenSource = axios.CancelToken.source();
    const startTime = Date.now();
    const fileSizeInMegaBits = file?.size * 1e-6;
    const request = axios.put(signedUrl, file, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      onUploadProgress(progressEvent) {
        const uploadedMegaBits = parseFloat(progressEvent.loaded * 1e-6);
        const deltaInSeconds = (Date.now() - startTime) / 1000;
        const speed = uploadedMegaBits / deltaInSeconds;
        setPercentCallback(parseInt(progressEvent.progress * 100));
        setUploadSpeedCallback(speed.toFixed(2));
        setEstimatedTimeCallback(Math.floor((fileSizeInMegaBits-uploadedMegaBits)/speed));
      },
      cancelToken: cancelTokenSource.token,
    });
    const _request = {};
    _request[JSON.stringify(signedUrl)] = cancelTokenSource;
    await request;
    delete _request[JSON.stringify(signedUrl)];

    const uploadedPath = signedUrl.split('?')[0];

    return { uploaded: true, uploadedPath };
  } catch (err) {
    if (axios.isCancel(err)) {
      return { error: err?.message };
    }
    return { error: err };
  }
};
