import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { renderFullName } from "../../../../utils/UserUtil";
import CloseIcon from "@mui/icons-material/Close";
import {
  DesktopTimePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/datetime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import FilterListIcon from "@mui/icons-material/FilterList";
import TimezoneSelect from "react-timezone-select";
import { stringAvatar } from "../../../../utils/AvatarUtil";
import {
  Add,
  LockOpenOutlined,
  PeopleOutlineOutlined,
  Repeat,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import AttendeeListSelect from "../AttendeeListSelect";
import {
  ContentCopyOutlined,
  GppGoodOutlined,
  TextSnippetOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useCurrentUser } from "../../../../hooks/auth.hook";
import { createEvent, updateEvent, updateMeetingRoom } from "../../../../api/Api";
import { useCurrentProject } from "../../../../hooks/projects";
import {
  useMeetingRoomHistory,
  useUpcomingMeetings,
} from "../../../../hooks/meetingRoom.hook";

const EditMeetingForm = ({ meeting, handleCloseEditModal, viewMode }) => {
  const { t } = useTranslation();
  const [dateValue, setDateValue] = useState(dayjs(meeting.startAt));
  const [startTimeValue, setStartTimeValue] = useState(dayjs(meeting.startAt));
  const [endTimeValue, setEndTimeValue] = useState(dayjs(meeting.endAt));
  const [selectedTimezone, setSelectedTimezone] = useState(meeting.timezone);
  const [attendees, setAttendees] = useState(meeting.attendees);
  const [anchorAttendeeListEl, setAnchorAttendeeListEl] = useState(null);
  const openAttendeeList = Boolean(anchorAttendeeListEl);
  const [addToCalendar, setAddToCalendar] = useState(!!meeting.eventId);
  const [recurringMeeting, setRecurringMeeting] = useState(
    meeting.frecency !== "ONCE"
  );
  const [frecency, setFrecency] = useState(
    meeting.frecency === "ONCE" ? "DAILY" : meeting.frecency
  );
  const [enabledPassword, setEnabledPassword] = useState(!!meeting.password);
  const [password, setPassword] = useState(meeting.password);
  const [showPassword, setShowPassword] = useState(false);
  const [enabledLobby, setEnabledLobby] = useState(false);
  const [enabledEncrypt, setEnabledEncrypt] = useState(false);
  const { data: currentUser } = useCurrentUser();
  const { data: currentProject } = useCurrentProject();
  const [notes, setNotes] = useState(
    meeting.notes?.find(n => n.userId === currentUser.id) ? meeting.notes.find(n => n.userId === currentUser.id).text : ''
  )

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const externalUrl = useMemo(() => {
    return `${process.env.REACT_APP_EXTERNAL_MEET_URL}?meeting=${meeting.roomUid}`;
  }, [meeting]);

  const handleCopyExternalUrl = () => {
    if (externalUrl) {
      navigator.clipboard.writeText(externalUrl);
      toast.success(t("VIDEO_CONFERENCING_PAGE.COPIED_EXTERNAL_URL"));
    }
  };

  const handleToggleEnableLobby = () => {
    setEnabledLobby(!enabledLobby);
  };

  const handleChangeNotes = (event) => {
    setNotes(event.target.value);
  }

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 31,
    height: 18,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      height: "100%",
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        marginTop: 0,
        marginBottom: 0,
        marginRight: 2,
        marginLeft: 0,
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#767680E5",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 13,
      height: 13,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#c4bfd2" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleClick = (event) => {
    setAnchorAttendeeListEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorAttendeeListEl(null);
  };

  const handleToogleEnabledPassword = () => {
    setEnabledPassword(!enabledPassword);
  };

  const handleEditMeetingRoom = async () => {
    const attendeeIds = attendees.map((a) => a.id);
    attendeeIds.push(currentUser.id);

    const fdate = dateValue.startOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
    const fstartTime = startTimeValue.format("HH:mm:ss");
    const fendTime = endTimeValue.format("HH:mm:ss");

    const combinedStartTime = fdate.replace("00:00:00", fstartTime);
    const combinedEndTime = fdate.replace("00:00:00", fendTime);

    let timezone = selectedTimezone;

    if (typeof selectedTimezone === "string") {
      timezone = selectedTimezone;
    } else {
      timezone = selectedTimezone.value;
    }

    const mNotes = meeting.notes ? meeting.notes.find(n => n.userId === currentUser.id) : null;
    const nNotes = meeting.notes?.filter(n => n.userId !== currentUser.id);
    let newNotes = [];
    if (nNotes) {
      newNotes = nNotes
    }
    if (!mNotes) {
      newNotes.push({
        userId: currentUser.id,
        text: notes,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        createdAt: new Date(),
        updatedAt: new Date()
      })
    }
    if (mNotes) {
      newNotes.push({
        ...mNotes,
        text: notes,
        updatedAt: new Date()
      })
    }


    const meetingRoomResponse = await updateMeetingRoom(meeting.id, {
      password: password,
      startAt: combinedStartTime,
      endAt: combinedEndTime,
      attendees: attendeeIds,
      timezone: timezone,
      frecency: recurringMeeting ? frecency : "ONCE",
      isEnabledLobby: enabledLobby,
      isEnabledE2EE: enabledEncrypt,
      notes: newNotes
    });

    if (addToCalendar) {
      if (meeting.eventId) {
        const eventData = {
          text: `Meeting: ${meeting.roomName}`,
          startDate: combinedStartTime,
          endDate: combinedEndTime,
          details: `Password: ${password ? password : ""} \nUrl: ${
            process.env.REACT_APP_EXTERNAL_MEET_URL
          }?meeting=${meeting.roomUid}`,
          calendar: "meeting",
          color: "#6D4BCE",
          allDay: false,
          projectId: currentProject?.id,
          recurring: recurringMeeting ? frecency : null,
          roomUid: meeting.roomUid,
        };
        await updateEvent(meeting.eventId, eventData);
      } else {
        const eventData = {
          text: `Meeting: ${meeting.roomName}`,
          startDate: combinedStartTime,
          endDate: combinedEndTime,
          details: `Password: ${password ? password : ""} \nUrl: ${
            process.env.REACT_APP_EXTERNAL_MEET_URL
          }?meeting=${meeting.roomUid}`,
          calendar: "meeting",
          color: "#6D4BCE",
          allDay: false,
          projectId: currentProject?.id,
          recurring: recurringMeeting ? frecency : null,
          roomUid: meeting.roomUid,
        };
        const response = await createEvent(eventData);
        const { data } = response.data;
        const { data: meetingData } = meetingRoomResponse;

        await updateMeetingRoom(meetingData.meetingRoom.id, {
          eventId: data.event.id,
        });
      }
    }

    toast.success(t("VIDEO_CONFERENCING_PAGE.CREATED_SCHEDULE_MEETING"));

    await refetchMeetingRoomHistory();
    await refetchUpcomingMeetings();
  };

  const { refetch: refetchMeetingRoomHistory } = useMeetingRoomHistory({
    projectId: currentProject.id,
    to: new Date(dayjs().subtract(1, "day").startOf("day").format()),
  });

  const { refetch: refetchUpcomingMeetings } = useUpcomingMeetings({
    projectId: currentProject.id,
    from: new Date(dayjs().startOf("day").format()),
  });

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        bgcolor: "background.paper",
        borderRadius: 3,
        boxShadow: 24,
        p: 4,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h6" component="h6">
            {meeting?.roomName}
          </Typography>
          <Typography
            sx={{ marginBottom: 3, textAlign: "left", width: "100%" }}
            className="mb-5"
            variant="caption"
            component="p"
          >
            {t("VIDEO_CONFERENCING_PAGE.CREATED_BY", {
              name: meeting?.user ? renderFullName(meeting?.user) : "",
            })}
          </Typography>
        </Box>

        <Box>
          <IconButton sx={{ padding: 0 }} onClick={handleCloseEditModal}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <form className="schedule-content_form" style={{ padding: 0 }}>
          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-date">
              <p className="schedule-content_form-item-date-title">
                <span>{t("VIDEO_CONFERENCING_PAGE.DATE_TIME")}</span>
              </p>

              <form
                className="schedule-content_form-item-date-form"
                style={{ marginTop: 24 }}
              >
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    md={6}
                    className="schedule-content_form-item-date-form-field"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        format={DEFAULT_DATE_FORMAT}
                        value={dateValue}
                        onChange={(newValue) => setDateValue(newValue)}
                        disablePast
                        readOnly={viewMode}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    xs={6}
                    md={3}
                    className="schedule-content_form-item-date-form-field"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{ p: 0 }}
                        components={["DesktopTimePicker"]}
                      >
                        <DemoItem>
                          <DesktopTimePicker
                            timeSteps={{ minutes: 15 }}
                            value={startTimeValue}
                            onChange={(newValue) => setStartTimeValue(newValue)}
                            endAdornment={
                              <InputAdornment position="end">
                                <FilterListIcon />
                              </InputAdornment>
                            }
                            ampm={false}
                            readOnly={viewMode}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    xs={6}
                    md={3}
                    className="schedule-content_form-item-date-form-field"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{ p: 0 }}
                        components={["DesktopTimePicker"]}
                      >
                        <DemoItem>
                          <DesktopTimePicker
                            timeSteps={{ minutes: 15 }}
                            value={endTimeValue}
                            onChange={(newValue) => setEndTimeValue(newValue)}
                            endAdornment={
                              <InputAdornment position="end">
                                <FilterListIcon />
                              </InputAdornment>
                            }
                            ampm={false}
                            readOnly={viewMode}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    xs={12}
                    md={3}
                    className="schedule-content_form-item-date-form-field"
                  ></Grid>
                </Grid>
              </form>
            </div>
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-timezone">
              <p className="schedule-content_form-item-timezone-title">
                <span>{t("COMMON.TIMEZONE")}</span>
              </p>

              <div className="schedule-content_form-item-timezone-select">
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                  isDisabled={viewMode}
                />
              </div>
            </div>
          </div>

          <div className="schedule-content_form-item">
            <p className="schedule-content_form-item-title">
              <span>{t("VIDEO_CONFERENCING_PAGE.ATTENDEE")}</span>
            </p>

            <Box sx={{ display: "flex" }}>
              {attendees
                ?.filter((a) => a.id !== currentUser.id)
                .map((a) => {
                  const fullName = renderFullName(a);

                  return (
                    <Avatar
                      {...stringAvatar(fullName)}
                      sx={{ width: 30, height: 30, fontSize: 14, mr: 1 }}
                    />
                  );
                })}

              {!viewMode && (
                <IconButton
                  sx={{ backgroundColor: "white", height: 28, width: 28 }}
                  aria-label="add-attendee"
                  aria-describedby="attendee-list-popover"
                  onClick={handleClick}
                >
                  <Add />
                </IconButton>
              )}
            </Box>

            <Popover
              id="attendee-list-popover"
              open={openAttendeeList}
              anchorEl={anchorAttendeeListEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <AttendeeListSelect
                attendees={attendees}
                setAttendees={setAttendees}
              />
            </Popover>
          </div>

          <div className="schedule-content_form-item">
            <p className="schedule-content_form-item-title">
              <TextSnippetOutlined />
              <span>{t("MENU.NOTES")}</span>
            </p>
            <TextField
              placeholder={t("ACTIONS.ADD_RESOURCE", {
                resource: t("MENU.NOTES").toLowerCase(),
              })}
              className="schedule-content_form-item-input"
              value={notes}
              onChange={handleChangeNotes}
              disabled={viewMode}
            />
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-switch">
              <div
                className="schedule-content_form-item-switch-label"
                onClick={() => setAddToCalendar(!addToCalendar)}
              >
                <Add />
                <span>{t("VIDEO_CONFERENCING_PAGE.ADD_TO_CALENDAR")}</span>
              </div>
              <div className="schedule-content_form-item-switch-btn">
                <IOSSwitch
                  checked={addToCalendar}
                  onChange={() => setAddToCalendar(!addToCalendar)}
                  disabled={viewMode}
                />
              </div>
            </div>
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-switch">
              <div
                className="schedule-content_form-item-switch-label"
                onClick={() => setRecurringMeeting(!recurringMeeting)}
              >
                <Repeat />
                <span>{t("VIDEO_CONFERENCING_PAGE.RECURRING_MEETING")}</span>
              </div>
              <div className="schedule-content_form-item-switch-btn">
                <IOSSwitch
                  checked={recurringMeeting}
                  onChange={() => setRecurringMeeting(!recurringMeeting)}
                  disabled={viewMode}
                />
              </div>
            </div>
          </div>

          {recurringMeeting && (
            <div className="schedule-content_form-item">
              <div className="schedule-content_form-item-switch">
                <RadioGroup
                  className="meeting-shortcut-item-end-select-radio"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={frecency}
                  onChange={(event) => setFrecency(event.target.value)}
                >
                  <FormControlLabel
                    disabled={viewMode}
                    value="DAILY"
                    control={<Radio />}
                    label={t("COMMON.TIME.DAILY")}
                  />
                  <FormControlLabel
                    disabled={viewMode}
                    value="WEEKLY"
                    control={<Radio />}
                    label={t("COMMON.TIME.WEEKLY")}
                  />
                </RadioGroup>
              </div>
            </div>
          )}

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-switch">
              <div
                className="schedule-content_form-item-switch-label"
                onClick={handleToogleEnabledPassword}
              >
                <LockOpenOutlined />
                <span>{t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION")}</span>
              </div>
              <div className="schedule-content_form-item-switch-btn">
                <IOSSwitch
                  checked={enabledPassword}
                  onChange={handleToogleEnabledPassword}
                  disabled={viewMode}
                />
              </div>
            </div>
          </div>

          {enabledPassword ? (
            <div className="schedule-content_form-item">
              <p className="schedule-content_form-item-title-info">
                {/* <img src={attendeeIcon} alt="conference name" /> */}
                <span>
                  {t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION_DESC")}
                </span>
              </p>
              {/* <TextField placeholder="Enter your password..." className="schedule-content_form-item-input" type="password" /> */}
              <FormControl className="schedule-content_form-item-input">
                <OutlinedInput
                  placeholder="Enter your password"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  disabled={viewMode}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          ) : (
            ""
          )}

          <div className="meetnow-content_form-item-switch">
            <div
              className="meetnow-content_form-item-switch-label"
              onClick={handleToggleEnableLobby}
            >
              <PeopleOutlineOutlined />
              <span>{t("VIDEO_CONFERENCING_PAGE.ENABLE_LOBBY")}</span>
            </div>
            <div className="meetnow-content_form-item-switch-btn">
              <IOSSwitch
                checked={enabledLobby}
                onChange={handleToggleEnableLobby}
                disabled={viewMode}
              />
            </div>
          </div>

          {enabledLobby && (
            <div className="meetnow-content_form-item">
              <p className="meetnow-content_form-item-title-info">
                <span>
                  {t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION_DESC")}
                </span>
              </p>
            </div>
          )}

          <div className="meetnow-content_form-item-switch">
            <div
              className="meetnow-content_form-item-switch-label"
              onClick={() => setEnabledEncrypt(!enabledEncrypt)}
            >
              <GppGoodOutlined />
              <span>{t("VIDEO_CONFERENCING.ENABLE_ENCRYPT")}</span>
            </div>
            <div className="meetnow-content_form-item-switch-btn">
              <IOSSwitch
                checked={enabledEncrypt}
                onChange={() => setEnabledEncrypt(!enabledEncrypt)}
                disabled={viewMode}
              />
            </div>
          </div>

          {enabledEncrypt && (
            <div className="meetnow-content_form-item">
              <p className="meetnow-content_form-item-title-info">
                <span>
                  {t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION_DESC")}
                </span>
              </p>
            </div>
          )}

          <div className="meetnow-content_form-item">
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p style={{ marginBottom: 5 }}>
                  {t("VIDEO_CONFERENCING_PAGE.LINK_TO_THE_MEETING")}:{" "}
                </p>
                <p>
                  <a href={externalUrl}>{externalUrl}</a>
                </p>
              </div>

              <Tooltip title={t("VIDEO_CONFERENCING_PAGE.COPIED_EXTERNAL_URL")}>
                <Button
                  size="small"
                  onClick={handleCopyExternalUrl}
                  sx={{
                    textTransform: "Capitalize",
                  }}
                  startIcon={<ContentCopyOutlined sx={{ fontSize: 14 }} />}
                >
                  {t("ACTIONS.COPY")}
                </Button>
              </Tooltip>
            </Box>
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-submit">
              <Button
                className="schedule-content_form-item-submit-btn"
                onClick={handleEditMeetingRoom}
                disabled={viewMode}
              >
                {t("ACTIONS.SUBMIT")}
              </Button>
            </div>
          </div>
        </form>
      </Box>
    </Box>
  );
};

export default EditMeetingForm;
