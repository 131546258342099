import React, { useEffect, useState } from "react";
import "./createevent-popups.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dayjs from "dayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/datetime";

const CreateEventsPopup = ({
  handleSubmitEvent,
  eventName,
  handleEventName,
  startDate,
  handleStartDate,
  startTime,
  handleStartTime,
  endDate,
  handleEndDate,
  endTime,
  handleEndTime,
  allDay,
  handleAllDay,
  calendars,
  calendar,
  handleCalendar,
  details,
  handleDetails,
  handleDeleteEvent,
  closeEventPopup,
  editEventMode,
  isValidDatetime,
  setIsValidDatetime,
  selectedCalendars,
  selectedEvent,
  handleMeetingNow,
}) => {
  const [defaultCalendar, setDefaultCalendar] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (editEventMode) {
      setDefaultCalendar(calendar);
    } else {
      if (calendar) {
        setDefaultCalendar(calendar);
      } else if (selectedCalendars[0] && selectedCalendars[0] !== "all") {
        setDefaultCalendar(
          calendars.find((item) => item.id === selectedCalendars[0])
        );
      } else {
        setDefaultCalendar(calendars.find((item) => item.text === "Personal"));
      }
    }
  }, [editEventMode, calendar, calendars, selectedCalendars]);

  const onEnter = (event) => {
    if (event.key === "Enter") {
      if (isValidDatetime) {
        document.getElementById("eventpopup-btn-submit").click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", onEnter);

    return () => {
      window.removeEventListener("keyup", onEnter);
    };
  }, []);

  return (
    <div className="create-eventpopup">
      <div className="create-eventpopup-header">
        <div className="create-eventpopup-header-title">
          {editEventMode ? (
            <p>{t("SERVICES.CALENDAR.EDIT_EVENT")}</p>
          ) : (
            <p>{t("SERVICES.CALENDAR.CREATE_EVENT")}</p>
          )}
        </div>

        <div className="create-eventpopup-header-btn">
          <IconButton onClick={closeEventPopup}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className="create-eventpopup-list">
        <div className="create-eventpopup-item">
          <p className="create-eventpopup-item-title">
            {t("SERVICES.CALENDAR.EVENT")}
          </p>

          <FormControl fullWidth>
            <TextField
              autoFocus
              className="create-eventpopup-item-input"
              value={eventName}
              onChange={handleEventName}
            />
          </FormControl>
        </div>

        <div className="create-eventpopup-item">
          <p className="create-eventpopup-item-title">
            {t("SERVICES.CALENDAR.EVENT_FORM.START")}
          </p>

          <div className="create-eventpopup-item-double">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                format={DEFAULT_DATE_FORMAT}
                value={startDate}
                onChange={handleStartDate}
              />
            </LocalizationProvider>

            {!allDay ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DesktopTimePicker"]}>
                  <DemoItem>
                    <DesktopTimePicker
                      timeSteps={{ minutes: 15 }}
                      value={startTime}
                      onChange={handleStartTime}
                      endAdornment={
                        <InputAdornment position="end">
                          <FilterListIcon />
                        </InputAdornment>
                      }
                      ampm={false}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="create-eventpopup-item">
          <p className="create-eventpopup-item-title">
            {t("SERVICES.CALENDAR.EVENT_FORM.END")}
          </p>

          <div className="create-eventpopup-item-double">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                format={DEFAULT_DATE_FORMAT}
                onError={(e) => {
                  if (!e) {
                    setIsValidDatetime(true);
                  } else {
                    setIsValidDatetime(false);
                  }
                }}
                minDate={startDate}
                value={endDate}
                onChange={handleEndDate}
              />
            </LocalizationProvider>

            {!allDay ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DesktopTimePicker"]}>
                  <DemoItem>
                    <DesktopTimePicker
                      timeSteps={{ minutes: 15 }}
                      minTime={
                        dayjs(startDate).diff(endDate) === 0
                          ? startTime
                          : undefined
                      }
                      value={endTime}
                      onChange={handleEndTime}
                      onError={(e) => {
                        if (!e) {
                          setIsValidDatetime(true);
                        } else {
                          setIsValidDatetime(false);
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <FilterListIcon />
                        </InputAdornment>
                      }
                      ampm={false}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="create-eventpopup-item">
          <div className="create-eventpopup-item-checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  checked={allDay}
                  value={allDay}
                  onChange={handleAllDay}
                />
              }
              label="All Day"
            />
          </div>
        </div>

        <div className="create-eventpopup-item">
          <p className="create-eventpopup-item-title">
            {t("SERVICES.CALENDAR.TITLE")}
          </p>

          {defaultCalendar && (
            <FormControl fullWidth>
              <Select
                className="create-eventpopup-item-select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={defaultCalendar.id}
                onChange={handleCalendar}
              >
                {calendars.map((calendarOption) => (
                  <MenuItem
                    className="calendar_create-eventpopup-item-select-item"
                    value={calendarOption.id}
                  >
                    {calendarOption.text}{" "}
                    <span
                      className="color-bullet"
                      style={{ backgroundColor: calendarOption.color }}
                    ></span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>

        <div className="create-eventpopup-item note">
          <p className="create-eventpopup-item-title">
            {t("SERVICES.CALENDAR.EVENT_FORM.NOTE")}
          </p>

          <FormControl fullWidth>
            <TextField
              className="create-eventpopup-item-input"
              variant="outlined"
              value={details}
              onChange={handleDetails}
              multiline
              maxRows={5}
            />
          </FormControl>
        </div>

        {selectedEvent?.roomUid && (
          <div className="create-eventpopup-btn">
            <Button
              sx={{
                backgroundColor: "#51A3FF",
                fontSize: 13,
                width: 120,
                borderRadius: 30,
              }}
              variant="contained"
              onClick={() => handleMeetingNow(selectedEvent?.roomUid)}
            >
              {t("VIDEO_CONFERENCING_PAGE.MEET_NOW")}
            </Button>
          </div>
        )}

        <div className="create-eventpopup-btn">
          <Button
            className="create-eventpopup-btn-reset"
            onClick={handleDeleteEvent}
          >
            {editEventMode ? t("ACTIONS.DELETE") : t("ACTIONS.RESET")}
          </Button>

          <Button
            id="eventpopup-btn-submit"
            className="create-eventpopup-btn-submit"
            onClick={handleSubmitEvent}
            disabled={!isValidDatetime}
          >
            {t("ACTIONS.SUBMIT")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateEventsPopup;
