import dayjs from "dayjs";
import {
  SECONDARY_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../constants/datetime";

export const renderMeetingTime = (meeting, withoutDate = false) => {
  if (withoutDate) {
    return `${dayjs(meeting.startAt).format(DEFAULT_TIME_FORMAT)} ${
      meeting.endAt
        ? `- ${dayjs(meeting.endAt).format(DEFAULT_TIME_FORMAT)}`
        : ""
    }`;
  }

  return `${dayjs(meeting.startAt).format(SECONDARY_DATE_FORMAT)} at ${dayjs(
    meeting.startAt
  ).format(DEFAULT_TIME_FORMAT)} ${
    meeting.endAt ? `- ${dayjs(meeting.endAt).format(DEFAULT_TIME_FORMAT)}` : ""
  }`;
};
