import React, { useEffect, useMemo, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useCurrentUser } from "../../../../hooks/auth.hook";
import { useDispatch } from "react-redux";
import { handleCloseMeetingPageModal } from "../../../../redux/app/appsModalSlice";
import { useSearchParams } from "react-router-dom";
import {
  getMeetingRoomByRoomUid,
  updateMeetingRoom,
  verifyPasswordMeetingRoom,
} from "../../../../api/Api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingButton } from "@mui/lab";

const MeetingPageMain = ({ isGuest }) => {
  const { data: currentUser } = useCurrentUser();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [room, setRoom] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const schema = yup
    .object({
      password: yup
        .string()
        .min(8, "Password must contain at least 8 characters")
        .max(32)
        .required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const formatRoomData = (roomData) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const mtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startAt = dayjs(roomData.startAt).tz(mtimezone);
    let endAt = null;
    if (roomData.endAt) {
      endAt = dayjs(roomData.endAt).tz(mtimezone);
    }

    return {
      ...roomData,
      startAt: startAt,
      endAt: endAt,
    };
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getRoomInfo = async (roomUid) => {
    try {
      const response = await getMeetingRoomByRoomUid(roomUid);

      if (response.data) {
        const roomData = formatRoomData(response.data.meetingRoom);
        setRoom(roomData);
      }
    } catch (error) {
      console.log("err", error);
      toast.error(t("VIDEO_CONFERENCING.ERROR_LOAD_ROOM"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (params.get("meeting")) {
      getRoomInfo(params.get("meeting"));
    }
  }, [params]);

  const isRequirePassword = useMemo(() => {
    if (room?.userId === currentUser?.id) {
      return false;
    }
    if (room) {
      const isUsePassword = room.roomUid.split("-")[1];
      return isUsePassword === "RP";
    }
    return false;
  }, [room, currentUser]);

  const isAskingPassword = useMemo(() => {
    if (!isRequirePassword) {
      return false;
    }
    if (isRequirePassword && isPasswordValid) {
      return false;
    }
    return true;
  }, [isRequirePassword, isPasswordValid]);

  const onSubmit = async (payload) => {
    try {
      setIsVerifying(true);
      const response = await verifyPasswordMeetingRoom(
        room.id,
        payload.password
      );
      const { data } = response;
      setIsVerifying(false);
      if (data?.isValid) {
        // redirect to meeting room
        setIsPasswordValid(true);
        toast.success(t("VIDEO_CONFERENCING.VALID_PASSWORD"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(t("VIDEO_CONFERENCING.INVALID_PASSWORD"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log("errr", error);
      setIsVerifying(false);
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderPasswordSession = () => {
    return (
      <div className="meeting-page_main-wrapper">
        <div
          className="video_host"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="cloud signin__block">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="site-input-label">
                {t("VIDEO_CONFERENCING.REQUIRED_PASSWORD")}
              </label>
              <div className="signin__form-group" style={{ marginBottom: 25 }}>
                <label className="site-input-label">
                  {t("SIGNIN.PASSWORD")}
                </label>
                <OutlinedInput
                  style={{ width: "100%", marginBottom: 10 }}
                  error={!!errors.password}
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ fontSize: 16, padding: 0 }}
                        onClick={toggleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityOutlined style={{ fontSize: 16 }} />
                        ) : (
                          <VisibilityOffOutlined style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.password?.message && (
                  <Box sx={{ display: "flex", alignItems: "center" }} mt={1}>
                    <ErrorOutlineIcon
                      fontSize="14"
                      sx={{ mr: "5px", color: "#EF4051" }}
                    />
                    <p className="site-input-error">
                      {errors.password?.message}
                    </p>
                  </Box>
                )}

                <div className="signin__form-group login-submit">
                  <LoadingButton
                    className="btn btn--w-100"
                    type="submit"
                    loading={isVerifying}
                    variant="contained"
                    sx={{
                      borderRadius: 35,
                      fontSize: 15,
                      color: "#FFF",
                      fontWeight: "500 !important",
                      height: 45,
                      background: "#51A3FF",
                      textTransform: "none",
                      boxShadow: "none",
                    }}
                  >
                    {t("SIGNIN.SUBMIT_BUTTON")}
                  </LoadingButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (isAskingPassword) {
    return renderPasswordSession();
  }

  if (!isGuest && isAskingPassword) {
    return renderPasswordSession();
  }

  return (
    <div className="meeting-page_main-wrapper">
      <div className="video_host">
        {room && (
          <JitsiMeeting
            domain={process.env.REACT_APP_VIDEO_CONNECTOR_DOMAIN}
            roomName={room.roomName ?? "N/A"}
            lang={i18n.language}
            configOverwrite={{
              startWithAudioMuted: true,
              disableModeratorIndicator: true,
              startScreenSharing: true,
              enableEmailInStats: false,
              prejoinConfig: {
                enabled: false,
              },
            }}
            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            }}
            userInfo={{
              displayName: currentUser
                ? `${currentUser?.firstName} ${currentUser?.lastName}`
                : "",
            }}
            onApiReady={async (externalApi) => {
              if (currentUser?.id === room.userId) {
                externalApi.executeCommand("grantModerator", currentUser.id);
              }

              externalApi.on("participantRoleChanged", function (event) {
                if (currentUser) {
                  if (event.role === "moderator") {
                    if (room.isEnabledLobby) {
                      externalApi.executeCommand("toggleLobby", true);
                    }
                    if (room.isEnabledE2EE) {
                      externalApi.executeCommand("toggleE2EE", true);
                    }
                  }
                }
              });
              externalApi.on("participantJoined", async function () {
                if (!currentUser) {
                  const roomInfo = await externalApi.getRoomsInfo();
                  const { rooms } = roomInfo;
                  const currentRoom = rooms[0];
                  const guests = currentRoom?.participants?.filter(
                    (p) => p.role !== "moderator"
                  );
                  externalApi.executeCommand(
                    "displayName",
                    `Guest #${guests.length}`
                  );
                }
              });
            }}
            onReadyToClose={async (externalApi) => {
              if (room.frecency === "ONCE") {
                await updateMeetingRoom(room.id, { isArchived: true });
              }

              if (room.isEnabledLobby) {
                externalApi.executeCommand("toggleLobby", false);
              }
              if (room.isEnabledE2EE) {
                externalApi.executeCommand("toggleE2EE", false);
              }

              dispatch(handleCloseMeetingPageModal());
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = "100%";
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MeetingPageMain;
