import React from 'react';
import SigninForm from './components/SigninForm';
import { authHook } from '../../hooks/auth.hook';


function Signin() {
  if (authHook.isAuthenticated) {
    return window.location.href = '/';
  }

  return <SigninForm />;
}

export default Signin;
