import { useQuery } from "@tanstack/react-query";
import { getProjectTask, createProjectTask, updateProjectTask } from "../api/Api"

const fetchTask = async (projectId) => {
  const response = await getProjectTask(projectId);
  const { task } = response.data;

  return task;
}

const createTask = async (projectId, taskInit) => {
  return createProjectTask(projectId, taskInit);
}

const updateTask = async (taskId, taskData) => {
  return updateProjectTask(taskId, taskData)
}

const useCurrentTask = (projectId) => {
  return useQuery({
    queryKey: ['currentTask', projectId],
    queryFn: () => fetchTask(projectId),
    enabled: false
  })
}

export { useCurrentTask, createTask, updateTask };
