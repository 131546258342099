import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./video-conferencing-leftside.scss";
import meetIcon from "../../../../../assets/images/vide-conferencing/meet.png";
import scheduleIcon from "../../../../../assets/images/vide-conferencing/schedule.png";
import MeetNow from "./meet-now/MeetNow";
import Schedule from "./schedule/Schedule";
import { useSelector, useDispatch } from "react-redux";
import { openNewMeeting } from "../../../../../redux/app/popupSlice";

const VideoConferencingLeftSide = () => {
  const { t } = useTranslation();

  const [showMain, setShowMain] = useState(true);
  const [showMeet, setShowMeet] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const openedNewMeeting = useSelector((state) => state.popup.openedNewMeeting);
  const dispatch = useDispatch();

  const handleShowMain = () => {
    setShowMain(true);
    setShowMeet(false);
    setShowSchedule(false);
  };

  const handleShowMeet = () => {
    setShowMeet(true);
    setShowMain(false);
  };

  const handleShowSchedule = () => {
    setShowSchedule(true);
    setShowMain(false);
  };

  useEffect(() => {
    if (openedNewMeeting) {
      handleShowMeet();
      dispatch(openNewMeeting(false));
    }
  }, [openedNewMeeting]);

  return (
    <div className="video-conferencing_leftside">
      <div
        className={`video-conferencing_leftside_actions ${
          !showMain ? "close" : ""
        }`}
      >
        <div
          className="video-conferencing_leftside_actions-btn"
          onClick={handleShowMeet}
        >
          <img src={meetIcon} alt="Meet now" />
          <p>{t("VIDEO_CONFERENCING_PAGE.MEET_NOW")}</p>
        </div>

        <div
          className="video-conferencing_leftside_actions-btn"
          onClick={handleShowSchedule}
        >
          <img src={scheduleIcon} alt="Schedule" />
          <p>{t("VIDEO_CONFERENCING_PAGE.SCHEDULE")}</p>
        </div>
      </div>

      <div className={`meetnow-container ${showMeet ? "open" : ""}`}>
        <MeetNow handleShowMain={() => handleShowMain()} />
      </div>

      <div className={`schedule-container ${showSchedule ? "open" : ""}`}>
        <Schedule handleShowMain={() => handleShowMain()} />
      </div>
    </div>
  );
};

export default VideoConferencingLeftSide;
