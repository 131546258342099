import React, { useEffect } from "react";
import SigninFormLoginStage from "./SigninFormLoginStage";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/logo-new.png";
import { Link, Divider } from "@mui/material";
import TagManager from "react-gtm-module";

function SigninForm() {
  const { t } = useTranslation();

  useEffect(() => {
    if (["reg.unlyme.com", "id.unlyme.com"].includes(window.location.hostname))
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: window.location.pathname + window.location.search,
      },
    });
  }, []);

  return (
    <div className="signin">
      <div className="signin-wrapper">
        <div className="cloud signin__block">
          <div className="signin__logo">{t("SIGNIN.TITLE")}</div>
          <div className="signin__subtitle">{t("SIGNIN.SUBTITLE")}</div>
          <SigninFormLoginStage />
        </div>
      </div>
      <div className="signin__footer-link">
        <div className="signin__footer-link-title">
          {t('Unlyme')}
        </div>
        <div className="signin__footer-link-links">
          <Link>
            {t('COMMON.TERMS')}
          </Link>
          <Divider orientation="vertical" flexItem />
          <Link>
            {t('COMMON.PRIVACY_POLICY')}
          </Link>
          <Divider orientation="vertical" flexItem />
          <div>
            {t('COMMON.VERSION', { version: '1.0.0' })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SigninForm;
