import React, { useState } from 'react';
import './invoices-popups.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const InvoiceRecordPopup = ({ handleCloseInvoiceRecordPopup, data}) => {
  const { t } = useTranslation();

  return (
    <div className='invoices-invoicerecordpopup'>
      <div className='invoices-invoicerecordpopup-header'>
        <div className='invoices-invoicerecordpopup-header-title'>
          <p>Invoice record</p>
        </div>

        <div className='invoices-invoicerecordpopup-header-btn'>
          <IconButton onClick={handleCloseInvoiceRecordPopup}>
            <CloseIcon />
          </IconButton>
        </div>

      </div>

      <div className='invoices-invoicerecordpopup-list'>
        <table className='invoices-invoicerecordpopup-table'>
            <tbody className='invoices-invoicerecordpopup-table-body'>
                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.INVOICE_ID")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>#{data.id}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.CLIENT")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.creator}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.PROJECT")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.project}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.AMOUNT")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.amount}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.INVOICE_DATE")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.date}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.DUE_DATE")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.date}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.CATEGORY")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.category}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.TAGS")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.tags}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.NOTES")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.notes}</td>
                </tr>

                <tr className='invoices-invoicerecordpopup-table-body-tr'>
                    <td className='invoices-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.TERMS_AND_CONDITIONS")}</td>
                    <td className='invoices-invoicerecordpopup-table-body-tr-value'>{data.terms}</td>
                </tr>
            </tbody>
        </table>
      </div>

    </div>



  );
}


export default InvoiceRecordPopup;
