import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { DropzoneDialog } from "material-ui-dropzone";
import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { handleCloseAppsModal } from "../../../redux/app/appsModalSlice";
import CloseIcon from "@mui/icons-material/Close";
import search from "../../../assets/images/header/new-icons/search.svg";
import { useSelector, useDispatch } from "react-redux";
import aaIcon from "../../../assets/images/notepage/new/Aa.svg";
import bulletIcon from "../../../assets/images/notepage/new/bullet-list.svg";
import copyIcon from "../../../assets/images/notepage/new/image.svg";
import lockIcon from "../../../assets/images/notepage/new/lock.svg";
import moreIcon from "../../../assets/images/notepage/new/more.svg";
import imageIcon from "../../../assets/images/notepage/new/image.svg";
import attachIcon from "../../../assets/images/notepage/new/attach.svg";
import pinIcon from "../../../assets/images/notepage/new/pin.svg";
import eyeIcon from "../../../assets/images/notepage/new/eye.svg";
import trashIcon from "../../../assets/images/notepage/new/trash.svg";
import shareIcon from "../../../assets/images/notepage/new/share.svg";
import tableIcon from "../../../assets/images/notepage/new/table.svg";
import arrowDownIcon from "../../../assets/images/notepage/new/arrow-down.svg";
import checkedUsersIcon from "../../../assets/images/notepage/new/check-user.svg";
import uploadIcon from "../../../assets/images/invoice-manager/upload-cloud.png";
import { BubbleMenu, FloatingMenu } from "@tiptap/react";
import Focus from "@tiptap/extension-focus";
import { sampleUsers } from "../../../mocks/mocks";
import { useProjectUsers } from "../../../hooks/users.hook";
import { useCurrentProject } from "../../../hooks/projects";
import { Avatar } from "@mui/material";
import { stringAvatar } from "../../../utils/AvatarUtil";
import { renderFullName } from "../../../utils/UserUtil";
import { useCurrentUser } from "../../../hooks/auth.hook";
import { toast } from "react-toastify";
import { generateUploadUrl, updateNote } from "../../../api/Api";
import { S3Upload } from "../../../services/S3Uploader";

const NotesDetailsBar = ({
  searchNote,
  editor,
  handleDeleteNote,
  showNote,
  handleUpdatePassword,
  handleUpdateNotePinned,
  handleUpdateNoteMembers,
  setNoteMembers,
  noteMembers,
  selectedNote,
  setSelectedNote,
}) => {
  const { t } = useTranslation();
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  const { data: currentProject } = useCurrentProject();
  const { data: projectUsers } = useProjectUsers(currentProject?.id);
  const { data: currentUser } = useCurrentUser();

  const projectUsersWithoutOwner = useMemo(() => {
    if (projectUsers?.length > 0) {
      return projectUsers.filter((pu) => pu.id !== currentUser?.id);
    }
    return [];
  }, [projectUsers]);

  // start lock menu popup
  const [anchorLock, setAnchorLock] = useState(null);
  const openLockOptions = Boolean(anchorLock);
  const handleClickLockOptions = (event) => {
    setAnchorLock(event.currentTarget);
  };
  const handleCloseLockOptions = () => {
    setAnchorLock(null);
  };

  const [anchorLockConfirmation, setAnchorLockConfirmation] = useState(null);
  const handleClickLockConfirmation = (event) => {
    setAnchorLockConfirmation(event.currentTarget);
  };
  const handleCloseLockConfirmation = () => {
    setAnchorLockConfirmation(null);
  };
  const openLockConfirmation = Boolean(anchorLockConfirmation);
  const lockConfirmationId = openLockConfirmation
    ? "simple-popover"
    : undefined;

  const [password, setPassword] = useState("");
  // end lock menu popup

  // start share menu popup
  const [anchorShare, setAnchorShare] = useState(null);
  const openShareOptions = Boolean(anchorShare);
  const handleClickShareOptions = (event) => {
    setAnchorShare(event.currentTarget);
  };
  const handleCloseShareOptions = () => {
    setAnchorShare(null);
  };

  const [anchorShareConfirmation, setAnchorShareConfirmation] = useState(null);
  const handleClickShareConfirmation = (event) => {
    setAnchorShareConfirmation(event.currentTarget);
  };

  const handleCloseShareConfirmation = () => {
    setAnchorShareConfirmation(null);
    setNoteMembers(selectedMembers);

    handleUpdateNoteMembers(selectedMembers);
  };

  const openShareConfirmation = Boolean(anchorShareConfirmation);
  const shareConfirmationId = openShareConfirmation
    ? "simple-popover"
    : undefined;

  // start selecting assign user
  const [searchMembersTerm, setSearchMembersTerm] = useState("");
  const [users, setUsers] = useState(sampleUsers);
  const [userChecked, setUserChecked] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([...noteMembers]);

  const handleAssignUser = (user) => {
    // if (showNote) {
    const isUserChecked = selectedMembers.some(
      (checkedUser) => checkedUser.id === user.id
    );

    if (!isUserChecked) {
      // Add the user to the selected members
      setSelectedMembers([...selectedMembers, user]);
    } else {
      // Remove the user from the selected members
      setSelectedMembers(
        selectedMembers.filter((checkedUser) => checkedUser.id !== user.id)
      );
    }
  };
  // end selecting assign user

  // end share menu popup

  // start view menu popup
  const [anchorView, setAnchorView] = useState(null);
  const openViewOptions = Boolean(anchorView);
  const handleClickViewOptions = (event) => {
    setAnchorView(event.currentTarget);
  };
  const handleCloseViewOptions = async (visibility) => {
    setAnchorView(null);
    try {
      await updateNote(showNote, { visibility: visibility });
      setSelectedNote({
        ...selectedNote,
        visibility: visibility,
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  // end view menu popup

  // start search menu popup
  const [searchFocused, setSearchFocused] = useState(null);

  const [searchText, setSearchText] = useState(searchNote);
  // end search menu popup

  // start table bubble menu
  const [anchorTableBubbleMenu, setAnchorTableBubbleMenu] = useState(null);
  const openTableBubbleMenuOptions = Boolean(anchorTableBubbleMenu);
  const handleClickTableBubbleMenuOptions = (event) => {
    setAnchorTableBubbleMenu(event.currentTarget);
  };
  const handleCloseTableBubbleMenuOptions = () => {
    setAnchorTableBubbleMenu(null);
    editor.commands.blur();
  };
  // end table bubble menu

  // start get position of the table for bubble menu
  const [showBubble, setShowBubble] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const tables = document.getElementsByClassName("tableWrapper");
    if (tables.length > 0) {
      const table = tables[0];
      const rect = table.getBoundingClientRect();
      setPosition({
        top: rect.top - 130,
        left: rect.left,
      });
    }

    if (editor.isActive("table")) {
      setShowBubble(true);
    } else {
      setShowBubble(false);
    }
  }, [editor.isActive("table")]);

  // end get position of the table for bubble menu

  // start handle uploaded image
  const [imageFile, setImageFile] = useState(null);
  const [openUploadArea, setOpenUploadArea] = useState(false);

  const handleImageUpload = async (file) => {
    try {
      if (file) {
        const signedUrlResponse = await generateUploadUrl(
          showNote,
          file?.name,
          "note"
        );
        const { url } = signedUrlResponse.data;

        const { uploaded, uploadedPath } = await S3Upload(
          url,
          file,
          () => {},
          () => {},
          () => {}
        );

        if (uploaded) {
          editor.chain().focus().setImage({ src: uploadedPath }).run();
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const CustomUploadIcon = () => <img src={uploadIcon} />;
  // end handle uploaded image

  return (
    <div className="tiptap-editor__actions">
      {editor && (
        <div
          style={{
            display: showBubble ? "inline-block" : "none",
            top: position.top,
          }}
          className="tiptap-editor__actions-bubble"
        >
          <>
            <IconButton
              className="tiptap-editor__actions-bubble_btn"
              value="table-bubble"
              aria-label="table-bubble"
              aria-controls={
                openTableBubbleMenuOptions ? "table-bubble-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openTableBubbleMenuOptions ? "true" : undefined}
              onClick={handleClickTableBubbleMenuOptions}
            >
              <MoreHorizIcon />
            </IconButton>

            <Menu
              className="table-menu__list"
              id="table-bubble-menu"
              anchorEl={anchorTableBubbleMenu}
              open={openTableBubbleMenuOptions}
              onClose={handleCloseTableBubbleMenuOptions}
            >
              <MenuItem
                className="table-menu__list-item"
                onClick={() => {
                  editor.chain().focus().addColumnBefore().run();
                  handleCloseTableBubbleMenuOptions();
                }}
                variant="contained"
              >
                {t("NOTES_PAGE.ADD_COLUMN_LEFT")}
              </MenuItem>
              <MenuItem
                className="table-menu__list-item"
                onClick={() => {
                  editor.chain().focus().addColumnAfter().run();
                  handleCloseTableBubbleMenuOptions();
                }}
                variant="contained"
              >
                {t("NOTES_PAGE.ADD_COLUMN_RIGHT")}
              </MenuItem>
              <MenuItem
                className="table-menu__list-item"
                onClick={() => {
                  editor.chain().focus().deleteColumn().run();
                  handleCloseTableBubbleMenuOptions();
                }}
                variant="contained"
              >
                {t("NOTES_PAGE.DELETE_COLUMN")}
              </MenuItem>
              <MenuItem
                className="table-menu__list-item"
                onClick={() => {
                  editor.chain().focus().addRowBefore().run();
                  handleCloseTableBubbleMenuOptions();
                }}
                variant="contained"
              >
                {t("NOTES_PAGE.ADD_ROW_ABOVE")}
              </MenuItem>
              <MenuItem
                className="table-menu__list-item"
                onClick={() => {
                  editor.chain().focus().addRowAfter().run();
                  handleCloseTableBubbleMenuOptions();
                }}
                variant="contained"
              >
                {t("NOTES_PAGE.ADD_ROW_BELOW")}
              </MenuItem>
              <MenuItem
                className="table-menu__list-item"
                onClick={() => {
                  editor.chain().focus().deleteRow().run();
                  handleCloseTableBubbleMenuOptions();
                }}
                variant="contained"
              >
                {t("NOTES_PAGE.DELETE_ROW")}
              </MenuItem>
              <MenuItem
                className="table-menu__list-item"
                onClick={() => {
                  editor.chain().focus().deleteTable().run();
                  handleCloseTableBubbleMenuOptions();
                }}
                variant="contained"
              >
                {t("NOTES_PAGE.DELETE_TABLE")}
              </MenuItem>
            </Menu>
          </>
        </div>
      )}

      <div className="tiptap-editor__actions-container">
        <div
          className="cloud-page__header_notes-details_actions-left"
          style={{ marginRight: secondPopupTab ? "30px" : "0" }}
        >
          <ButtonGroup variant="outlined" aria-label="text formatting">
            <IconButton
              value="bold"
              aria-label="bold"
              onClick={() => {
                editor.chain().focus().toggleBold().run();
              }}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              disableRipple
              className={editor.isActive("bold") ? "is-active" : ""}
            >
              <img src={aaIcon} />
            </IconButton>

            <IconButton
              value="bullet"
              aria-label="bullet"
              onClick={() => editor.chain().focus().toggleTaskList().run()}
              disableRipple
              className={editor.isActive("taskList") ? "is-active" : ""}
            >
              <img src={bulletIcon} />
            </IconButton>

            <IconButton
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                  .run()
              }
              value="table"
              aria-label="table"
              disableRipple
            >
              <img src={tableIcon} />
            </IconButton>

            <IconButton
              value="image"
              aria-label="image"
              disableRipple
              onClick={() => setOpenUploadArea(true)}
            >
              <img src={imageIcon} />
            </IconButton>

            <DropzoneDialog
              acceptedFiles={["image/*"]}
              dropzoneClass="backlog-modal_content_left_item-attach"
              dropzoneText={"Drop files here, or click to upload"}
              cancelButtonText={"cancel"}
              submitButtonText={"submit"}
              maxFileSize={5000000}
              value={imageFile}
              open={openUploadArea}
              onClose={() => setOpenUploadArea(false)}
              onSave={async (files) => {
                if (files.length > 0) {
                  await handleImageUpload(files[0]);
                }
                setOpenUploadArea(false);
              }}
              showPreviews={true}
              showFileNamesInPreview={true}
              filesLimit={1}
              Icon={CustomUploadIcon}
            />
          </ButtonGroup>
        </div>

        <div className="cloud-page__header_notes-details_actions-right">
          <div className="cloud-page__header_notes-details_actions-right-btns">
            <ButtonGroup variant="outlined" aria-label="actions">
              {currentUser?.id === selectedNote?.userId && (
                <IconButton
                  value="lock"
                  aria-label="lock"
                  disableRipple
                  aria-controls={openLockOptions ? "lock-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLockOptions ? "true" : undefined}
                  onClick={handleClickLockOptions}
                >
                  <img src={lockIcon} />

                  <img className="arrow" src={arrowDownIcon} />
                </IconButton>
              )}

              <Menu
                className="locknote-menu-popup"
                id="lock-menu"
                anchorEl={anchorLock}
                open={openLockOptions}
                onClose={handleCloseLockOptions}
              >
                <MenuItem
                  className="locknote-menu-popup_item"
                  disabled
                  variant="contained"
                >
                  {t("NOTES_PAGE.CLOSE_ALL_PROTECTED_NOTES")}
                </MenuItem>

                <Divider variant="middle" component="li" />

                <MenuItem
                  className="locknote-menu-popup_item"
                  // onClick={handleCloseLockOptions}
                  aria-describedby={lockConfirmationId}
                  variant="contained"
                  onClick={handleClickLockConfirmation}
                >
                  {t("NOTES_PAGE.SET_PASSWORD_PROTECTION")}
                </MenuItem>

                <Popover
                  className="locknote-options-popup"
                  id={lockConfirmationId}
                  open={openLockConfirmation}
                  anchorEl={anchorLockConfirmation}
                  onClose={handleCloseLockConfirmation}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div className="locknote-options-popup_header">
                    <p>{t("NOTES_PAGE.SET_PASSWORD_PROTECTION")}</p>

                    <IconButton onClick={handleCloseLockConfirmation}>
                      <CloseIcon />
                    </IconButton>
                  </div>

                  <div className="locknote-options-popup_info">
                    <p>{t("NOTES_PAGE.SET_PASSWORD_PROTECTION_DESC")}</p>

                    <input
                      className="locknote-options-popup_info-input"
                      placeholder="Write your password..."
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="locknote-options-popup_actions">
                    <Button
                      className="locknote-options-popup_actions-close"
                      onClick={handleCloseLockConfirmation}
                    >
                      {t("ACTIONS.CLOSE")}
                    </Button>
                    <Button
                      className="locknote-options-popup_actions-confirm"
                      onClick={() => {
                        // handleLockNote(password)
                        handleUpdatePassword(showNote, password);
                      }}
                    >
                      {t("ACTIONS.CONFIRM")}
                    </Button>
                  </div>
                </Popover>
              </Menu>

              {currentUser?.id === selectedNote?.userId && (
                <IconButton
                  value="share"
                  aria-label="share"
                  disableRipple
                  aria-controls={openShareOptions ? "share-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openShareOptions ? "true" : undefined}
                  onClick={handleClickShareOptions}
                >
                  <img src={shareIcon} />

                  <img className="arrow" src={arrowDownIcon} />
                </IconButton>
              )}

              <Menu
                className="sharenote-menu-popup"
                id="share-menu"
                anchorEl={anchorShare}
                open={openShareOptions}
                onClose={handleCloseShareOptions}
              >
                <MenuItem
                  className="sharenote-menu-popup_item"
                  aria-describedby={shareConfirmationId}
                  variant="contained"
                  onClick={handleClickShareConfirmation}
                >
                  {t("NOTES_PAGE.MEMBERS")}
                </MenuItem>

                <Popover
                  className="sharenote-options-popup"
                  id={shareConfirmationId}
                  open={openShareConfirmation}
                  anchorEl={anchorShareConfirmation}
                  onClose={handleCloseShareConfirmation}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div className="sharenote-options-popup-container">
                    <div className="sharenote-options-popup-header">
                      <p> {t("NOTES_PAGE.MEMBERS")}</p>

                      <IconButton onClick={handleCloseShareConfirmation}>
                        <CloseIcon />
                      </IconButton>
                    </div>

                    <div className="sharenote-options-popup-input">
                      <TextField
                        className="sharenote-options-popup-input-field"
                        value={searchMembersTerm}
                        onChange={(e) => setSearchMembersTerm(e.target.value)}
                        placeholder={t("NOTES_PAGE.SEARCH_MEMBERS")}
                      />
                    </div>

                    <div className="sharenote-options-popup-assign">
                      {projectUsersWithoutOwner
                        ?.filter((user) =>
                          user.name
                            .toLowerCase()
                            .includes(searchMembersTerm.toLowerCase())
                        )
                        .map((user) => (
                          <div
                            className="sharenote-options-popup-assign-item"
                            key={user.id}
                            style={{
                              backgroundColor:
                                selectedMembers.findIndex(
                                  (checkedUser) => checkedUser.id === user.id
                                ) !== -1
                                  ? "rgba(81, 163, 255, 0.05)"
                                  : "",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    selectedMembers.findIndex(
                                      (checkedUser) =>
                                        checkedUser.id === user.id
                                    ) !== -1
                                  }
                                  icon={<></>}
                                  checkedIcon={
                                    <img src={checkedUsersIcon} alt="checked" />
                                  }
                                  onChange={() => {
                                    handleAssignUser(user);
                                  }}
                                />
                              }
                              label={
                                <div>
                                  <Avatar
                                    {...stringAvatar(renderFullName(user))}
                                    style={{
                                      width: 26,
                                      height: 26,
                                      fontSize: 12,
                                    }}
                                    src={user.icon}
                                  />

                                  <p>{user.name}</p>
                                </div>
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </Popover>
              </Menu>

              {currentUser?.id === selectedNote?.userId && (
                <IconButton
                  value="eye"
                  aria-label="eye"
                  disableRipple
                  aria-controls={openViewOptions ? "view-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openViewOptions ? "true" : undefined}
                  onClick={handleClickViewOptions}
                >
                  <img src={eyeIcon} />
                </IconButton>
              )}

              <Menu
                className="viewnote-menu-popup"
                id="view-menu"
                anchorEl={anchorView}
                open={openViewOptions}
              >
                <MenuItem
                  className="viewnote-menu-popup_item"
                  variant="contained"
                  onClick={() => handleCloseViewOptions("ALL")}
                  selected={selectedNote?.visibility === "ALL"}
                >
                  {t("NOTES_PAGE.VISIBILITY_ALL")}
                </MenuItem>

                <MenuItem
                  className="viewnote-menu-popup_item"
                  variant="contained"
                  onClick={() => handleCloseViewOptions("ONLY_ME")}
                  selected={selectedNote?.visibility === "ONLY_ME"}
                >
                  {t("NOTES_PAGE.VISIBILITY_ONLY_ME")}
                </MenuItem>

                <MenuItem
                  className="viewnote-menu-popup_item"
                  variant="contained"
                  onClick={() => handleCloseViewOptions("MEMBERS")}
                  selected={selectedNote?.visibility === "MEMBERS"}
                >
                  {t("NOTES_PAGE.VISIBILITY_MEMBERS")}
                </MenuItem>
              </Menu>

              <IconButton
                value="pin"
                aria-label="pin"
                disableRipple
                onClick={() => {
                  handleUpdateNotePinned(showNote);
                }}
              >
                <img src={pinIcon} />
              </IconButton>

              {currentUser?.id === selectedNote?.userId && (
                <IconButton
                  value="trash"
                  aria-label="trash"
                  disableRipple
                  onClick={() => {
                    // console.log(showNote)
                    handleDeleteNote(showNote);
                  }}
                >
                  <img src={trashIcon} />
                </IconButton>
              )}
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesDetailsBar;
