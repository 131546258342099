import React, { useState }from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import TablePagination from '@mui/material/TablePagination';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteClientsPopup from '../popups/DeleteClientsPopup';
import Modal from '@mui/material/Modal';
import trashIcon from '../../../../../assets/images/invoice-manager/Trash.png';
import desctiptionIcon from '../../../../../assets/images/invoice-manager/description.png';
import editIcon from '../../../../../assets/images/invoice-manager/Edit.png';
import moreIcon from '../../../../../assets/images/invoice-manager/more.svg';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const ClientsTableHead = (props) => {
    const { t } = useTranslation();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, setSearchText, sortByDateHandler, invoices, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, setPage } =
      props;


    const headColumns = [
      // {
      //   id: 1,
      //   numeric: false,
      //   disablePadding: true,
      //   label: '',
      //   sortable: false,
      //   with: 70
      // },
      {
        id: 2,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.COMPANY_NAME"),
        sortable: false,
      },
      {
        id: 3,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.ACCOUNT_OWNER"),
        sortable: false,
      },
      {
        id: 4,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.PROJECTS"),
        sortable: false,
      },
      {
        id: 5,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.INVOICES"),
        sortable: false,
      },
      {
        id: 6,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.TAGS"),
      },
      {
        id: 7,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.CATEGORY"),
      },
      {
        id: 8,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.STATUS"),
      },
      {
        id: 9,
        numeric: true,
        disablePadding: true,
        label: t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.ACTIONS"),
      },
    ];



    const options = ["Edit", "Add description", "Delete"];
    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    const handlePrevClick = () => {
      setPage(page - 1);
    };

    const handleNextClick = () => {
      setPage(page + 1);
    };


    // start delete invoice popup
    const [deleteClientsPopup, setDeleteClientsPopup] = useState(false);
    const handleOpenDeleteClientsPopup = () => {
      setDeleteClientsPopup(true)
    };
    const handleCloseDeleteClientsPopup = () => {
      setDeleteClientsPopup(false)
    };
    // end delete invoice popup


    const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
    const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);



    return (
      <>
        <TableHead className='clients-header'>
          <TableRow className='clients-header-row'>
            <TableCell padding="checkbox" className='clients-header-row-selected'>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>


            <TableCell padding="checkbox" className='clients-header-row-option'>
              {numSelected > 0 ?
                numSelected
              :
                ''
              }
            </TableCell>

            <TableCell colSpan={6} padding="checkbox" className='clients-header-row-option'>
              {numSelected > 0 ?
                <div className='clients-header-row-option-container'>
                   <Button
                    startIcon={<img src={trashIcon} />}
                    onClick={handleOpenDeleteClientsPopup}
                    >
                      {t("ACTIONS.DELETE")}
                  </Button>

                  {
                    secondPopupTab ?
                      ''
                    :
                      <Button
                        startIcon={<img src={editIcon} />}
                        onClick={() => {
                          toast.error(t('ERROR.CLICKED_ON_SELECTED_ROW', { action: 'Edit'  }), {
                              position: "top-center",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: false,
                              pauseOnFocusLoss: false,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                          handleClose()
                          }}
                        >
                          {t("ACTIONS.EDIT")}
                      </Button>
                  }


                  {
                    firstPopupTab || secondPopupTab ?
                      ''
                    :
                      <Button
                        startIcon={<img src={desctiptionIcon} />}
                        onClick={() => {
                          toast.error(t('ERROR.CLICKED_ON_SELECTED_ROW', { action: 'Add description' }), {
                              position: "top-center",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: false,
                              pauseOnFocusLoss: false,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                          handleClose()
                        }}
                        >
                          Add description
                      </Button>
                  }


                  {
                    firstPopupTab && !secondPopupTab ?
                      <>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                          className='more'
                        >
                          {/* <MoreHorizIcon sx={{ color: '#00000080' }} /> */}
                          <img src={moreIcon} />
                        </IconButton>

                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          disableScrollLock = {true}
                        >
                          <MenuItem onClick={() => {
                            toast.error(t('ERROR.CLICKED_ON_SELECTED_ROW', { action: 'Add description' }), {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              });
                            handleClose()
                            }}
                          >
                            <img style={{width: '18px', height: '18px', marginRight: '5px'}} src={desctiptionIcon} /> Add description
                          </MenuItem>
                        </Menu>
                      </>

                     : secondPopupTab && firstPopupTab ?
                      <>
                         <IconButton
                           aria-label="more"
                           id="long-button"
                           aria-controls={open ? "long-menu" : undefined}
                           aria-expanded={open ? "true" : undefined}
                           aria-haspopup="true"
                           onClick={handleClick}
                           className='more'
                         >
                           {/* <MoreHorizIcon sx={{ color: '#00000080' }} /> */}
                          <img src={moreIcon} />
                         </IconButton>

                         <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          disableScrollLock = {true}
                        >
                          <MenuItem onClick={() => {
                            toast.error(t('ERROR.CLICKED_ON_SELECTED_ROW', { action: 'Edit'  }), {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              });
                            handleClose()
                            }}
                          >
                            <img style={{width: '18px', height: '18px', marginRight: '5px'}} src={editIcon} /> Edit
                          </MenuItem>

                          <MenuItem onClick={() => {
                            toast.error(t('ERROR.CLICKED_ON_SELECTED_ROW', { action: 'Add description' }), {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              });
                            handleClose()
                            }}
                          >
                            <img style={{width: '18px', height: '18px', marginRight: '5px'}} src={desctiptionIcon} /> Add description
                          </MenuItem>
                        </Menu>
                      </>

                      : ''
                   }
                </div>
              :
                ''
              }
            </TableCell>

            <TableCell colSpan={1} padding="checkbox" className='email-header_pagination'>
              <TablePagination
                className='invoices_pagination'
                // rowsPerPageOptions={[20, 50, 100]}
                // labelDisplayedRows = {({from, to, count}) => `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
                // labelDisplayedRows = {({from, to, count, page}) => `${page + 1} of ${Math.ceil(count / 20)}`}
                labelDisplayedRows = {({from, to, count, page}) => ``}
                rowsPerPageOptions={[]}
                component="div"
                count={invoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                // onPageChange={handleChangePage}
                ActionsComponent={() => (
                  <div className='invoices_pagination-container'>
                    <IconButton
                      onClick={handlePrevClick}
                      disabled={page === 0}
                      aria-label="previous page"
                    >
                      <ChevronLeftIcon />
                    </IconButton>

                    <p><span>{page+1}</span><span>of</span><span>{Math.ceil(invoices.length / 20)}</span></p>

                    <IconButton
                      onClick={handleNextClick}
                      disabled={page >= Math.ceil(invoices.length / rowsPerPage) - 1}
                      aria-label="next page"
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </div>
                )}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableCell>

                  {/* <TableCell
                    // align={headCell.numeric ? 'right' : 'left'}
                    align="center"
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    // sortDirection={orderBy === headCell.id ? order : false}
                    onClick={sortByDateHandler}
                    sx={{ cursor: 'pointer', maxHeight: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                      Date
                      <ImportExportIcon sx={{color: '#999999', verticalAlign : 'middle'}} />
                  </TableCell>

                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label === 'Date' ? 'Date' : ''}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>  */}
          </TableRow>

          <TableRow className='clients-header-row'>
            <TableCell>

            </TableCell>

          {headColumns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              className='clients-header-row-cell'
              // align={headCell.numeric ? 'right' : 'left'}
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          ))}
          </TableRow>
        </TableHead>


        {/* delete ivoice modal */}
        <Modal
          open={deleteClientsPopup}
          onClose={() => handleCloseDeleteClientsPopup()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className='cloud-page__header_invoice-manager-details_add-modal'
        >
          <DeleteClientsPopup handleCloseDeleteClientsPopup={handleCloseDeleteClientsPopup} />
        </Modal>

      </>
    );
}

export default ClientsTableHead;
