import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./notes-page.scss";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import trashIcon from "../../../assets/images/notepage/new/trash.svg";
import smallLockedIcon from "../../../assets/images/notepage/new/small-locked.svg";
import expandIcon from "../../../assets/images/header/expand.png";
import compressIcon from "../../../assets/images/header/compress.png";
import { useSelector, useDispatch } from "react-redux";
import {
  openNote,
  toggleNotePopup,
  toggleSecondPopupTab,
} from "../../../redux/app/popupSlice";
import {
  handleCloseAppsModal,
  handleOpenAppModalIsFullPage,
  handleCloseAppModalIsFullPage,
  handleSetSelectedNoteTab,
} from "../../../redux/app/appsModalSlice";
import NotesDetailsBar from "./NotesDetailsBar";

import "./tiptap.scss";
import Placeholder from "@tiptap/extension-placeholder";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Image } from "@tiptap/extension-image";

import Gapcursor from "@tiptap/extension-gapcursor";
import Paragraph from "@tiptap/extension-paragraph";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import NotesLockPage from "./NotesLockPage";

import CloseIcon from "@mui/icons-material/Close";
import pinIcon from "../../../assets/images/notepage/new/pin.svg";
import lockIcon from "../../../assets/images/notepage/new/lock.svg";
import bulletIcon from "../../../assets/images/notepage/new/bullet-list.svg";
import attachIcon from "../../../assets/images/notepage/new/attach.svg";
import ButtonGroup from "@mui/material/ButtonGroup";
import search from "../../../assets/images/header/new-icons/search.svg";
import shareIcon from "../../../assets/images/notepage/new/share.svg";
import lockpageIcon from "../../../assets/images/notepage/new/lockpage.svg";
import documentGrayIcon from "../../../assets/images/notepage/new/document.svg";
import documentColorIcon from "../../../assets/images/notepage/new/document-color.svg";
import {
  createNote,
  deleteNote,
  updateNote as updateNoteApi,
  verifyPasswordNote,
} from "../../../api/Api";
import { useCurrentProject } from "../../../hooks/projects";
import { useNotes } from "../../../hooks/note.hook";
import { useDebounce } from "../../../utils/TimmerDebounce";
import { stringAvatar } from "../../../utils/AvatarUtil";
import { renderFullName } from "../../../utils/UserUtil";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, id, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      id={`vertical-tabpanel-${id}`}
      aria-labelledby={`vertical-tab-${id}`}
      {...other}
    >
      {value === id && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(id) {
  return {
    id: `vertical-tab-${id}`,
    "aria-controls": `vertical-tabpanel-${id}`,
  };
}

const NotesPageContent = ({
  searchNote,
  setSearchNote,
  viewMode,
  currentTitle,
  setCurrentTitle,
  currentMessage,
  setCurrentMessage,
  firstTimeNoteAppLaunch,
  setFirstTimeNoteAppLaunch,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);
  const appIsFullPage = useSelector(
    (state) => state.appsModal.appModalIsFullPage
  );
  const selectedNoteTab = useSelector(
    (state) => state.appsModal.selectedNoteTab
  );

  const { data: currentProject } = useCurrentProject();

  const openFullPage = () => {
    setFirstTimeNoteAppLaunch(false);
    setNewNoteToggler(false);

    dispatch(handleOpenAppModalIsFullPage());
  };

  const closeFullPage = () => {
    setFirstTimeNoteAppLaunch(false);
    setNewNoteToggler(false);

    dispatch(handleCloseAppModalIsFullPage());
  };

  const [showNote, setShowNote] = useState(null);
  const [notes, setNotes] = useState([]);
  const [filteredNote, setFilteredNote] = useState([]);
  const [hasEntered, setHasEntered] = useState(false);
  const [inputText, setInputText] = useState("");
  const [noteIsLocked, setNoteIsLocked] = useState(false);
  const [notePassword, setNotePassword] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteIndex, setSelectedNoteIndex] = useState(null);
  const [noteIsPinned, setNoteIsPinned] = useState(false);
  const [noteMembers, setNoteMembers] = useState([]);
  const [openViewDoc, setOpenViewDoc] = useState(false);

  const openedNoteId = useSelector(state => state.popup.openedNote)

  useEffect(() => {
    if (!showNote) {
      setShowNote(selectedNoteTab);
    }
  }, []);

  useEffect(() => {
    if (openedNoteId && filteredNote?.length > 0) {
      handleShowNote(openedNoteId);
      dispatch(openNote(null))
    }
  }, [openedNoteId, filteredNote])

  const handleShowNote = (id) => {
    dispatch(handleSetSelectedNoteTab(id));

    setNewNoteToggler(false);

    setShowNote(id);
    const mSelectedNote = notes.find((note) => note.id === id);
    setSelectedNote(mSelectedNote);
    if (mSelectedNote) {
      setNewTitleValue(mSelectedNote.title);
      setNewMessageValue(mSelectedNote.message);
    } else {
      setNewTitleValue("");
      setNewMessageValue("");
    }
  };

  const [newNoteToggler, setNewNoteToggler] = useState(false);

  useEffect(() => {
    if (currentTitle !== "") {
      setCurrentTitle(currentTitle);
    }

    if (currentMessage !== "") {
      setCurrentMessage(currentMessage);
    }
  }, [viewMode]);

  const { data: noteData } = useNotes(currentProject.id);

  useEffect(() => {
    if (noteData?.length > 0) {
      setNotes(noteData);
      setFilteredNote(noteData);
    }
  }, [noteData]);

  useEffect(() => {
    setFilteredNote(notes);
  }, [notes]);

  useEffect(() => {
    if (firstTimeNoteAppLaunch) {
      setNewNoteToggler(true);
    }
  }, []);

  // useEffect(() => {
  //   if (searchNote && searchNote === "Notes with Checklist") {
  //     setFilteredNote(
  //       notes.filter((note) =>
  //         note.message.content.some((item) => item.type === "taskList")
  //       )
  //     );
  //   } else if (searchNote && searchNote === "Shared Notes") {
  //     setFilteredNote(notes.filter((note) => note.members?.length > 0));
  //   } else if (searchNote && searchNote === "Protected Notes") {
  //     setFilteredNote(notes.filter((note) => note.locked));
  //   } else if (searchNote) {
  //     setFilteredNote(
  //       notes.filter((note) => {
  //         const filterValue = searchNote.toLowerCase();

  //         if (note.title.toLowerCase().includes(filterValue)) {
  //           return true;
  //         } else if (note.message.content) {
  //           return note.message.content.some(
  //             (item) =>
  //               item.content[0].text &&
  //               item.content[0].text.toLowerCase().includes(filterValue)
  //           );
  //         } else {
  //           return false;
  //         }
  //       })
  //     );
  //   } else {
  //     setFilteredNote(notes);
  //   }
  // }, [searchNote, notes]);

  const handleLockNote = (password) => {
    setNotePassword(password);
    setNoteIsLocked(!noteIsLocked);
  };

  const handleUpdateNotePinned = async (id) => {
    const updatedNotes = [...notes];
    const index = notes.findIndex((note) => note.id === id);

    if (index !== -1) {
      const pinnedFlag =
        updatedNotes[index].pinned !== undefined
          ? !updatedNotes[index].pinned
          : true;

      updatedNotes[index] = {
        ...updatedNotes[index],
        pinned: pinnedFlag,
      };

      await updateNoteApi(id, { pinned: pinnedFlag });

      setNotes(updatedNotes);
    } else {
      setNoteIsPinned(!noteIsPinned);
    }
  };

  const newHandleAddNote = async (
    dayNow,
    monthNow,
    yearNow,
    timeNow,
    currenTitle,
    currentMessage,
    noteIsLocked,
    notePassword,
    notePinned,
    noteMembers
  ) => {
    if (currentMessage !== "" && currentTitle !== "") {
      const newNote = {
        id: new Date().getTime(),
        day: dayNow,
        month: monthNow,
        year: yearNow,
        time: timeNow,
        title: currenTitle || "",
        message: currentMessage || "",
        locked: noteIsLocked,
        password: notePassword,
        pinned: notePinned,
        members: noteMembers,
      };

      await createNote({
        projectId: currentProject.id,
        title: currenTitle || "",
        message: currentMessage || [],
        locked: noteIsLocked,
        password: notePassword,
        pinned: notePinned,
        members: noteMembers,
      });

      setNotes([...notes, newNote]);
      setFilteredNote([...notes, newNote]);
      setCurrentTitle("");
      setCurrentMessage("");
      setNewNoteToggler(false);
      setHasEntered(false);
      setInputText("");
      setNoteIsLocked(false);
      setNoteIsPinned(false);
      setNotePassword("");
      setNoteMembers([]);
    }
  };

  const handleAddBlankNote = async () => {
    let newDayNow = dayjs().date();
    let newMonthNow = dayjs().format("MMM");
    let newYearNow = dayjs().format("YYYY");
    let newTimeNow = dayjs().format("HH:mm");

    let id = new Date().getTime();

    const newNote = {
      id: id,
      day: newDayNow,
      month: newMonthNow,
      year: newYearNow,
      time: newTimeNow,
      title: "",
      message: "",
      locked: false,
      password: "",
      pinned: false,
      members: [],
    };

    await createNote({
      projectId: currentProject.id,
      title: "",
      message: [],
      locked: false,
      password: "",
      pinned: false,
      pinned: false,
    });

    setNotes([...notes, newNote]);
    setFilteredNote([...notes, newNote]);
    handleShowNote(id);
  };

  const handleDeleteNote = async (id) => {
    try {
      await deleteNote(id);

      const index = notes.findIndex((note) => note.id === id);

      const updatedNotes = [...notes];
      updatedNotes.splice(index, 1);
      setNotes(updatedNotes);
      setNewNoteToggler(false);
      setShowNote(null);
      toast.success("Deleted");
    } catch (error) {
      toast.error("Cannot delete note");
    }
  };

  const [newTitleValue, setNewTitleValue] = useState("");
  const [newMessageValue, setNewMessageValue] = useState("");
  const debouncedData = useDebounce(newMessageValue, 1000);

  const updateNoteCallback = useCallback(async () => {
    await newHandleUpdateNote();
  }, [debouncedData]);

  useEffect(() => {
    if (debouncedData && currentProject && showNote) {
      updateNoteCallback();
    }
  }, [debouncedData, updateNoteCallback]);

  const newHandleUpdateNote = async () => {
    let newDayNow = dayjs().date();
    let newMonthNow = dayjs().format("MMM");
    let newYearNow = dayjs().format("YYYY");
    let newTimeNow = dayjs().format("HH:mm");

    let updateNote = notes.find((n) => n.id === showNote);
    let updateNoteIndex = notes.findIndex((n) => n.id === showNote);

    updateNote = {
      ...updateNote,
      title: newTitleValue || updateNote.title,
      message: newMessageValue || updateNote.message,
      day: newDayNow,
      month: newMonthNow,
      year: newYearNow,
      time: newTimeNow,
    };

    await updateNoteApi(updateNote.id, {
      projectId: currentProject.id,
      title: newTitleValue,
      message: newMessageValue,
    });

    let cloneNotes = [...notes];
    cloneNotes[updateNoteIndex] = updateNote;

    setNotes(cloneNotes);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dayNow = dayjs().date();
  const monthNow = dayjs().format("MMM");
  const yearNow = dayjs().format("YYYY");
  const timeNow = dayjs().format("HH:mm");

  // getting title from first enter
  const handleMessageChange = (event) => {
    const newText = event.target.value;
    setInputText(newText);

    const lines = newText.split("\n");
    const firstLine = lines[0];
    const restLines = lines.slice(1).join("\n");

    if (!hasEntered && newText.includes("\n")) {
      setCurrentTitle(firstLine);
      setCurrentMessage(restLines);
      setHasEntered(true);
    } else if (hasEntered && currentTitle !== firstLine) {
      setCurrentTitle(firstLine);
      setCurrentMessage(restLines);
    } else if (hasEntered && currentTitle === firstLine) {
      setCurrentMessage(restLines);
    }
  };

  // start text editor
  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    TaskList,
    TaskItem.configure({
      nested: true,
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
    Document,
    Paragraph.configure({
      HTMLAttributes: {
        class: "paragraph",
      },
    }),
    Text,
    Gapcursor,
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    Image,
    Placeholder.configure({
      placeholder: "Write something …",
    }),
  ];

  const newContent = `
  <p><strong>
  </strong></p>
  <p>
  </p>
  `;

  const handleSubmitPassword = async (password) => {
    try {
      const index = notes.findIndex((note) => note.id === selectedNote.id);

      const response = await verifyPasswordNote(showNote, password);
      const { data } = response.data;
      const { isValid } = data;

      if (isValid) {
        const updatedNotes = [...notes];
        updatedNotes[index] = {
          ...updatedNotes[index],
          locked: false,
          password: "",
        };

        setNotes(updatedNotes);
        setSelectedNote({
          ...selectedNote,
          locked: false,
        });
      } else {
        alert("Wrong");
      }
    } catch (error) {
      console.log("ERRRR", error);
      alert("Wrong");
    }
  };

  const handleUpdatePassword = async (id, password) => {
    const index = notes.findIndex((note) => note.id === id);

    if (selectedNote != null) {
      const updatedNotes = [...notes];
      updatedNotes[index] = {
        ...updatedNotes[index],
        locked: true,
        password: password,
      };

      await updateNoteApi(id, {
        locked: true,
        password: password,
      });

      setNotes(updatedNotes);
    }

    if (selectedNote === null) {
      setNotePassword(password);
      setNoteIsLocked(!noteIsLocked);
    }
  };

  const handleUpdateNoteMembers = async (members) => {
    const index = notes.findIndex((note) => note.id === showNote);

    if (index !== -1) {
      const updatedNotes = [...notes];
      updatedNotes[index] = {
        ...updatedNotes[index],
        members: members,
      };

      await updateNoteApi(showNote, {
        members: members,
      });

      setNotes(updatedNotes);
    } else {
      // setNoteMembers(members);
    }
  };

  const MenuBar = () => {
    const { editor } = useCurrentEditor();

    if (!editor) {
      return null;
    }

    return (
      <NotesDetailsBar
        editor={editor}
        setSearchNote={setSearchNote}
        searchNote={searchNote}
        handleDeleteNote={handleDeleteNote}
        showNote={showNote}
        handleLockNote={handleLockNote}
        setNotePassword={setNotePassword}
        notePassword={notePassword}
        handleUpdatePassword={handleUpdatePassword}
        handleUpdateNotePinned={handleUpdateNotePinned}
        handleUpdateNoteMembers={handleUpdateNoteMembers}
        setNoteMembers={setNoteMembers}
        noteMembers={noteMembers}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
      />
    );
  };

  const [searchFocused, setSearchFocused] = useState(null);

  const [searchText, setSearchText] = useState(searchNote);

  useEffect(() => {
    if (noteData?.length === 0) {
      handleAddBlankNote();
    }
  }, [noteData]);

  console.log(filteredNote, showNote, selectedNote);

  return (
    <>
      <div className="cloud-page__header_notes-details">
        <div className="cloud-page__header_notes-details_search_wrapper">
          <div className="cloud-page__header_notes-details_search">
            {/* <form
              onSubmit={(e) => {
                e.preventDefault();
                setSearchNote(searchText);
              }}
            >
              <div className="cloud-page__header_notes-details_search_container">
                <img src={search} />
                <input
                  className="cloud-page__header_notes-details_search-input"
                  id="notes-detailsbar-search"
                  type="text"
                  autocomplete="off"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  placeholder={t("NOTES_PAGE.SEARCH_PLACEHOLDER")}
                  onFocus={() => setSearchFocused(true)}
                  onBlur={() => {
                    setTimeout(() => {
                      setSearchFocused(false);
                    }, 100);
                  }}
                />

                {searchFocused && (
                  <ul className="searchnote-menu-popup">
                    <li className="searchnote-menu-popup_item disabled">
                      {t("NOTES_PAGE.SUGGESTED_SEARCHES")}
                    </li>

                    <li
                      className="searchnote-menu-popup_item"
                      onClick={() => {
                        setSearchText("Shared Notes");
                        document
                          .getElementById("notes-detailsbar-search")
                          .focus();
                      }}
                    >
                      <img src={shareIcon} alt="shared notes" />
                      {t("NOTES_PAGE.SHARED_NOTES")}
                    </li>

                    <li
                      className="searchnote-menu-popup_item"
                      onClick={() => {
                        setSearchText("Protected Notes");
                        document
                          .getElementById("notes-detailsbar-search")
                          .focus();
                      }}
                    >
                      <img src={lockIcon} alt="protected notes" />
                      {t("NOTES_PAGE.PROTECTED_NOTES")}
                    </li>

                    <li
                      className="searchnote-menu-popup_item"
                      onClick={() => {
                        setSearchText("Notes with Checklist");
                        document
                          .getElementById("notes-detailsbar-search")
                          .focus();
                      }}
                    >
                      <img src={bulletIcon} alt="checklist notes" />
                      {t("NOTES_PAGE.NOTES_WITH_CHECKLIST")}
                    </li>

                    <li
                      className="searchnote-menu-popup_item"
                      onClick={() => {
                        setSearchText("Notes with Attachments");
                        document
                          .getElementById("notes-detailsbar-search")
                          .focus();
                      }}
                    >
                      <img src={attachIcon} alt="attached notes" />
                      {t("NOTES_PAGE.NOTES_WITH_ATTACHMENT")}
                    </li>
                  </ul>
                )}
              </div>
            </form> */}
          </div>

          <div className="cloud-page__header_notes-details_close">
            <ButtonGroup variant="outlined" aria-label="actions">
              {!appIsFullPage ? (
                <IconButton aria-label="expand" onClick={openFullPage}>
                  <img src={expandIcon} alt="expand" />
                </IconButton>
              ) : (
                <IconButton aria-label="compress" onClick={closeFullPage}>
                  <img src={compressIcon} alt="compress" />
                </IconButton>
              )}

              <IconButton
                aria-label="delete"
                onClick={() => {
                  dispatch(handleCloseAppsModal());
                  setFirstTimeNoteAppLaunch(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </ButtonGroup>
          </div>
        </div>
      </div>

      {viewMode === "list" && (
        <div className="listview-notes-page">
          <div className="grid-content">
            <div className="grid-content_left">
              <div className="listview-notes-page_sidebar">
                <div className="listview-notes-page_sidebar_create-event">
                  {newNoteToggler && currentTitle && currentMessage ? (
                    <Button
                      className="listview-notes-page_sidebar_create-event_btn"
                      onClick={() => {
                        const newDayNow = dayjs().date();
                        const newMonthNow = dayjs().format("MMM");
                        const newYearNow = dayjs().format("YYYY");
                        const newTimeNow = dayjs().format("HH:mm");
                        newHandleAddNote(
                          newDayNow,
                          newMonthNow,
                          newYearNow,
                          newTimeNow,
                          currentTitle,
                          currentMessage,
                          noteIsLocked,
                          notePassword,
                          noteIsPinned,
                          noteMembers
                        );
                      }}
                    >
                      {t("NOTES_PAGE.CREATE_NOTE_BUTON")}
                    </Button>
                  ) : (
                    <Button
                      className="listview-notes-page_sidebar_create-event_btn"
                      onClick={() => {
                        handleAddBlankNote();
                        setFirstTimeNoteAppLaunch(true);
                      }}
                    >
                      {t("NOTES_PAGE.CREATE_NOTE_BUTON")}
                    </Button>
                  )}
                </div>

                {filteredNote.some((item) => item.pinned) && (
                  <>
                    <p className="listview-notes-page_sidebar-title">
                      <img src={pinIcon} alt="pinned" />
                      {t("NOTES_PAGE.PINNED")}
                    </p>

                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={showNote}
                      aria-label="Vertical tabs example"
                      className="listview-notes-page_sidebar-section_notes-list"
                    >
                      {filteredNote
                        .filter((item) => item.pinned)
                        .map((item, index) => (
                          <Tab
                            className={`listview-notes-page_sidebar-section_notes-item ${
                              selectedNote?.id === item.id
                                ? "selected-note-item"
                                : ""
                            }`}
                            key={item.id}
                            // tabIndex={item.id}
                            tabIndex={
                              selectedNote?.id === item.id
                                ? selectedNote?.id
                                : -1
                            }
                            aria-selected={
                              selectedNote?.id === item.id ? true : false
                            }
                            {...a11yProps(item.id)}
                            component={"div"}
                            onClick={() => {
                              handleShowNote(item.id);
                              setNoteMembers(item.members);
                            }}
                            label={
                              <>
                                <div className="listview-notes-page_sidebar-section_notes-item_content">
                                  <div
                                    className="listview-notes-page_sidebar-section_notes-item_content-icon"
                                    style={{
                                      paddingTop: item.title ? "2px" : "0",
                                    }}
                                  >
                                    {selectedNote?.id === item.id ? (
                                      <img
                                        src={documentColorIcon}
                                        alt="active note"
                                      />
                                    ) : (
                                      <img
                                        src={documentGrayIcon}
                                        alt="not active note"
                                      />
                                    )}
                                  </div>

                                  <div className="listview-notes-page_sidebar-section_notes-item_content-text">
                                    {item.title && (
                                      <p
                                        className={`listview-notes-page_sidebar-section_notes-item_content-text_name ${
                                          selectedNote?.id === item.id &&
                                          "active"
                                        }`}
                                      >
                                        {item.title}
                                      </p>
                                    )}
                                    <p
                                      className={`listview-notes-page_sidebar-section_notes-item_content-text_message ${
                                        selectedNote?.id === item.id && "active"
                                      }`}
                                    >
                                      <span>{item.time}</span>
                                      <span>
                                        {item.message.content &&
                                        item.message.content[1] &&
                                        item.message.content[1].content
                                          ? item.message.content[1].content[0]
                                              .text
                                          : ""}
                                      </span>
                                    </p>
                                  </div>
                                </div>

                                <div className="listview-notes-page_sidebar-section_notes-item_actions">
                                  {item.locked && (
                                    <div className="listview-notes-page_sidebar-section_notes-item_actions-locked">
                                      <img src={smallLockedIcon} />
                                    </div>
                                  )}
                                </div>
                              </>
                            }
                          />
                        ))}
                    </Tabs>
                  </>
                )}

                <div className="listview-notes-page_sidebar-section">
                  {filteredNote.some((item) => item.pinned) && (
                    <p className="listview-notes-page_sidebar-title">
                      {t("NOTES_PAGE.TITLE")}
                    </p>
                  )}

                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={showNote}
                    aria-label="Vertical tabs example"
                    className="listview-notes-page_sidebar-section_notes-list"
                  >
                    {filteredNote
                      .filter((item) => !item.pinned)
                      .map((item, index) => (
                        <Tab
                          className={`listview-notes-page_sidebar-section_notes-item ${
                            selectedNote?.id === item.id
                              ? "selected-note-item"
                              : ""
                          }`}
                          key={item.id}
                          // tabIndex={item.id}
                          tabIndex={
                            selectedNote?.id === item.id ? selectedNote?.id : -1
                          }
                          aria-selected={
                            selectedNote?.id === item.id ? true : false
                          }
                          {...a11yProps(item.id)}
                          component={"div"}
                          onClick={() => {
                            handleShowNote(item.id);
                            setNoteMembers(item.members);
                          }}
                          label={
                            <>
                              <div className="listview-notes-page_sidebar-section_notes-item_content">
                                <div
                                  className="listview-notes-page_sidebar-section_notes-item_content-icon"
                                  style={{
                                    paddingTop: item.title ? "2px" : "0",
                                  }}
                                >
                                  {selectedNote?.id === item.id ? (
                                    <img
                                      src={documentColorIcon}
                                      alt="active note"
                                    />
                                  ) : (
                                    <img
                                      src={documentGrayIcon}
                                      alt="not active note"
                                    />
                                  )}
                                </div>

                                <div className="listview-notes-page_sidebar-section_notes-item_content-text">
                                  {item.title && (
                                    <p
                                      className={`listview-notes-page_sidebar-section_notes-item_content-text_name ${
                                        selectedNote?.id === item.id && "active"
                                      }`}
                                    >
                                      {item.title}
                                    </p>
                                  )}
                                  <p
                                    className={`listview-notes-page_sidebar-section_notes-item_content-text_message ${
                                      selectedNote?.id === item.id && "active"
                                    }`}
                                  >
                                    <span>{item.time}</span>
                                    <span>
                                      {item.message.content &&
                                      item.message.content[1] &&
                                      item.message.content[1].content
                                        ? item.message.content[1].content[0]
                                            .text
                                        : ""}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="listview-notes-page_sidebar-section_notes-item_actions">
                                {item.locked && (
                                  <div className="listview-notes-page_sidebar-section_notes-item_actions-locked">
                                    <img src={smallLockedIcon} />
                                  </div>
                                )}
                              </div>
                            </>
                          }
                        />
                      ))}
                  </Tabs>
                </div>
              </div>
            </div>

            <div className="grid-content_right">
              <div className="listview-notes-page_main">
                <div className="listview-notes-page_main_notes">
                  {showNote && (
                    <div className="listview-notes-page_main_notes-messages">
                      {filteredNote.map((item, index) => {
                        return (
                          <TabPanel
                            key={item.id}
                            value={showNote}
                            id={item.id}
                            className="listview-notes-page_main_notes-messages-tabpanel"
                          >
                            {item.locked && (
                              <NotesLockPage
                                selectedNote={selectedNote}
                                setSelectedNote={setSelectedNote}
                                handleSubmitPassword={handleSubmitPassword}
                              />
                            )}

                            {!item.locked && (
                              <div className="listview-notes-page_main_notes-messages__body">
                                <div className="listview-notes-page_main_notes-messages__body-date">
                                  <p>
                                    {item.day} {item.month} {item.year} at{" "}
                                    {item.time}
                                  </p>
                                </div>

                                <div className="listview-notes-page_main_notes-messages__body-content">
                                  <EditorProvider
                                    slotBefore={<MenuBar />}
                                    extensions={extensions}
                                    content={
                                      item.message ? item.message : newContent
                                    }
                                    onCreate={({ editor }) => {
                                      // editor.commands.focus('end')
                                    }}
                                    onBeforeCreate={({ editor }) => {
                                      // editor.commands.focus('end')
                                    }}
                                    autofocus={item.message && "end"}
                                    onUpdate={({ editor }) => {
                                      setNewTitleValue(
                                        editor.getJSON().content[0].content
                                          ? editor.getJSON().content[0]
                                              .content[0].text
                                          : ""
                                      );
                                      setNewMessageValue(editor.getJSON());

                                      // newHandleUpdateNote(
                                      //   index,
                                      //   newTitleValue || item.title,
                                      //   newMessageValue || item.message
                                      // );
                                    }}
                                  ></EditorProvider>

                                  <div className="listview-notes-page_main_notes-messages__body-content_save">
                                    <Button
                                      className="listview-notes-page_main_notes-messages__body-content_save-btn"
                                      onClick={() =>
                                        newHandleUpdateNote(
                                          index,
                                          newTitleValue || item.title,
                                          newMessageValue || item.message
                                        )
                                      }
                                    >
                                      {t("ACTIONS.SAVE")}
                                    </Button>

                                    <p className="listview-notes-page_main_notes-messages__body-content_save-date">
                                      {t("NOTES_PAGE.LAST_SAVE")} {item.month}{" "}
                                      {item.day}, {item.year} at {item.time}
                                    </p>
                                  </div>
                                </div>

                                <div
                                  className="listview-notes-page_main_notes-messages__body-members"
                                  style={{
                                    borderTop:
                                      noteMembers?.length > 0
                                        ? "1px solid #E8E7E4"
                                        : "unset",
                                  }}
                                >
                                  {noteMembers?.map((member) => (
                                    <div
                                      className="listview-notes-page_main_notes-messages__body-members_item"
                                      key={member.id}
                                    >
                                      <Avatar
                                        {...stringAvatar(
                                          renderFullName(member)
                                        )}
                                        style={{ fontSize: 14 }}
                                        src={member.image}
                                        alt={renderFullName(member)}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </TabPanel>
                        );
                      })}
                    </div>
                  )}

                  {newNoteToggler && (
                    <div className="listview-notes-page_main_notes-messages">
                      <div className="listview-notes-page_main_notes-messages__body">
                        <div className="listview-notes-page_main_notes-messages__body-date">
                          <p>
                            {dayNow} {monthNow} {yearNow} at {timeNow}
                          </p>
                        </div>

                        <div className="listview-notes-page_main_notes-messages__body-content">
                          <EditorProvider
                            slotBefore={<MenuBar />}
                            extensions={extensions}
                            content={currentMessage}
                            onCreate={({ editor }) => {
                              if (firstTimeNoteAppLaunch) {
                                // handleAddBlankNote();
                              }
                            }}
                            onDestroy={(editor) => {}}
                            onBeforeCreate={({ editor }) => {}}
                            onUpdate={({ editor }) => {
                              setCurrentTitle(
                                editor.getJSON()?.content[0]?.content[0]
                                  ? editor.getJSON().content[0].content[0].text
                                  : ""
                              );
                              setCurrentMessage(editor.getJSON());
                            }}
                          ></EditorProvider>

                          <div className="listview-notes-page_main_notes-messages__body-content_save">
                            <Button
                              className="listview-notes-page_main_notes-messages__body-content_save-btn"
                              onClick={() => {
                                let newDayNow = dayjs().date();
                                let newMonthNow = dayjs().format("MMM");
                                let newYearNow = dayjs().format("YYYY");
                                let newTimeNow = dayjs().format("HH:mm");
                                newHandleAddNote(
                                  newDayNow,
                                  newMonthNow,
                                  newYearNow,
                                  newTimeNow,
                                  currentTitle,
                                  currentMessage,
                                  noteIsLocked,
                                  notePassword,
                                  noteIsPinned,
                                  noteMembers
                                );
                              }}
                            >
                              {t("ACTIONS.SAVE")}
                            </Button>
                          </div>
                        </div>

                        <div
                          className="listview-notes-page_main_notes-messages__body-members"
                          style={{
                            borderTop:
                              noteMembers?.length > 0
                                ? "1px solid #E8E7E4"
                                : "unset",
                          }}
                        >
                          {noteMembers?.length > 0 &&
                            noteMembers.map((member) => (
                              <div
                                className="listview-notes-page_main_notes-messages__body-members_item"
                                key={member.id}
                              >
                                <Avatar
                                  {...stringAvatar(renderFullName(member))}
                                  style={{ fontSize: 12 }}
                                  src={member.image}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {viewMode === "grid" && (
        <div className="gridview-notes-page">
          <div className="gridview-notes-page_container">
            <Tabs
              variant="scrollable"
              value={showNote}
              aria-label="Vertical tabs example"
              className="gridview-notes-page_list"
            >
              {filteredNote.map((item, index) => (
                <Tab
                  className={`gridview-notes-page_item ${
                    selectedNote?.id === item.id ? "selected-note-item" : ""
                  }`}
                  key={item.id}
                  tabIndex={
                    selectedNote?.id === item.id ? selectedNote?.id : -1
                  }
                  aria-selected={selectedNote?.id === item.id ? true : false}
                  {...a11yProps(item.id)}
                  component={"div"}
                  onClick={() => {
                    handleShowNote(item.id);
                    setOpenViewDoc(true);
                  }}
                  label={
                    <>
                      <div className="gridview-notes-page_item_content">
                        <p className="gridview-notes-page_item_content_title">
                          {item.title}
                        </p>
                        <p className="gridview-notes-page_item_content_message">
                          {item.locked && (
                            <div className="gridview-notes-page_item_content_message-locked">
                              <img src={lockpageIcon} alt="locked" />
                            </div>
                          )}

                          {!item.locked && (
                            <EditorProvider
                              extensions={extensions}
                              content={item.message}
                              editable={false}
                            ></EditorProvider>
                          )}
                        </p>
                      </div>

                      <p className="gridview-notes-page_item_info">
                        <p className="gridview-notes-page_item_info-title">
                          {item.title}
                        </p>

                        <p className="gridview-notes-page_item_info-time">
                          {item.time}
                        </p>
                      </p>
                    </>
                  }
                />
              ))}
            </Tabs>

            <Modal open={openViewDoc}>
              <div className="backlog-modal_container">
                <div className="backlog-modal_header">
                  <div className="backlog-modal_header-title"></div>
                  <div className="backlog-modal_header-btn">
                    <IconButton onClick={() => setOpenViewDoc(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>

                <div className="backlog-modal_content note-view-doc">
                  {selectedNote?.locked && (
                    <NotesLockPage
                      selectedNote={selectedNote}
                      setSelectedNote={setSelectedNote}
                      handleSubmitPassword={handleSubmitPassword}
                    />
                  )}

                  {!selectedNote?.locked && (
                    <EditorProvider
                      extensions={extensions}
                      content={selectedNote?.message}
                      editable={false}
                    ></EditorProvider>
                  )}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default NotesPageContent;
