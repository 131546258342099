export const renderFullName  = (user) => {
  let fullName = "";

  if (user?.firstName) {
    fullName = user.firstName;
  }
  if (user?.lastName) {
    fullName = fullName + ` ${user.lastName}`;
  }

  return fullName;
}
