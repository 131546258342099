import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import MeetingPage from "../../../meeting-page/MeetingPage";
import Modal from "@mui/material/Modal";
import { handleOpenMeetingPageModal } from "../../../../../../redux/app/appsModalSlice";
import { handleCloseMeetingPageModal } from "../../../../../../redux/app/appsModalSlice";
import arrowLeftIcon from "../../../../../../assets/images/vide-conferencing/chevron-left.svg";
import meetIcon from "../../../../../../assets/images/vide-conferencing/meet.png";
import attendeeIcon from "../../../../../../assets/images/vide-conferencing/attendee.svg";
import nameIcon from "../../../../../../assets/images/vide-conferencing/name.svg";
import micOffIcon from "../../../../../../assets/images/vide-conferencing/mic-off.svg";
import micOnIcon from "../../../../../../assets/images/vide-conferencing/mic-on.png";
import videoOffIcon from "../../../../../../assets/images/vide-conferencing/video-off.svg";
import videoOnIcon from "../../../../../../assets/images/vide-conferencing/video-on.png";
import recordIcon from "../../../../../../assets/images/vide-conferencing/record.svg";
import passwordIcon from "../../../../../../assets/images/vide-conferencing/password.svg";
import "./meet-now.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { createMeetingRoom as createMeetingRoomApi } from "../../../../../../api/Api";
import { useCurrentProject } from "../../../../../../hooks/projects";
import { toast } from "react-toastify";
import { Avatar, Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import {
  Add,
  ControlPointOutlined,
  LockOpenOutlined,
  PeopleOutlineOutlined,
  PlusOneOutlined,
} from "@material-ui/icons";
import { AddLinkOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import AttendeeListSelect from "../../../AttendeeListSelect";
import { stringAvatar } from "../../../../../../utils/AvatarUtil";
import { renderFullName } from "../../../../../../utils/UserUtil";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { useCurrentUser } from "../../../../../../hooks/auth.hook";
import { useUpcomingMeetings } from "../../../../../../hooks/meetingRoom.hook";

const MeetNow = ({ handleShowMain }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const openMeetingPageModal = useSelector(
    (state) => state.appsModal.openMeetingPageModal
  );
  const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  const [mic, setMic] = useState(false);
  const [video, setVideo] = useState(false);
  const [record, setRecord] = useState(true);
  const [password, setPassword] = useState(null);
  const [enabledPassword, setEnabledPassword] = useState(false);
  const [conferenceName, setConferenceName] = useState("");
  const [enabledExternal, setEnabledExternal] = useState(false);
  const [enabledLobby, setEnabledLobby] = useState(false);
  const [enabledEncrypt, setEnabledEncrypt] = useState(false);
  const { data: currentProject } = useCurrentProject();
  const { data: currentUser } = useCurrentUser();
  const [roomUid, setRoomUid] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [anchorAttendeeListEl, setAnchorAttendeeListEl] = React.useState(null);
  const [, setSearchParams] = useSearchParams();

  const openAttendeeList = Boolean(anchorAttendeeListEl);

  const handleClick = (event) => {
    setAnchorAttendeeListEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorAttendeeListEl(null);
  };

  const handleMic = () => {
    setMic(!mic);
  };

  const handleVideo = () => {
    setVideo(!video);
  };

  const handleRecord = () => {
    setRecord(!record);
  };

  const handleToogleEnabledPassword = () => {
    setEnabledPassword(!enabledPassword);
  };

  const handleToogleEnableExternal = () => {
    setEnabledExternal(!enabledExternal);
  };

  const handleToggleEnableLobby = () => {
    setEnabledLobby(!enabledLobby);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangeMeetingRoomName = (event) => {
    setConferenceName(event.target.value);
  };

  const { refetch: refetchUpcomingMeetings } =
    useUpcomingMeetings({
      projectId: currentProject?.id,
      from: new Date(dayjs().startOf("day").format()),
    });

  useEffect(() => {
    const troomUid = `${currentProject.id}-${enabledPassword ? "RP" : "NP"}-${(
      Math.random() + 1
    )
      .toString(36)
      .substring(7)}`;
    setRoomUid(troomUid);
  }, [currentProject, conferenceName, enabledPassword]);

  const handleStart = async () => {
    const attendeeIds = attendees.map((a) => a.id);
    attendeeIds.push(currentUser.id);

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let confName = conferenceName;

    if (!confName) {
      confName = `Meeting-${Math.floor(Math.random() * 999999)}`;
      setConferenceName(confName);
    }

    await createMeetingRoomApi({
      roomName: confName,
      password: password,
      roomUid: roomUid,
      projectId: currentProject.id,
      attendees: attendeeIds,
      startAt: dayjs().startOf('day').format("YYYY-MM-DDTHH:mm:ssZ"),
      endAt: dayjs().endOf('day').format("YYYY-MM-DDTHH:mm:ssZ"),
      timezone: timezone,
      frecency: "ONCE",
      isEnabledLobby: enabledLobby,
      isEnabledE2EE: enabledEncrypt,
    });

    setSearchParams({ meeting: roomUid });

    dispatch(handleOpenMeetingPageModal());
    await refetchUpcomingMeetings();
  };

  const externalUrl = useMemo(() => {
    if (enabledExternal) {
      return `${process.env.REACT_APP_EXTERNAL_MEET_URL}?meeting=${roomUid}`;
    }
    return "";
  }, [enabledExternal, roomUid]);

  const handleCopyExternalUrl = () => {
    if (externalUrl) {
      navigator.clipboard.writeText(externalUrl);
      toast.success(t("VIDEO_CONFERENCING_PAGE.COPIED_EXTERNAL_URL"));
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 31,
    height: 18,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      height: "100%",
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        marginTop: 0,
        marginBottom: 0,
        marginRight: 2,
        marginLeft: 0,
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#767680E5",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 13,
      height: 13,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#c4bfd2" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <div className="meetnow">
        <div className="meetnow-actions">
          <Button
            variant="outlined"
            startIcon={<img src={arrowLeftIcon} />}
            onClick={() => handleShowMain()}
          >
            {t("ACTIONS.BACK")}
          </Button>
        </div>

        <div className="meetnow-content">
          <div className="meetnow-content_icon">
            <img src={meetIcon} alt="Meet now" />

            <p>{t("VIDEO_CONFERENCING_PAGE.MEET_NOW")}</p>
          </div>

          <form className="meetnow-content_form">
            <div className="meetnow-content_form-item">
              <p className="meetnow-content_form-item-title">
                <img src={nameIcon} alt="conference name" />
                <span>{t("VIDEO_CONFERENCING_PAGE.CONFERENCE_NAME")}</span>
              </p>
              <TextField
                placeholder="Name your conference"
                className="meetnow-content_form-item-input"
                onChange={(e) => handleChangeMeetingRoomName(e)}
              />
            </div>

            <div className="meetnow-content_form-item">
              <p className="meetnow-content_form-item-title">
                <img src={attendeeIcon} alt="conference name" />
                <span>{t("VIDEO_CONFERENCING_PAGE.ATTENDEE")}</span>
              </p>

              <Box sx={{ display: "flex" }}>
                {attendees.map((a) => {
                  const fullName = renderFullName(a);

                  return (
                    <Avatar
                      {...stringAvatar(fullName)}
                      sx={{ width: 30, height: 30, fontSize: 14, mr: 1 }}
                    />
                  );
                })}

                <IconButton
                  sx={{ backgroundColor: "white", height: 28, width: 28 }}
                  aria-label="add-attendee"
                  aria-describedby="attendee-list-popover"
                  onClick={handleClick}
                >
                  <Add />
                </IconButton>
              </Box>

              <Popover
                id="attendee-list-popover"
                open={openAttendeeList}
                anchorEl={anchorAttendeeListEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <AttendeeListSelect
                  attendees={attendees}
                  setAttendees={setAttendees}
                />
              </Popover>
            </div>

            {/* <div
              style={{ marginTop: "15px" }}
              className="meetnow-content_form-item-switch"
            >
              <div
                className="meetnow-content_form-item-switch-label"
                onClick={handleMic}
              >
                <img src={mic ? micOnIcon : micOffIcon} alt="microphone" />
                <span>
                  {t("VIDEO_CONFERENCING_PAGE.AUTO_ENABLE_MICROPHONE")}
                </span>
              </div>
              <div className="meetnow-content_form-item-switch-btn">
                <IOSSwitch checked={mic} onChange={handleMic} />
              </div>
            </div> */}

            {/* <div className="meetnow-content_form-item-switch">
              <div
                className="meetnow-content_form-item-switch-label"
                onClick={handleVideo}
              >
                <img src={video ? videoOnIcon : videoOffIcon} alt="video" />
                <span>{t("VIDEO_CONFERENCING_PAGE.AUTO_ENABLE_CAMERA")}</span>
              </div>
              <div className="meetnow-content_form-item-switch-btn">
                <IOSSwitch
                  checked={video}
                  onChange={handleVideo}
                  // inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div> */}

            {/* <div className="meetnow-content_form-item-switch">
              <div
                className="meetnow-content_form-item-switch-label"
                onClick={handleRecord}
              >
                <img src={recordIcon} alt="record" />
                <span>
                  {t("VIDEO_CONFERENCING_PAGE.AUTO_ENABLE_RECORDING")}
                </span>
              </div>
              <div className="meetnow-content_form-item-switch-btn">
                <IOSSwitch
                  checked={record}
                  onChange={handleRecord}
                  // inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div> */}

            <div className="meetnow-content_form-item-switch">
              <div
                className="meetnow-content_form-item-switch-label"
                onClick={handleToogleEnabledPassword}
              >
                <LockOpenOutlined />
                {/* <img src={passwordIcon} alt="password" /> */}
                <span>{t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION")}</span>
              </div>
              <div className="meetnow-content_form-item-switch-btn">
                <IOSSwitch
                  checked={enabledPassword}
                  onChange={handleToogleEnabledPassword}
                  // inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>

            {enabledPassword ? (
              <div className="meetnow-content_form-item">
                <p className="meetnow-content_form-item-title-info">
                  {/* <img src={attendeeIcon} alt="conference name" /> */}
                  <span>
                    {t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION_DESC")}
                  </span>
                </p>
                {/* <TextField placeholder="Enter your password..." className="meetnow-content_form-item-input" type="password" /> */}
                <FormControl className="meetnow-content_form-item-input">
                  <OutlinedInput
                    placeholder="Enter your password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(event) => setPassword(event.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            ) : (
              ""
            )}

            <div className="meetnow-content_form-item-switch">
              <div
                className="meetnow-content_form-item-switch-label"
                onClick={handleToggleEnableLobby}
              >
                <PeopleOutlineOutlined />
                <span>{t("VIDEO_CONFERENCING_PAGE.ENABLE_LOBBY")}</span>
              </div>
              <div className="meetnow-content_form-item-switch-btn">
                <IOSSwitch
                  checked={enabledLobby}
                  onChange={handleToggleEnableLobby}
                />
              </div>
            </div>

            {enabledLobby && (
              <div className="meetnow-content_form-item">
                <p className="meetnow-content_form-item-title-info">
                  <span>
                    {t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION_DESC")}
                  </span>
                </p>
              </div>
            )}

            <div className="meetnow-content_form-item-switch">
              <div
                className="meetnow-content_form-item-switch-label"
                onClick={handleToogleEnableExternal}
              >
                <AddLinkOutlined />
                {/* <img src={passwordIcon} alt="password" /> */}
                <span>
                  {t("VIDEO_CONFERENCING_PAGE.LINK_FOR_EXTERNAL_USERS")}
                </span>
              </div>
              <div className="meetnow-content_form-item-switch-btn">
                <IOSSwitch
                  checked={enabledExternal}
                  onChange={handleToogleEnableExternal}
                  // inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>

            <div className="meetnow-content_form-item-switch">
              <div
                className="meetnow-content_form-item-switch-label"
                onClick={() => setEnabledEncrypt(!enabledEncrypt)}
              >
                <GppGoodOutlinedIcon />
                <span>{t("VIDEO_CONFERENCING.ENABLE_ENCRYPT")}</span>
              </div>
              <div className="meetnow-content_form-item-switch-btn">
                <IOSSwitch
                  checked={enabledEncrypt}
                  onChange={() => setEnabledEncrypt(!enabledEncrypt)}
                />
              </div>
            </div>

            {enabledEncrypt && (
              <div className="meetnow-content_form-item">
                <p className="meetnow-content_form-item-title-info">
                  <span>
                    {t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION_DESC")}
                  </span>
                </p>
              </div>
            )}

            {enabledExternal && (
              <div className="meetnow-content_form-item">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    {t("VIDEO_CONFERENCING_PAGE.LINK_TO_THE_MEETING")}:{" "}
                    <a href={externalUrl}>{externalUrl}</a>
                  </p>

                  <Tooltip
                    title={t("VIDEO_CONFERENCING_PAGE.COPIED_EXTERNAL_URL")}
                  >
                    <Button
                      size="small"
                      onClick={handleCopyExternalUrl}
                      sx={{
                        textTransform: "Capitalize",
                      }}
                      startIcon={<ContentCopyIcon sx={{ fontSize: 14 }} />}
                    >
                      {t("ACTIONS.COPY")}
                    </Button>
                  </Tooltip>
                </Box>
              </div>
            )}

            <div className="meetnow-content_form-item-submit">
              <Button
                className="meetnow-content_form-item-submit-btn"
                onClick={handleStart}
              >
                {t("ACTIONS.START")}
              </Button>
            </div>
          </form>
        </div>
      </div>


    </>
  );
};

export default MeetNow;
