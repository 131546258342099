import { FormControl, MenuItem, Select } from "@mui/material";
import {
  ISO_SUPPORTED_LANGUAGES,
  LOCAL_STORAGE_LOCALE,
  SUPPORTED_LANGUAGES,
} from "../../../types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { styled } from "@mui/material/styles";

const HUMANIZE_LANGS = {
  en: "English",
  fr: "French",
  de: "German",
};

const StyledFormControl = styled((props) => <FormControl {...props} />)(
  () => ({
    "& .MuiInput-root": {
      "&::before": {
        borderBottom: "none"
      }
    },
  })
);

const StyledSelect = styled((props) => <Select {...props} />)(
  () => ({
    "& .MuiSvgIcon-root": {
      color: "#4382C4"
    },
  })
);

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const [currentLang, setCurrentLenght] = useState(i18n.language);

  const onChangeLang = (language) => {
    const userLang = language;
    const isSupported =
      !!ISO_SUPPORTED_LANGUAGES.find((sl) => sl === userLang) ||
      !!SUPPORTED_LANGUAGES.find((sl) => sl === userLang);
    if (userLang && isSupported) {
      i18n.changeLanguage(userLang, () => {
        localStorage.setItem(LOCAL_STORAGE_LOCALE, userLang);
        setCurrentLenght(userLang);
      });
    }
  };

  return (
    <>
      <StyledFormControl
        variant="standard"
        sx={{
          mr: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1
        }}
      >
        <LanguageIcon sx={{ color: "#4382C4" }} />
        <StyledSelect
          value={currentLang}
          sx={{ color: "#4382C4" }}
        >
          {SUPPORTED_LANGUAGES.map((sl) => (
            <MenuItem value={sl} onClick={() => onChangeLang(sl)}>
              {HUMANIZE_LANGS[sl]}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </>
  );
};

export default LanguageSwitcher;
