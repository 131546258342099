import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import storeTasks from "../../../tasks/utils/store";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { sampleTasks } from "../../../../../mocks/mocks";
import plusIcon from "../../../../../assets/images/my-services/plus.svg";
import expandIcon from "../../../../../assets/images/my-services/expand.svg";
import calendarIcon from "../../../../../assets/images/my-services/tasks/calendar.svg";
import commentsIcon from "../../../../../assets/images/my-services/tasks/message.svg";
import moreIcon from "../../../../../assets/images/my-services/tasks/settings.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  handleOpenTasksWidgetModal,
  handleCloseTasksWidgetModal,
} from "../../../../../redux/app/appsModalSlice";

import TasksPage from "../../../tasks/TasksPage";
import { useCurrentProject } from "../../../../../hooks/projects";
import { useCurrentTask } from "../../../../../hooks/tasks.hook";
import { useProjectUsers } from "../../../../../hooks/users.hook";
import { stringAvatar } from "../../../../../utils/AvatarUtil";
import { renderFullName } from "../../../../../utils/UserUtil";
import { useSearchParams } from "react-router-dom";
import emptyListIcon from "../../../../../assets/images/my-services/videoconference/emptylist.svg";
import { openTask } from "../../../../../redux/app/popupSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const TasksBlock = () => {
  const { data: currentProject } = useCurrentProject();
  const { data: currentTask, refetch: refetchTask } = useCurrentTask(
    currentProject?.id
  );
  const { refetch: refetchUsers } = useProjectUsers(currentProject?.id);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentProject) {
      refetchTask();
      refetchUsers();
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentProject && currentTask) {
      setData(currentTask.task);
    }
  }, [currentTask]);

  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openActions = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  let [, setSearchParams] = useSearchParams();
  const openTasksWidgetModal = useSelector(
    (state) => state.appsModal.openTasksWidgetModal
  );
  const openNotesWidgetModal = useSelector(
    (state) => state.appsModal.openNotesWidgetModal
  );
  const openCalendarWidgetModal = useSelector(
    (state) => state.appsModal.openCalendarWidgetModal
  );
  const openWorkDriveWidgetModal = useSelector(
    (state) => state.appsModal.openWorkDriveWidgetModal
  );
  const appsModal = useSelector((state) => state.appsModal.openAppsModal);

  const handleOpenTasksModal = () => {
    dispatch(handleOpenTasksWidgetModal());
  };
  const handleCloseTasksModal = () => {
    dispatch(handleCloseTasksWidgetModal());
  };

  const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  // setting modals
  const [anchorElSetting, setAnchorElSetting] = useState(null);
  const open = Boolean(anchorElSetting);
  const handleOpenSetting = (event) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };

  const handleOpenCreateNewTaskPopup = () => {
    const tasksButton = document.querySelector('[aria-label="Tasks"]');
    tasksButton.click();

    // const createEventButton = document.querySelector('[aria-label="calendar create event"]')
    // createEventButton.click();

    const createEventButtonInterval = setInterval(() => {
      const createEventButton = document.querySelector(
        '[tabindex="0"] [aria-label="add new card"]'
      );

      if (createEventButton) {
        createEventButton.click();

        clearInterval(createEventButtonInterval);
      }
    }, 500);
  };

  const getSelectedTags = (item) => {
    return item.tags?.filter((t) => t.isSelected);
  };

  const handleOpenCard = (list, item) => {
    setTimeout(() => {
      const tasksButton = document.querySelector('[aria-label="Tasks"]');
      tasksButton.click();
    }, 500);

    setTimeout(() => {
      dispatch(openTask({
        listId: list.id,
        itemId: item.id,
      }));
    }, 750)
  };

  return (
    <>
      <div
        className={`my-services__tasks ${
          openNotesWidgetModal ||
          appsModal ||
          openCalendarWidgetModal ||
          openTasksWidgetModal ||
          openWorkDriveWidgetModal
            ? "back-transparent"
            : ""
        }`}
      >
        <div className="my-services__tasks_header">
          <div className="my-services__tasks_header-view">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="my-services__tasks_header-view_tab"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {data?.listIds.map((listId, index) => {
                const list = data.lists[listId];

                return <Tab key={listId} label={list.title} index={index} />;
              })}
            </Tabs>
          </div>

          <div className="react-grid-dragHandleArea"></div>

          <div className="my-services__tasks_header-more">
            <IconButton aria-label="expand" onClick={handleOpenTasksModal}>
              <img src={expandIcon} />
            </IconButton>
          </div>
        </div>

        <div className="my-services__tasks_content">
          {data?.listIds.map((listId, index) => {
            const list = data.lists[listId];
            return (
              <TabPanel
                key={listId}
                value={value}
                index={index}
                className="my-services__tasks_content-list"
              >
                {list.cards.length === 0 && (
                  <div className="tasks-widget__emptyevent">
                    <div className="tasks-widget__emptyevent-img">
                      <img src={emptyListIcon} alt="empty list" />
                    </div>

                    <p className="tasks-widget__emptyevent-text">
                      {t("SERVICES.TASKS.EMPTY_TASKS")}
                    </p>
                  </div>
                )}
                {list.cards.map((item, index) => (
                  <div
                    onClick={() => handleOpenCard(list, item)}
                    key={item.id}
                    className="my-services__tasks_content-item"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="my-services__tasks_content-item-info">
                      <p className="my-services__tasks_content-item-info-title">
                        {item.title}
                      </p>
                    </div>

                    <div className="my-services__tasks_content-item-details">
                      <div className="my-services__tasks_content-item-details-members">
                        <AvatarGroup max={3}>
                          {item.members.map((member) => {
                            if (member.icon) {
                              <Avatar
                                src={member.icon}
                                className="my-services__tasks_content-item-details-members-member"
                              />;
                            }

                            return (
                              <Avatar
                                {...stringAvatar(renderFullName(member))}
                                src={member.icon}
                                className="my-services__tasks_content-item-details-members-member"
                              />
                            );
                          })}
                        </AvatarGroup>
                      </div>

                      {getSelectedTags(item).length > 0 ? (
                        <div className="my-services__tasks_content-item-details-tags">
                          {item.tags.map((tag, index) => {
                            if (tag.isSelected) {
                              if (getSelectedTags(item).length <= 2) {
                                return (
                                  <span
                                    key={`${listId}-${tag.id}`}
                                    style={{
                                      backgroundColor: item.color,
                                      color: "white",
                                    }}
                                    className="my-services__tasks_content-item-details-tags-tag"
                                  >
                                    {tag.name}
                                  </span>
                                );
                              } else {
                                if (index < 1) {
                                  return (
                                    <span
                                      key={`${listId}-${tag.id}`}
                                      style={{
                                        backgroundColor: item.color,
                                        color: "white",
                                      }}
                                      className="my-services__tasks_content-item-details-tags-tag"
                                    >
                                      {tag.name}
                                    </span>
                                  );
                                }

                                if (
                                  index ===
                                  getSelectedTags(item).length - 1
                                ) {
                                  return (
                                    <span
                                      key={`${listId}-${tag.id}`}
                                      style={{
                                        backgroundColor: item.color,
                                        color: "white",
                                      }}
                                      className="my-services__tasks_content-item-details-tags-tag"
                                    >
                                      +{getSelectedTags(item).length - 1}
                                    </span>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="my-services__tasks_content-item-details-comments">
                        <IconButton aria-label="comments">
                          <img src={commentsIcon} />
                        </IconButton>
                        {item.comments ? item.comments.length : "0"}
                      </div>
                    </div>
                  </div>
                ))}
              </TabPanel>
            );
          })}
        </div>
      </div>

      <Modal
        open={openTasksWidgetModal}
        onClose={handleCloseTasksModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="apps-modal"
        disableEnforceFocus
      >
        <div
          className={`apps-modal-container ${
            firstPopupTab ? "firstPopupShow" : ""
          } ${secondPopupTab ? "secondPopupShow" : ""}`}
        >
          <TasksPage handleCloseTasksModal={handleCloseTasksModal} />
        </div>
      </Modal>
    </>
  );
};

export default TasksBlock;
