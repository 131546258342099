import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import NotesPage from "../../../notes/NotesPage";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  handleOpenNotesWidgetModal,
  handleCloseNotesWidgetModal,
} from "../../../../../redux/app/appsModalSlice";
import plusIcon from "../../../../../assets/images/my-services/plus.svg";
import expandIcon from "../../../../../assets/images/my-services/expand.svg";
import listActiveIcon from "../../../../../assets/images/my-services/notes/list-active.svg";
import listNotIcon from "../../../../../assets/images/my-services/notes/list-not.svg";
import gridActiveIcon from "../../../../../assets/images/my-services/notes/grid-active.svg";
import gridNotIcon from "../../../../../assets/images/my-services/notes/grid-not.svg";
import { useNotes } from "../../../../../hooks/note.hook";
import { useCurrentProject } from "../../../../../hooks/projects";
import emptyListIcon from "../../../../../assets/images/my-services/videoconference/emptylist.svg";
import { openNote } from "../../../../../redux/app/popupSlice";


const NotesBlock = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewMode, setViewMode] = useState("list");

  const { data: currentProject } = useCurrentProject();
  const { data: noteData } = useNotes(currentProject?.id);

  const handleViewMode = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const openNotesWidgetModal = useSelector(
    (state) => state.appsModal.openNotesWidgetModal
  );
  const openCalendarWidgetModal = useSelector(
    (state) => state.appsModal.openCalendarWidgetModal
  );
  const openTasksWidgetModal = useSelector(
    (state) => state.appsModal.openTasksWidgetModal
  );
  const openWorkDriveWidgetModal = useSelector(
    (state) => state.appsModal.openWorkDriveWidgetModal
  );
  const appsModal = useSelector((state) => state.appsModal.openAppsModal);

  const handleOpenNotesModal = () => {
    dispatch(handleOpenNotesWidgetModal());
  };
  const handleCloseNotesModal = () => {
    dispatch(handleCloseNotesWidgetModal());
  };

  const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  function getMonthNumber(monthStr) {
    const monthNum = new Date(monthStr + "-1-01").getMonth() + 1;

    if (monthNum < 10) {
      return `0${monthNum}`;
    } else {
      return monthNum;
    }
  }

  const handleOpenNotesPopup = () => {
    const notesButton = document.querySelector('[aria-label="Notes"]');
    notesButton.click();
  };

  const getFirstLineContent = (message) => {
    if (message?.content?.length > 1 && message?.content[1]?.content?.length > 0) {
      if (message?.content[1]?.content[0]?.type == "text") {
        return message?.content[1].content[0].text;
      }
      return "-";
    }
    return "-";
  };

  const handleOpenSelectedNote = (noteId) => {
    dispatch(openNote(noteId));
    handleOpenNotesPopup();
  }

  return (
    <>
      <div
        className={`my-services__notes ${
          openNotesWidgetModal ||
          appsModal ||
          openCalendarWidgetModal ||
          openTasksWidgetModal ||
          openWorkDriveWidgetModal
            ? "back-transparent"
            : ""
        }`}
      >
        <div className="my-services__notes_header">
          <div className="my-services__notes_header-view">
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewMode}
              aria-label="text alignment"
            >
              <ToggleButton value="list" aria-label="list mode">
                <img src={viewMode === "list" ? listActiveIcon : listNotIcon} />
              </ToggleButton>
              <ToggleButton value="grid" aria-label="grid mode">
                <img src={viewMode === "grid" ? gridActiveIcon : gridNotIcon} />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="react-grid-dragHandleArea"></div>

          <div className="my-services__notes_header-more">
            <IconButton aria-label="add" onClick={handleOpenNotesPopup}>
              <img src={plusIcon} />
            </IconButton>

            <IconButton aria-label="expand" onClick={handleOpenNotesPopup}>
              <img src={expandIcon} />
            </IconButton>
          </div>
        </div>

        <div className="my-services__notes_content">
          <ul
            className={`my-services__notes_content_list ${
              viewMode === "grid" ? "grid" : ""
            }`}
          >
            {noteData?.length === 0 && (
              <div className="my-services__video-conference_content-emptylist">
                <div className="my-services__video-conference_content-emptylist-img">
                  <img src={emptyListIcon} alt="empty list" />
                </div>

                <p className="my-services__video-conference_content-emptylist-text">
                  {t("SERVICES.NOTES.NO_NOTES")}
                </p>
              </div>
            )}
            {noteData?.map((note) => (
              <li
                className="my-services__notes_content_list-item"
                key={note.id}
                onClick={() => handleOpenSelectedNote(note.id)}
              >
                <div className="my-services__notes_content_list-item-title">
                  {note.title}
                </div>

                <div className="my-services__notes_content_list-item-details">
                  {/* <p className="my-services__notes_content_list-item-details-time">{note.day}/{dayjs(`${note.day}${note.month}${note.year}`).format('MM')}/{note.year}</p> */}
                  <p className="my-services__notes_content_list-item-details-time">
                    {note.day}/{getMonthNumber(note.month)}/{note.year}
                  </p>
                  <p className="my-services__notes_content_list-item-details-desctiprion">
                    {getFirstLineContent(note.message)}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NotesBlock;
