import React from "react";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { handleCloseMeetingPageModal } from "../../../../redux/app/appsModalSlice";
import CloseIcon from "@mui/icons-material/Close";

const MeetingDetailsBar = ({ isGuest = false }) => {
  const dispatch = useDispatch();

  return (
    <div className="meeting-page_details">
      <div className="meeting-page_details-close">
        <div className="meeting-page_details-close-btn">
          {!isGuest && (
            <IconButton
              aria-label="delete"
              onClick={() => dispatch(handleCloseMeetingPageModal())}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default MeetingDetailsBar;
