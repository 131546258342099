import React, { useEffect, useMemo, useState, createRef } from "react";
import calendarIcon from "../../../assets/images/calendarIcon.png";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import listPlugin from "@fullcalendar/list";
import CloudPage from "../../pages/cloud-page/CloudPage";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./calendar-site.scss";
import { Calendar_page_current_events } from "../../../mocks/mocks";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import CreateEventsPopup from "./popups/CreateEventPopup";
import settingIcon from "../../../assets/images/calendar/settings.svg";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import prevIcon from "../../../assets/images/calendar/prev-icon.svg";
import nextIcon from "../../../assets/images/calendar/next-icon.svg";
import expandIcon from "../../../assets/images/header/expand.png";
import compressIcon from "../../../assets/images/header/compress.png";

import { CirclePicker } from "react-color";
import Modal from "@mui/material/Modal";
import SettingPopup from "./popups/SettingPopup";
import {
  getEvents,
  createEvent,
  deleteEvent,
  getCalendars,
  updateEvent,
  createCalendar as createCalendarApi,
  updateCalendar as updateCalendarApi,
} from "../../../api/Api";
import dayjs from "dayjs";
import { CalendarColors } from "../../../constants/colors";
import { useCurrentProject } from "../../../hooks/projects";
import AgendaCustomView from "./agenda/AgendaCustomView";
import { useSearchParams } from "react-router-dom";
import { useEvents } from "../../../hooks/events.hook";
import { SECONDARY_DATE_FORMAT } from "../../../constants/datetime";
import { useDispatch } from "react-redux";
import {
  handleCloseAppModalIsFullPage,
  handleCloseAppsModal,
  handleCloseMeetingPageModal,
  handleOpenAppModalIsFullPage,
  handleOpenMeetingPageModal,
} from "../../../redux/app/appsModalSlice";
import { useSelector } from "react-redux";
import MeetingPage from "../video-conferencing/meeting-page/MeetingPage";
import { getLangISOFormat } from "../../../types";

function CalendarPage() {
  const fullCalendarComponentRef = createRef();
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [tempEvent, setTempEvent] = useState();
  let [searchParams, setSearchParams] = useSearchParams();
  const [initView, setInitView] = useState(
    searchParams.get("view") || "dayGridMonth"
  );
  const [editEventMode, setEditEventMode] = useState(false);
  const [selectedEventID, setSelectedEventID] = useState("");
  const [selectedEvent, setSelectedEvent] = useState();
  const dispatch = useDispatch();

  const openMeetingPageModal = useSelector(
    (state) => state.appsModal.openMeetingPageModal
  );
  const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  const fullcalendarEvents = useMemo(() => {
    if (!editEventMode) {
      return events.map((event) => {
        if (event.recurring) {
          let daysOfWeek;

          if (event.recurring === "WEEKLY") {
            const day = dayjs(event.startDate).get("day");
            daysOfWeek = [day];
          }

          return {
            ...event,
            title: event.text || "(No title)",
            start: event.startDate,
            end: event.endDate,
            startRecur: event.startDate,
            startTime: dayjs(event.startDate).format("HH:mm"),
            endTime: event.endDate
              ? dayjs(event.endDate).format("HH:mm")
              : null,
            daysOfWeek: daysOfWeek,
            handleEventClick: handleEventClick,
          };
        }

        return {
          ...event,
          title: event.text || "(No title)",
          start: event.startDate,
          end: event.endDate,
          handleEventClick: handleEventClick,
        };
      });
    } else {
      const mEvents = events.filter((e) => e.id !== selectedEventID);
      mEvents.push({
        ...selectedEvent,
        color: "#3788d8",
      });

      return mEvents.map((event) => {
        if (event.recurring) {
          let daysOfWeek;

          if (event.recurring === "WEEKLY") {
            const day = dayjs(event.startDate).get("day");
            daysOfWeek = [day];
          }

          return {
            ...event,
            title: event.text || "(No title)",
            start: event.startDate,
            end: event.endDate,
            startRecur: event.startDate,
            startTime: dayjs(event.startDate).format("HH:mm"),
            endTime: event.endDate
              ? dayjs(event.endDate).format("HH:mm")
              : null,
            daysOfWeek: daysOfWeek,
            handleEventClick: handleEventClick,
          };
        }

        return {
          ...event,
          title: event.text || "(No title)",
          start: event.startDate,
          end: event.endDate,
          handleEventClick: handleEventClick,
        };
      });
    }
  }, [events, editEventMode, selectedEventID, selectedEvent]);

  useEffect(() => {
    const withoutTempEvents = events.filter((ev) => ev.id > 0);
    if (tempEvent) {
      setEvents([...withoutTempEvents, tempEvent]);
    } else {
      setEvents(withoutTempEvents);
    }
  }, [tempEvent]);

  const { t, i18n } = useTranslation();
  const [currentEvents, setCurrentEvents] = useState([]);
  const { data: currentProject } = useCurrentProject();
  const { refetch: refetchEvents } = useEvents(currentProject?.id);
  const [isValidDatetime, setIsValidDatetime] = useState(true);

  const [eventName, setEventName] = useState("");
  const handleEventName = (event) => {
    setEventName(event.target.value);
  };

  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const handleStartDate = (newValue) => {
    setStartDate(newValue);
    if (!editEventMode) {
      setTempEvent({
        ...tempEvent,
        startDate: dayjs(newValue).format(),
      });
    } else {
      setSelectedEvent({
        ...selectedEvent,
        startDate: dayjs(newValue).startOf("day").format(),
      });
    }
  };

  const [startTime, setStartTime] = useState(dayjs(new Date()));
  const handleStartTime = (newValue) => {
    setStartTime(newValue);
    if (!editEventMode) {
      setTempEvent({
        ...tempEvent,
        startDate: dayjs(newValue).format(),
      });
    } else {
      setSelectedEvent({
        ...selectedEvent,
        startDate: dayjs(newValue).format(),
      });
    }
  };

  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const handleEndDate = (newValue) => {
    setEndDate(newValue);
    if (!editEventMode) {
      setTempEvent({
        ...tempEvent,
        endDate: dayjs(newValue).format(),
      });
    } else {
      setSelectedEvent({
        ...selectedEvent,
        endDate: dayjs(newValue).add(1, "day").startOf("day").format(),
      });
    }
  };

  const [endTime, setEndTime] = useState(dayjs(new Date()));
  const handleEndTime = (newValue) => {
    setEndTime(newValue);
    if (!editEventMode) {
      setTempEvent({
        ...tempEvent,
        endDate: dayjs(newValue).format(),
      });
    } else {
      setSelectedEvent({
        ...selectedEvent,
        endDate: dayjs(newValue).format(),
      });
    }
  };

  const [allDay, setAllDay] = useState(false);
  const handleAllDay = () => {
    setAllDay(!allDay);
  };

  const [repeat, setRepeat] = useState("");
  const handleRepeat = (event) => {
    setRepeat(event.target.value);
  };

  const [calendar, setCalendar] = useState(1);
  const handleCalendar = (event) => {
    const newCalendar = calendars.find((cal) => cal.id === event.target.value);
    setCalendar(newCalendar);
  };

  const [details, setDetails] = useState("");
  const handleDetails = (event) => {
    setDetails(event.target.value);
  };

  const [openDeleteEventModal, setOpenDeleteEventModal] = useState(false);

  const fetchEvents = async (calendars) => {
    try {
      const response = calendars
        ? await getEvents({ projectId: currentProject.id, calendars })
        : await getEvents({ projectId: currentProject.id });
      const { data } = response.data;
      setEvents(data.events);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    if (currentProject) {
      fetchEvents();
    }
  }, [currentProject]);

  const fetchCalendars = async () => {
    const response = await getCalendars(currentProject?.id);
    const { data } = response;
    setCalendars(data.data.calendars);
  };

  useEffect(() => {
    if (currentProject) {
      fetchCalendars();
    }
  }, [currentProject]);

  useEffect(() => {
    if (calendars.length) {
      const newCalendar = calendars.find((cal) => cal.text === "Personal");
      setCalendar(newCalendar);
    }
  }, [calendars, calendars.length]);

  const handleDateSelect = (selectInfo) => {
    setSelectedEventID(null);
    setStartDate(dayjs(selectInfo.startStr));
    let tempEndDate;

    // The Fullcalendar issue that in the dayGridMonth, it automatically plus 1 day
    if (selectInfo.view.type === "dayGridMonth") {
      tempEndDate = dayjs(selectInfo.endStr).subtract(15, "minutes");
      setEndDate(tempEndDate);
    } else {
      tempEndDate = dayjs(selectInfo.endStr);
      setEndDate(tempEndDate);
    }

    setStartTime(dayjs(selectInfo.startStr));
    setEndTime(tempEndDate);
    setCreateEventPopup(true);

    const defaultCalendar = calendars.find((cal) => cal.text === "Personal");
    setCalendar(defaultCalendar);
    const temp = {
      id: 0,
      title: "(No title)",
      startDate: selectInfo.startStr,
      endDate: tempEndDate.format(),
      calendar: defaultCalendar,
    };

    setTempEvent(temp);
  };

  const handleChangeEvent = (eventInfo) => {
    const { event } = eventInfo;
    const offset = dayjs(event._instance.range.start).utcOffset();

    const eventData = {
      startDate: dayjs(event._instance.range.start)
        .subtract(offset, "minutes")
        .format(),
      endDate: dayjs(event._instance.range.end)
        .subtract(offset, "minutes")
        .format(),
    };

    updateEvent(event._def.publicId, eventData)
      .then((response) => {
        // Handle success (e.g., show a success message)
        console.log("Event added successfully:", response);

        // Refresh the calendar events by calling your fetch events function
        fetchEvents();
      })
      .catch((error) => {
        // Handle error (e.g., show an error message)
        console.error("Error adding event:", error);
      });
  };

  const handleSubmitEvent = () => {
    const normalStartDate = startDate;
    const normalStartTime = startTime;

    const normalEndDate = endDate;
    const normalEndTime = endTime;
    const formattedStartDate = startDate.format();
    const formattedStartTime = normalStartTime.format("HH:mm:ss");
    const formattedEndDate = endDate.format();
    const formattedEndTime = normalEndDate.format("HH:mm:ss");

    const eventColor = calendar
      ? calendars.find((item) => item.id === calendar.id).color
      : calendars.find((item) => item.text === "Personal").color;

    if (!selectedEventID) {
      const eventData = {
        // Create an event object from selectInfo.start and selectInfo.end
        text: eventName,
        startDate: `${
          allDay
            ? startDate.startOf("day")
            : formattedStartDate.replace("00:00:00", formattedStartTime)
        }`,
        endDate: `${
          allDay
            ? endDate.add(1, "day").startOf("day")
            : formattedEndDate.replace("23:45:00", formattedEndTime)
        }`,
        details: details,
        calendar: calendar,
        color: eventColor,
        allDay: allDay,
        projectId: currentProject?.id,
      };

      createEvent(eventData)
        .then((response) => {
          // Handle success (e.g., show a success message)
          console.log("Event added successfully:", response);

          // Refresh the calendar events by calling your fetch events function
          fetchEvents(selectedCategories.filter((x) => x !== "all").join(","));
          refetchEvents();
        })
        .catch((error) => {
          // Handle error (e.g., show an error message)
          console.error("Error adding event:", error);
        });
    } else {
      const formattedStartDate = startDate.startOf("day").format();
      const formattedStartTime = startTime.format("HH:mm:ss");
      const nStartDate = formattedStartDate.replace(
        "00:00:00",
        formattedStartTime
      );

      const formattedEndDate = endDate.startOf("day").format();
      const formattedEndTime = endTime.format("HH:mm:ss");
      const nEndDate = formattedEndDate.replace("00:00:00", formattedEndTime);

      let nCalendar = calendar
        ? calendar
        : calendars.find((c) => c.text === "Personal");

      const eventData = {
        // Create an event object from selectInfo.start and selectInfo.end
        text: eventName,
        startDate: `${allDay ? startDate.startOf("day") : nStartDate}`,
        endDate: `${allDay ? endDate.add(1, "day").startOf("day") : nEndDate}`,
        details: details,
        calendar: nCalendar,
        color: eventColor,
        allDay: allDay,
        projectId: currentProject?.id,
      };

      updateEvent(selectedEventID, eventData)
        .then((response) => {
          // Handle success (e.g., show a success message)
          console.log("Event added successfully:", response);

          // Refresh the calendar events by calling your fetch events function
          fetchEvents(selectedCategories.filter((x) => x !== "all").join(","));
          refetchEvents();
        })
        .catch((error) => {
          // Handle error (e.g., show an error message)
          console.error("Error adding event:", error);
        });
    }

    setEditEventMode(false);
    setCreateEventPopup(false);
    setEventName("");
    setStartDate(dayjs(new Date()));
    setStartTime(dayjs(new Date()));
    setEndDate(dayjs(new Date()));
    setEndTime(dayjs(new Date()));
    setAllDay(false);
    setRepeat("");
    setCalendar("");
    setDetails("");
  };

  const handleOpenDeleteEventModal = () => {
    setOpenDeleteEventModal(true);
  };

  const handleDeleteEvent = () => {
    // selectedEvent.event.remove();
    if (selectedEventID) {
      deleteEvent(selectedEventID)
        .then((response) => {
          // Handle success (e.g., show a success message)
          console.log("Event deleted successfully:", response);

          // Refresh the calendar events by calling your fetch events function
          fetchEvents();
        })
        .catch((error) => {
          // Handle error (e.g., show an error message)
          console.error("Error deleting event:", error);
        });
    }

    setCreateEventPopup(false);
    setEventName("");
    setStartDate(dayjs(new Date()));
    setStartTime(dayjs(new Date()));
    setEndDate(dayjs(new Date()));
    setEndTime(dayjs(new Date()));
    setAllDay(false);
    setRepeat("");
    setCalendar("");
    setDetails("");
    setSelectedEventID("");
    setSelectedEvent(undefined);
    setOpenDeleteEventModal(false);

    // handle remove temp event
    if (!selectedEventID) {
      setTempEvent();
    }
  };

  function handleEventClick(clickInfo) {
    setEditEventMode(true);

    const selectedStartDate = dayjs(
      clickInfo.event._def.extendedProps.startDate
    );
    const selectedEndDate = dayjs(clickInfo.event._def.extendedProps.endDate);
    const sEvent = events.find(
      (e) => e.id === parseInt(clickInfo.event._def.publicId)
    );
    setSelectedEventID(clickInfo.event._def.publicId);
    setSelectedEvent(sEvent);
    setEventName(clickInfo.event._def.extendedProps.text);
    setStartDate(selectedStartDate);
    setStartTime(selectedStartDate);
    setEndDate(selectedEndDate);
    setEndTime(selectedEndDate);
    setAllDay(clickInfo.event._def.allDay);
    setCalendar(clickInfo.event._def.extendedProps.calendar);
    setDetails(clickInfo.event._def.extendedProps.details);
    setCreateEventPopup(true);
  }

  function handleEvents(events) {
    setCurrentEvents(events);
  }

  const [date, setDate] = useState(new Date());

  const onChangeDate = (newDate) => {
    let calendarApi = fullCalendarComponentRef.current.getApi();
    calendarApi.gotoDate(newDate); // call a method on the Calendar object
    setDate(newDate);
  };

  // filtering events by category
  const [selectedCategories, setSelectedCategories] = useState(["all"]);

  const handleCategoryToggle = (category) => {
    if (category === "all") {
      setSelectedCategories(["all"]);
      fetchEvents();
    } else {
      if (selectedCategories.includes("all")) {
        setSelectedCategories([category]);
        fetchEvents([category].join(","));
      } else {
        setSelectedCategories((prevCategories) => {
          if (prevCategories.includes(category)) {
            fetchEvents(
              prevCategories.filter((cat) => cat !== category).join(",")
            );
            return prevCategories.filter((cat) => cat !== category);
          } else {
            fetchEvents([...prevCategories, category].join(","));
            return [...prevCategories, category];
          }
        });
      }
    }
  };

  const [newCategoryTitle, setNewCategoryTitle] = useState("");
  const [addCategory, setAddCategory] = useState(false);

  const newCategoryTitleHandler = (e) => {
    setNewCategoryTitle(e.target.value);
  };

  const handleCreateUserCalendar = async (e) => {
    if (e.keyCode === 13) {
      setNewCategoryTitle("");

      await createCalendarApi({
        text: e.target.value,
        projectId: currentProject?.id,
        color: "#997ceb",
      });

      await fetchCalendars();

      setAddCategory(false);
    }
  };

  const [createEventPopup, setCreateEventPopup] = useState(false);
  const closeEventPopup = (event, reason) => {
    setEditEventMode(false);
    setCreateEventPopup(false);
    setEventName("");
    setStartDate(dayjs(new Date()));
    setStartTime(dayjs(new Date()));
    setEndDate(dayjs(new Date()));
    setEndTime(dayjs(new Date()));
    setAllDay(false);
    setRepeat("");
    setCalendar("");
    setDetails("");
    setTempEvent();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const [categoryIndex, setCategoryIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const handleChangeCalendarColor = async (e) => {
    const selecetedCal = calendars[categoryIndex];

    await updateCalendarApi(selecetedCal.id, {
      color: e.hex,
      projectId: currentProject?.id,
    });

    await fetchCalendars();
    await fetchEvents(selectedCategories.filter((x) => x !== "all").join(","));

    handleCloseOptions();
  };

  // start delete popup
  const [settingPopup, setSettingPopup] = useState(false);
  const handleOpenSettingPopup = () => {
    setSettingPopup(true);
    handleCloseOptions();
  };
  const handleCloseSettingPopup = () => {
    setSettingPopup(false);
  };
  // end delete popup

  const handleEventFromDashboard = () => {
    const selectedEvent = fullcalendarEvents.find(
      (event) => event.id === parseInt(searchParams.get("eventId"))
    );
    setEditEventMode(true);

    const selectedStartDate = dayjs(selectedEvent.startDate);
    const selectedEndDate = dayjs(selectedEvent.endDate);

    onChangeDate(selectedEndDate.toISOString());

    let calendarApi = fullCalendarComponentRef.current.getApi();
    calendarApi.scrollToTime(selectedStartDate);

    setSelectedEventID(selectedEvent.id);
    setSelectedEvent(selectedEvent);
    setEventName(selectedEvent.text);
    setStartDate(selectedStartDate);
    setStartTime(selectedStartDate);
    setEndDate(selectedEndDate);
    setEndTime(selectedEndDate);
    setAllDay(selectedEvent.allDay);
    setCalendar(selectedEvent.calendar);
    setDetails(selectedEvent.details);
    setCreateEventPopup(true);
  };

  const handleOpenFromDashboard = (view) => {
    let calendarApi = fullCalendarComponentRef.current.getApi();
    calendarApi.changeView(view);
  };

  useEffect(() => {
    if (searchParams.get("eventId") && fullcalendarEvents.length > 0) {
      handleEventFromDashboard();
      setSearchParams({});
    }
  }, [searchParams, fullcalendarEvents]);

  useEffect(() => {
    const todayElement = document.getElementsByClassName("fc-today-button")[0];
    if (todayElement) {
      todayElement.addEventListener("click", () => {
        setDate(dayjs().toISOString());
      });
    }
  }, []);

  const handleMeetingNow = (roomUid) => {
    setSearchParams({ meeting: roomUid });
    setCreateEventPopup(false);
    dispatch(handleOpenMeetingPageModal());
  };

  const appIsFullPage = useSelector(
    (state) => state.appsModal.appModalIsFullPage
  );

  const openFullPage = () => {
    dispatch(handleOpenAppModalIsFullPage());
    setTimeout(
      ()=>{window.dispatchEvent(new Event('resize'));},
      500
    );
  };

  const closeFullPage = () => {
    dispatch(handleCloseAppModalIsFullPage());
    setTimeout(
      ()=>{window.dispatchEvent(new Event('resize'));},
      500
    );
  };

  const [viewTitle, setViewTitle] = useState("");
  const [selectedView, setSelectedView] = useState("dayGridMonth");

  const handleViewChange = (viewName) => {
    fullCalendarComponentRef.current.getApi().changeView(viewName);
  };

  useEffect(() => {
    const calendarApi = fullCalendarComponentRef.current.getApi();

    const updateViewTitle = () => {
      const viewDate = calendarApi.getDate();

      let formattedTitle = "";

      switch (selectedView) {
        case "timeGridDay":
          // const originalDate = new Date('Thu May 09 2024 00:00:00 GMT+0200 (Central European Summer Time)');
          formattedTitle = viewDate.toLocaleDateString(getLangISOFormat(i18n.language), {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
          // formattedTitle = viewDate.toLocaleString('default', { day: '2-digit', month: 'long', year: 'numeric' });
          break;
        case "timeGridWeek":
        case "dayGridMonth":
        case "listMonth":
          formattedTitle = viewDate.toLocaleString(getLangISOFormat(i18n.language), {
            month: "long",
            year: "numeric",
          });
          break;
        default:
          formattedTitle = viewDate.toLocaleString(getLangISOFormat(i18n.language), {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
      }

      setViewTitle(formattedTitle);
    };

    if (calendarApi) {
      updateViewTitle();

      const onViewChange = () => {
        if (selectedView !== calendarApi.view.type) {
          setSelectedView(calendarApi.view.type);
        }
        updateViewTitle();
      };

      calendarApi.on("viewDidMount", onViewChange);
      calendarApi.on("datesSet", onViewChange);
      calendarApi.on("viewSkeletonRender", onViewChange);
    }

    return () => {
      if (calendarApi) {
        calendarApi.off("viewDidMount", updateViewTitle);
        calendarApi.off("datesSet", updateViewTitle);
        calendarApi.off("viewSkeletonRender", updateViewTitle);
      }
    };
  }, [selectedView]);

  return (
    <div className="page-container">
      <div className="cloud-page" id="cloud-page">
        <div className="cloud-page__header">
          <div className="cloud-page__header_calendar-container">
            <div className="cloud-page__header_share">
              <div className="cloud-page__header_share_icon">
                <img src={calendarIcon} alt="calendar app icon" />
              </div>

              <div className="cloud-page__header_share_title">
                {t("SERVICES.CALENDAR.TITLE")}
              </div>
            </div>

            <div className="cloud-page__header_details calendar-app__header">
              <div className="calendar-app__header-left">
                <div className="calendar-app__header-left_actions">
                  <Button
                    onClick={() => {
                      fullCalendarComponentRef.current.getApi().prev();
                    }}
                  >
                    <img src={prevIcon} alt="prev icon" />
                  </Button>

                  <span>{viewTitle}</span>

                  <Button
                    onClick={() => {
                      fullCalendarComponentRef.current.getApi().next();
                    }}
                  >
                    <img src={nextIcon} alt="next icon" />
                  </Button>
                </div>
              </div>

              <div className="calendar-app__header-right">
                <div className="calendar-app__header-right_actions">
                  <Button
                    className={selectedView === "timeGridDay" && "active"}
                    onClick={() => {
                      setSelectedView("timeGridDay");
                      handleViewChange("timeGridDay");
                    }}
                  >
                    Day
                  </Button>

                  <Button
                    className={selectedView === "timeGridWeek" && "active"}
                    onClick={() => {
                      setSelectedView("timeGridWeek");
                      handleViewChange("timeGridWeek");
                    }}
                  >
                    Week
                  </Button>

                  <Button
                    className={selectedView === "dayGridMonth" && "active"}
                    onClick={() => {
                      setSelectedView("dayGridMonth");
                      handleViewChange("dayGridMonth");
                    }}
                  >
                    Month
                  </Button>

                  <Button
                    className={selectedView === "listMonth" && "active"}
                    onClick={() => {
                      setSelectedView("listMonth");
                      handleViewChange("listMonth");
                    }}
                  >
                    Agenda
                  </Button>
                </div>

                {!appIsFullPage ? (
                  <IconButton aria-label="expand" onClick={openFullPage}>
                    <img src={expandIcon} alt="expand" />
                  </IconButton>
                ) : (
                  <IconButton aria-label="compress" onClick={closeFullPage}>
                    <img src={compressIcon} alt="compress" />
                  </IconButton>
                )}

                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    dispatch(handleCloseAppsModal());
                    dispatch(handleCloseAppModalIsFullPage());
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <div className="cloud-page__content">
          <div className="calendar-page">
            <div className="grid-content">
              <div className="grid-content_left">
                <div className="calendar-page_sidebar">
                  <div className="calendar-page_sidebar_create-event">
                    <Button
                      className="calendar-page_sidebar_create-event_btn"
                      aria-label="calendar create event"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={() => setCreateEventPopup(true)}
                    >
                      {t("CALENDAR_PAGE.CREATE__EVENT_BUTTON")}
                    </Button>

                    <Button
                      style={{ display: "none" }}
                      id="event-dashboard-connector"
                      onClick={handleEventFromDashboard}
                    >
                      Connector
                    </Button>

                    <Button
                      style={{ display: "none" }}
                      id="event-dashboard-connector-open-day-view"
                      onClick={() => handleOpenFromDashboard("timeGridDay")}
                    >
                      Connector Open day view
                    </Button>

                    <Button
                      style={{ display: "none" }}
                      id="event-dashboard-connector-open-week-view"
                      onClick={() => handleOpenFromDashboard("timeGridWeek")}
                    >
                      Connector Open week view
                    </Button>

                    <Button
                      style={{ display: "none" }}
                      id="event-dashboard-connector-open-month-view"
                      onClick={() => handleOpenFromDashboard("dayGridMonth")}
                    >
                      Connector Open month view
                    </Button>

                    <Drawer
                      transitionDuration={0}
                      anchor="right"
                      open={createEventPopup}
                      onClose={closeEventPopup}
                      disableScrollLock={false}
                      id="create-event-drawer"
                      className={`calendar-page_sidebar_create-event_drawer ${appIsFullPage ? 'with-full-page' : 'without-full-page'}`}
                      ModalProps={{ onBackdropClick: closeEventPopup }}
                    >
                      <CreateEventsPopup
                        openCreateEventPopup={createEventPopup}
                        setCreateEventPopup={setCreateEventPopup}
                        handleSubmitEvent={handleSubmitEvent}
                        categories={Calendar_page_current_events}
                        eventName={eventName}
                        handleEventName={handleEventName}
                        startDate={startDate}
                        handleStartDate={handleStartDate}
                        startTime={startTime}
                        handleStartTime={handleStartTime}
                        endDate={endDate}
                        handleEndDate={handleEndDate}
                        endTime={endTime}
                        handleEndTime={handleEndTime}
                        allDay={allDay}
                        handleAllDay={handleAllDay}
                        repeat={repeat}
                        handleRepeat={handleRepeat}
                        calendars={calendars}
                        calendar={calendar}
                        handleCalendar={handleCalendar}
                        details={details}
                        handleDetails={handleDetails}
                        handleDeleteEvent={handleOpenDeleteEventModal}
                        closeEventPopup={closeEventPopup}
                        editEventMode={editEventMode}
                        isValidDatetime={isValidDatetime}
                        setIsValidDatetime={setIsValidDatetime}
                        selectedCalendars={selectedCategories}
                        selectedEvent={selectedEvent}
                        handleMeetingNow={handleMeetingNow}
                      />
                    </Drawer>
                  </div>

                  <div className="calendar-page_sidebar-section">
                    <Calendar
                      locale="en"
                      onChange={onChangeDate}
                      value={date}
                      nextLabel={<ArrowForwardIosIcon />}
                      prevLabel={<ArrowBackIosNewIcon />}
                      formatShortWeekday={(locale, date) =>
                        [`S`, `M`, `T`, `W`, `T`, `F`, `S`][date.getDay()]
                      }
                    />
                  </div>

                  <div className="calendar-page_sidebar-section">
                    {addCategory ? (
                      <TextField
                        className="calendar-page_sidebar-section_add-calendar"
                        value={newCategoryTitle}
                        autoFocus
                        variant="outlined"
                        onChange={newCategoryTitleHandler}
                        onKeyDown={handleCreateUserCalendar}
                        onBlur={() => setAddCategory(false)}
                      />
                    ) : (
                      ""
                    )}

                    <FormGroup className="calendar-page_sidebar-section_filter">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedCategories.includes("all") ||
                              selectedCategories.length === 0
                            }
                            onChange={() => handleCategoryToggle("all")}
                          />
                        }
                        label="All"
                      />

                      <Box
                        className="scrollable-section"
                        display="flex"
                        flexDirection="column"
                        style={{ overflowY: "scroll" }}
                      >
                        {calendars.map((item, index) => (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: item.color,
                                    "&.Mui-checked": { color: item.color },
                                  }}
                                  value={item.id}
                                  checked={selectedCategories.includes(item.id)}
                                  onChange={() => handleCategoryToggle(item.id)}
                                />
                              }
                              label={
                                <div className="calendar-page_sidebar-section_filter-action">
                                  {item.text}

                                  <IconButton
                                    aria-label="setting"
                                    id="long-button"
                                    aria-controls={
                                      open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenOptions(e);
                                      setCategoryIndex(index);
                                      setSelectedCategory(item);
                                    }}
                                  >
                                    <img src={settingIcon} />
                                  </IconButton>
                                </div>
                              }
                            />
                          </>
                        ))}
                      </Box>

                      <Menu
                        id="long-menu"
                        className="calendar-page_sidebar-section_filter-action-popup"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseOptions}
                        disableScrollLock={true}
                      >
                        <MenuItem disableRipple={true}>
                          <CirclePicker
                            onChangeComplete={(e) =>
                              handleChangeCalendarColor(e)
                            }
                            colors={CalendarColors}
                            circleSize={22}
                          />
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            handleOpenSettingPopup();
                          }}
                        >
                          {t("ACTIONS.SETTING")}
                        </MenuItem>
                      </Menu>
                    </FormGroup>

                    <Button
                      startIcon={<AddIcon />}
                      className="calendar-page_sidebar-section_add-btn"
                      onClick={() => setAddCategory(true)}
                    >
                      {t("SERVICES.CALENDAR.ADD_CALENDAR")}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="grid-content_right">
                <div className="calendar-page_main">
                  <div className="calendar-page_main_calendar">
                    <FullCalendar
                      ref={fullCalendarComponentRef}
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        listPlugin,
                        AgendaCustomView,
                      ]}
                      am
                      headerToolbar={{
                        start: "prev title next today",
                        center: "",
                        end: "timeGridDay,timeGridWeek,dayGridMonth,listMonth",
                      }}
                      buttonText={{
                        today: "Today",
                        month: "Month",
                        week: "Week",
                        day: "Day",
                        listMonth: "Agenda",
                      }}
                      views={{
                        dayGridMonth: {
                          titleFormat: { year: "numeric", month: "long" },
                          displayEventTime: true,
                          // displayEventEnd: true,
                          dragRevertDuration: 1,
                        },

                        timeGridDay: {
                          titleFormat: (e) => {
                            return dayjs(e.date.marker).format(
                              SECONDARY_DATE_FORMAT
                            );
                          },
                          dayHeaderContent: (info) => {
                            return (
                              <Box display="flex" gap={0.5}>
                                <Box fontWeight={500}>
                                  {dayjs(info.date).format("dddd")}
                                </Box>
                              </Box>
                            );
                          },
                          slotDuration: "00:30",
                          snapDuration: "00:05",
                          slotEventOverlap: false,
                          slotLabelFormat: (e) => {
                            const offset = dayjs(e.start.marker).utcOffset();
                            return dayjs(e.start.marker)
                              .subtract(offset, "minutes")
                              .format("HH:mm");
                          },
                          dragRevertDuration: 1,
                          eventMinHeight: 1,
                        },

                        timeGridWeek: {
                          titleFormat: { year: "numeric", month: "long" },
                          dayHeaderContent: (info) => {
                            return (
                              <Box display="flex" gap={0.5}>
                                <Box>{dayjs(info.date).format("ddd")}</Box>
                                <Box className="weekdate-box" fontWeight={500}>
                                  {dayjs(info.date).format("D")}
                                </Box>
                              </Box>
                            );
                          },
                          slotDuration: "00:30",
                          snapDuration: "00:05",
                          slotEventOverlap: false,
                          slotLabelFormat: (e) => {
                            const offset = dayjs(e.start.marker).utcOffset();
                            return dayjs(e.start.marker)
                              .subtract(offset, "minutes")
                              .format("HH:mm");
                          },
                          dragRevertDuration: 1,
                          eventMinHeight: 1,
                        },

                        listMonth: {
                          duration: {
                            months: 1,
                          },
                        },
                      }}
                      eventTimeFormat={{
                        hour: "2-digit",
                        hour12: false,
                        minute: "2-digit",
                        meridiem: "short",
                      }}
                      allDaySlot={false}
                      allDayText="All day"
                      firstDay={1}
                      initialView={initView}
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      dayMaxEvents={true}
                      fixedWeekCount={false}
                      weekends={true}
                      navLinks={true}
                      selectOverlap={true}
                      nowIndicator={true}
                      showNonCurrentDates={true}
                      displayEventTime={true}
                      eventStartEditable={true}
                      eventDisplay="block"
                      eventResizableFromStart={true}
                      aspectRatio={2}
                      unselectAuto={true}
                      events={fullcalendarEvents} // alternatively, use the `events` setting to fetch from a feed
                      select={handleDateSelect}
                      eventClick={handleEventClick}
                      eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                      eventChange={handleChangeEvent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* setting modal */}
        <Modal
          hideBackdrop
          open={settingPopup}
          onClose={() => handleCloseSettingPopup()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="cloud-page__header_invoice-manager-details_add-modal"
        >
          <SettingPopup
            selectedCategory={selectedCategory}
            handleCloseSettingPopup={handleCloseSettingPopup}
            categoryIndex={categoryIndex}
            Calendar_page_current_events={Calendar_page_current_events}
            fetchCalendars={fetchCalendars}
            fetchEvents={fetchEvents}
            currentProject={currentProject}
          />
        </Modal>

        <Modal
          hideBackdrop
          open={openDeleteEventModal}
          onClose={() => setOpenDeleteEventModal(false)}
          className="cloud-page__header_invoice-manager-details_add-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 350,
              bgcolor: "background.paper",
              borderRadius: 3,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="start"
            >
              <Typography
                sx={{ marginBottom: 3 }}
                className="mb-5"
                variant="h6"
                component="h6"
              >
                {t("ACTIONS.DELETE_CARD")}
              </Typography>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setOpenDeleteEventModal(false)}
              />
            </Box>

            <Box>
              <Typography
                sx={{ marginBottom: 3 }}
                className="mb-5"
                variant="h6"
                component="div"
                fontSize={16}
              >
                {t("ACTIONS.CANNOT_UNDO_NOTI")}
              </Typography>
            </Box>

            <Box>
              <Button
                fullWidth
                sx={{
                  borderRadius: 15,
                  height: 40,
                }}
                variant="outlined"
                color="error"
                onClick={() => handleDeleteEvent()}
              >
                {t("ACTIONS.DELETE")}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={openMeetingPageModal}
          onClose={handleCloseMeetingPageModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`meeting-page-modal ${
            firstPopupTab ? "meeting-page-modal-shift-one" : ""
          } ${secondPopupTab ? "meeting-page-modal-shift-two" : ""}`}
          disableEnforceFocus
        >
          <div className="meeting-page-modal-container">
            <MeetingPage conferenceName={""} />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default CalendarPage;
