import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import TimezoneSelect from "react-timezone-select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import arrowLeftIcon from "../../../../../../assets/images/vide-conferencing/chevron-left.svg";
import scheduleIcon from "../../../../../../assets/images/vide-conferencing/schedule.png";
import dateIcon from "../../../../../../assets/images/vide-conferencing/date.png";
import nameIcon from "../../../../../../assets/images/vide-conferencing/name.svg";
import attendeeIcon from "../../../../../../assets/images/vide-conferencing/attendee.svg";
import timezoneIcon from "../../../../../../assets/images/vide-conferencing/timezone.png";
import passwordIcon from "../../../../../../assets/images/vide-conferencing/password.svg";
import "./schedule.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import {
  DEFAULT_DATE_FORMAT,
  SECONDARY_DATE_FORMAT,
} from "../../../../../../constants/datetime";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Avatar, Box, Radio, RadioGroup } from "@mui/material";
import { renderFullName } from "../../../../../../utils/UserUtil";
import { stringAvatar } from "../../../../../../utils/AvatarUtil";
import { Add, LockOpenOutlined, Repeat } from "@material-ui/icons";
import AttendeeListSelect from "../../../AttendeeListSelect";
import Popover from "@mui/material/Popover";
import { TextSnippetOutlined } from "@mui/icons-material";
import {
  createEvent,
  createMeetingRoom as createMeetingRoomApi,
  getCalendars,
  updateMeetingRoom,
} from "../../../../../../api/Api";
import { useCurrentProject } from "../../../../../../hooks/projects";
import {
  useMeetingRoomHistory,
  useUpcomingMeetings,
} from "../../../../../../hooks/meetingRoom.hook";
import { toast } from "react-toastify";
import { useCurrentUser } from "../../../../../../hooks/auth.hook";

const Schedule = ({ handleShowMain }) => {
  const { t } = useTranslation();
  const [dateValue, setDateValue] = useState(dayjs);
  const [startTimeValue, setStartTimeValue] = useState(dayjs());
  const [endTimeValue, setEndTimeValue] = useState(dayjs().add(1, "hour"));
  const [attendees, setAttendees] = useState([]);
  const [addToCalendar, setAddToCalendar] = useState(false);
  const [recurringMeeting, setRecurringMeeting] = useState(false);
  const [conferenceName, setConferenceName] = useState("");
  const [roomUid, setRoomUid] = useState("");
  const [frecency, setFrecency] = useState("DAILY");
  const { data: currentProject } = useCurrentProject();
  const { data: currentUser } = useCurrentUser();

  const [anchorAttendeeListEl, setAnchorAttendeeListEl] = React.useState(null);
  const [meetingCalendar, setMeetingCalendar] = useState(null);

  const openAttendeeList = Boolean(anchorAttendeeListEl);

  const [notes, setNotes] = useState(null);

  const handleClick = (event) => {
    setAnchorAttendeeListEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorAttendeeListEl(null);
  };

  const [selectedTimezone, setSelectedTimezone] = useState(
    dayjs().utcOffset() === 60
      ? "Europe/Amsterdam"
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const [enabledPassword, setEnabledPassword] = useState(false);

  const [password, setPassword] = useState(null);

  const handleToogleEnabledPassword = () => {
    setEnabledPassword(!enabledPassword);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const fetchMeetingCalendar = async () => {
    const response = await getCalendars(currentProject?.id);
    const { data } = response;
    const rmeetingCalendar = data?.data?.calendars?.find(
      (c) => c.text === "Meeting"
    );
    setMeetingCalendar(rmeetingCalendar);
  };

  useEffect(() => {
    if (currentProject) {
      fetchMeetingCalendar();
    }
  }, [currentProject]);

  const handleCreateSchedule = async () => {
    const attendeeIds = attendees.map((a) => a.id);
    attendeeIds.push(currentUser.id);

    const fdate = dateValue.startOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
    const fstartTime = startTimeValue.format("HH:mm:ss");
    const fendTime = endTimeValue.format("HH:mm:ss");

    const combinedStartTime = fdate.replace("00:00:00", fstartTime);
    const combinedEndTime = fdate.replace("00:00:00", fendTime);

    let timezone = selectedTimezone;

    if (typeof selectedTimezone === "string") {
      timezone = selectedTimezone;
    } else {
      timezone = selectedTimezone.value;
    }

    const meetingRoomResponse = await createMeetingRoomApi({
      roomName: conferenceName,
      password: password,
      roomUid: roomUid,
      projectId: currentProject.id,
      startAt: combinedStartTime,
      endAt: combinedEndTime,
      attendees: attendeeIds,
      timezone: timezone,
      frecency: recurringMeeting ? frecency : "ONCE",
      notes: notes
        ? [
            {
              userId: currentUser.id,
              text: notes,
              firstName: currentUser.firstName,
              lastName: currentUser.lastName,
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          ]
        : [],
    });

    if (addToCalendar) {
      const eventData = {
        text: `Meeting: ${conferenceName}`,
        startDate: combinedStartTime,
        endDate: combinedEndTime,
        details: `Password: ${password ? password : ""} \nUrl: ${
          process.env.REACT_APP_EXTERNAL_MEET_URL
        }?meeting=${roomUid}`,
        calendar: meetingCalendar,
        color: "#6D4BCE",
        allDay: false,
        projectId: currentProject?.id,
        recurring: recurringMeeting ? frecency : null,
        roomUid: roomUid,
      };
      const response = await createEvent(eventData);
      const { data } = response.data;
      const { data: meetingData } = meetingRoomResponse;

      await updateMeetingRoom(meetingData.meetingRoom.id, {
        eventId: data.event.id,
      });
    }

    toast.success(t("VIDEO_CONFERENCING_PAGE.CREATED_SCHEDULE_MEETING"));

    await refetchMeetingRoomHistory();
    await refetchUpcomingMeetings();
  };

  const { refetch: refetchMeetingRoomHistory } = useMeetingRoomHistory({
    projectId: currentProject.id,
    to: new Date(dayjs().subtract(1, "day").startOf("day").format()),
  });

  const { refetch: refetchUpcomingMeetings } = useUpcomingMeetings({
    projectId: currentProject.id,
    from: new Date(dayjs().startOf("day").format()),
  });

  const handleChangeNotes = (event) => {
    setNotes(event.target.value);
  };

  const handleChangeMeetingRoomName = (event) => {
    setConferenceName(event.target.value);
    const troomUid = `${currentProject.id}-${(Math.random() + 1)
      .toString(36)
      .substring(7)}`;
    setRoomUid(troomUid, roomUid);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 31,
    height: 18,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      height: "100%",
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        marginTop: 0,
        marginBottom: 0,
        marginRight: 2,
        marginLeft: 0,
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#767680E5",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 13,
      height: 13,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#c4bfd2" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <div className="schedule">
      <div className="schedule-actions">
        <Button
          variant="outlined"
          startIcon={<img src={arrowLeftIcon} />}
          onClick={() => handleShowMain()}
        >
          {t("ACTIONS.BACK")}
        </Button>
      </div>

      <div className="schedule-content">
        <div className="schedule-content_icon">
          <img src={scheduleIcon} alt="schedule" />

          <p>{t("VIDEO_CONFERENCING_PAGE.SCHEDULE")}</p>
        </div>

        <form className="schedule-content_form">
          <div className="schedule-content_form-item">
            <p className="schedule-content_form-item-title">
              <img src={nameIcon} alt="conference name" />
              <span>{t("VIDEO_CONFERENCING_PAGE.CONFERENCE_NAME")}</span>
            </p>
            <TextField
              placeholder="Name your conference"
              className="schedule-content_form-item-input"
              onChange={(e) => handleChangeMeetingRoomName(e)}
            />
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-date">
              <p className="schedule-content_form-item-date-title">
                <img src={dateIcon} alt="date" />
                <span>{t("VIDEO_CONFERENCING_PAGE.DATE_TIME")}</span>
              </p>

              <form className="schedule-content_form-item-date-form">
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    md={6}
                    className="schedule-content_form-item-date-form-field"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        format={SECONDARY_DATE_FORMAT}
                        defaultValue={dateValue}
                        value={dateValue}
                        onChange={(newValue) => setDateValue(newValue)}
                        disablePast
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    xs={6}
                    md={3}
                    className="schedule-content_form-item-date-form-field"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{ p: 0 }}
                        components={["DesktopTimePicker"]}
                      >
                        <DemoItem>
                          <DesktopTimePicker
                            timeSteps={{ minutes: 15 }}
                            value={startTimeValue}
                            onChange={(newValue) => {
                              setStartTimeValue(newValue);
                              setEndTimeValue(newValue.add(1, "hour"));
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <FilterListIcon />
                              </InputAdornment>
                            }
                            ampm={false}
                            minTime={
                              dayjs().isSame(dateValue, "date")
                                ? dayjs()
                                : undefined
                            }
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    xs={6}
                    md={3}
                    className="schedule-content_form-item-date-form-field"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{ p: 0 }}
                        components={["DesktopTimePicker"]}
                      >
                        <DemoItem>
                          <DesktopTimePicker
                            timeSteps={{ minutes: 15 }}
                            value={endTimeValue}
                            onChange={(newValue) => setEndTimeValue(newValue)}
                            endAdornment={
                              <InputAdornment position="end">
                                <FilterListIcon />
                              </InputAdornment>
                            }
                            ampm={false}
                            minTime={startTimeValue.add(10, "minutes")}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    xs={12}
                    md={3}
                    className="schedule-content_form-item-date-form-field"
                  ></Grid>
                </Grid>
              </form>
            </div>
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-timezone">
              <p className="schedule-content_form-item-timezone-title">
                <img src={timezoneIcon} alt="conference name" />
                <span>{t("COMMON.TIMEZONE")}</span>
              </p>

              <div className="schedule-content_form-item-timezone-select">
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                />
              </div>
            </div>
          </div>

          <div className="schedule-content_form-item">
            <p className="schedule-content_form-item-title">
              <img src={attendeeIcon} alt="conference name" />
              <span>{t("VIDEO_CONFERENCING_PAGE.ATTENDEE")}</span>
            </p>

            <Box sx={{ display: "flex" }}>
              {attendees.map((a) => {
                const fullName = renderFullName(a);

                return (
                  <Avatar
                    {...stringAvatar(fullName)}
                    sx={{ width: 30, height: 30, fontSize: 14, mr: 1 }}
                  />
                );
              })}

              <IconButton
                sx={{ backgroundColor: "white", height: 28, width: 28 }}
                aria-label="add-attendee"
                aria-describedby="attendee-list-popover"
                onClick={handleClick}
              >
                <Add />
              </IconButton>
            </Box>

            <Popover
              id="attendee-list-popover"
              open={openAttendeeList}
              anchorEl={anchorAttendeeListEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <AttendeeListSelect
                attendees={attendees}
                setAttendees={setAttendees}
              />
            </Popover>
          </div>

          <div className="schedule-content_form-item">
            <p className="schedule-content_form-item-title">
              <TextSnippetOutlined />
              <span>{t("MENU.NOTES")}</span>
            </p>
            <TextField
              placeholder={t("ACTIONS.ADD_RESOURCE", {
                resource: t("MENU.NOTES").toLowerCase(),
              })}
              className="schedule-content_form-item-input"
              onChange={(e) => handleChangeNotes(e)}
            />
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-switch">
              <div
                className="schedule-content_form-item-switch-label"
                onClick={() => setAddToCalendar(!addToCalendar)}
              >
                <Add />
                <span>{t("VIDEO_CONFERENCING_PAGE.ADD_TO_CALENDAR")}</span>
              </div>
              <div className="schedule-content_form-item-switch-btn">
                <IOSSwitch
                  checked={addToCalendar}
                  onChange={() => setAddToCalendar(!addToCalendar)}
                />
              </div>
            </div>
          </div>

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-switch">
              <div
                className="schedule-content_form-item-switch-label"
                onClick={() => setRecurringMeeting(!recurringMeeting)}
              >
                <Repeat />
                <span>{t("VIDEO_CONFERENCING_PAGE.RECURRING_MEETING")}</span>
              </div>
              <div className="schedule-content_form-item-switch-btn">
                <IOSSwitch
                  checked={recurringMeeting}
                  onChange={() => setRecurringMeeting(!recurringMeeting)}
                />
              </div>
            </div>
          </div>

          {recurringMeeting && (
            <div className="schedule-content_form-item">
              <div className="schedule-content_form-item-switch">
                <RadioGroup
                  className="meeting-shortcut-item-end-select-radio"
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={frecency}
                  onChange={(event) => setFrecency(event.target.value)}
                >
                  <FormControlLabel
                    value="DAILY"
                    control={<Radio />}
                    label={t("COMMON.TIME.DAILY")}
                  />
                  <FormControlLabel
                    value="WEEKLY"
                    control={<Radio />}
                    label={t("COMMON.TIME.WEEKLY")}
                  />
                </RadioGroup>
              </div>
            </div>
          )}

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-switch">
              <div
                className="schedule-content_form-item-switch-label"
                onClick={handleToogleEnabledPassword}
              >
                <LockOpenOutlined />
                <span>{t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION")}</span>
              </div>
              <div className="schedule-content_form-item-switch-btn">
                <IOSSwitch
                  checked={password}
                  onChange={handleToogleEnabledPassword}
                />
              </div>
            </div>
          </div>

          {enabledPassword ? (
            <div className="schedule-content_form-item">
              <p className="schedule-content_form-item-title-info">
                {/* <img src={attendeeIcon} alt="conference name" /> */}
                <span>
                  {t("VIDEO_CONFERENCING_PAGE.PASSWORD_PROTECTION_DESC")}
                </span>
              </p>
              {/* <TextField placeholder="Enter your password..." className="schedule-content_form-item-input" type="password" /> */}
              <FormControl className="schedule-content_form-item-input">
                <OutlinedInput
                  placeholder="Enter your password"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          ) : (
            ""
          )}

          <div className="schedule-content_form-item">
            <div className="schedule-content_form-item-submit">
              <Button
                className="schedule-content_form-item-submit-btn"
                onClick={handleCreateSchedule}
              >
                {t("VIDEO_CONFERENCING_PAGE.SCHEDULE")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Schedule;
