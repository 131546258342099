import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import icon from "../../../../assets/images/my-services/video-conference.png";
import "./meeting-page.scss";
import { useSelector, useDispatch } from "react-redux";
import { toggleNotePopup, toggleSecondPopupTab } from '../../../../redux/app/popupSlice';
import MeetingDetailsBar from './MeetingDetailsBar';
import MeetingPageContent from './MeetingPageContent';
import { getMeetingRoomByRoomUid } from "../../../../api/Api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";

const MeetingPage = ({ isGuest = false }) => {
  const { t } = useTranslation();
  const [room, setRoom] = useState(null);
  const [params] = useSearchParams();

  const formatRoomData = (roomData) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const mtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startAt = dayjs(roomData.startAt).tz(mtimezone);
    let endAt = null;
    if (roomData.endAt) {
      endAt = dayjs(roomData.endAt).tz(mtimezone);
    }

    return {
      ...roomData,
      startAt: startAt,
      endAt: endAt,
    };
  };

  const getRoomInfo = async (roomUid) => {
    try {
      const response = await getMeetingRoomByRoomUid(roomUid);

      if (response.data) {
        const roomData = formatRoomData(response.data.meetingRoom);
        setRoom(roomData);
      }
    } catch (error) {
      console.log("err", error);
      toast.error(t("VIDEO_CONFERENCING.ERROR_LOAD_ROOM"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (params.get("meeting")) {
      getRoomInfo(params.get("meeting"));
    }
  }, [params]);

  return (
    <div className="page-container">
      <div className="cloud-page">
        <div className="cloud-page__header">
          <div className="grid-content">
            <div className="grid-content_left">
              <div className='cloud-page__header_share'>
                <div className='cloud-page__header_share_icon'>
                  <img src={icon} />
                </div>

                <div className="cloud-page__header_share_title">
                {t("VIDEO_CONFERENCING_PAGE.TITLE")}
                </div>

                <div className="meeting-page_details-info" style={{ marginLeft: 10 }}>
                  <Button style={{ cursor: 'unset' }} variant="contained">{room?.roomName}</Button>
                </div>
              </div>
            </div>

            <div className="grid-content_right">
              <div className='cloud-page__header_video-conferencing_details'>
                <MeetingDetailsBar isGuest={isGuest} />
              </div>
            </div>
          </div>

        </div>
        <div className="cloud-page__content">
          <MeetingPageContent isGuest={isGuest} />
        </div>
      </div>

    </div>
  );
};

export default MeetingPage;

