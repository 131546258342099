import React, { useState } from 'react';
import './invoices-popups.scss';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';




const ChangeCategoryPopup = ({ handleCloseChangeCategoryPopup, data }) => {
  const { t } = useTranslation()

    const [category, setCategory] = useState('');
    const handleCategory = (event) => {
      setCategory(event.target.value);
    };


    const handleCancelCategory = () => {
        handleCloseChangeCategoryPopup();
        setCategory('');
      }

      const handleSubmitCategory = () => {
        handleCloseChangeCategoryPopup();
        setCategory('');
        toast.error(`You have clicked on Change category by id = ${data.data.id}!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

  return (
    <div className='invoices-changepopup'>
      <div className='invoices-changepopup-header'>
        <div className='invoices-changepopup-header-title'>
          <p>{t("ACTIONS.CHANGE_RESOURCE", { resource: "category" })}</p>
        </div>

        <div className='invoices-changepopup-header-btn'>
          <IconButton onClick={handleCloseChangeCategoryPopup}>
            <CloseIcon />
          </IconButton>
        </div>

      </div>

      <div className='invoices-changepopup-list'>
        <div className='invoices-changepopup-item flex'>
            <p className='invoices-changepopup-item-title'>
              {t("INVOICE_MANAGER_PAGE.CATEGORY")}
            </p>

            <FormControl fullWidth>
                <Select
                  className="invoices-changepopup-item-select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  onChange={handleCategory}
                >
                  <MenuItem value='category1'>Category 1</MenuItem>
                  <MenuItem value='category2'>Category 2</MenuItem>
                  <MenuItem value='category3'>Category 3</MenuItem>
                  <MenuItem value='category4'>Category 4</MenuItem>
                </Select>
            </FormControl>
        </div>

        <div className='invoices-changepopup-btn'>
          <Button className='invoices-changepopup-btn-reset' onClick={handleCancelCategory}>{t("ACTIONS.CANCEL")}</Button>

          <Button className='invoices-changepopup-btn-submit' onClick={handleSubmitCategory}>{t("ACTIONS.SUBMIT")}</Button>
        </div>
      </div>

    </div>



  );
}


export default ChangeCategoryPopup;
