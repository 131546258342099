import { useQuery } from "@tanstack/react-query";
import { getProjectUsers } from "../api/Api";

const fetchProjectUsers = async (projectId) => {
  const response = await getProjectUsers(projectId, true);
  const { data } = response;

  const formattedData = data?.users?.map(user => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`
  }));

  return formattedData || [];
};

const useProjectUsers = (projectId) => {
  return useQuery({
    queryKey: ["projectUsers", projectId],
    queryFn: () => fetchProjectUsers(projectId),
    enabled: false,
  });
};

export { useProjectUsers };
