export const CalendarColors = [
  '#61AEE6',
  '#01C2A5',
  '#E88DD9',
  '#D2FB9E',
  '#6BA8CB',
  '#61BBA5',
  '#CF89D5',
  '#EF9C80',
  '#8289EE',
  '#74B1A7',
  '#DD89AF',
  '#E48882',
  '#997CEB',
  '#ADD579',
  '#F68896',
  '#FFE999',
  '#6D4BCE',
  '#99CA58',
  '#D352BE',
  '#F7CC34',
  '#DA5C53'
]
