import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./video-conferencing-rightside.scss";
import { useDispatch } from "react-redux";
import { useCurrentProject } from "../../../../../hooks/projects";
import {
  useMeetingRoomHistory,
  useUpcomingMeetings,
} from "../../../../../hooks/meetingRoom.hook";
import { groupBy, orderBy } from "lodash";
import dayjs from "dayjs";
import { stringAvatar } from "../../../../../utils/AvatarUtil";
import {
  SECONDARY_DATE_FORMAT,
} from "../../../../../constants/datetime";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import {
  CalendarTodayOutlined,
  CreateOutlined,
  DeleteOutlineOutlined,
} from "@material-ui/icons";
import {
  ContentCopy,
  Inventory2Outlined,
  VideoCameraBackOutlined,
} from "@mui/icons-material";
import { handleOpenMeetingPageModal } from "../../../../../redux/app/appsModalSlice";
import { useSearchParams } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import {
  createEvent,
  deleteMeetingRoom,
  getCalendars,
  updateMeetingRoom,
} from "../../../../../api/Api";
import EditMeetingForm from "../../edit/edit-form";
import { renderMeetingTime } from "../../../../../utils/TimeUltil";

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(() => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const VideoConferencingRightSide = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let [, setSearchParams] = useSearchParams();

  const [value, setValue] = React.useState(0);
  const [selectedMeetingIndex, setSelectedMeetingIndex] = useState(null);
  const [openDelete, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [toDeleteMeeting, setToDeleteMeeting] = useState(null);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [meetingCalendar, setMeetingCalendar] = useState(null);

  const fetchMeetingCalendar = async () => {
    const response = await getCalendars(currentProject?.id);
    const { data } = response;
    const rmeetingCalendar = data?.data?.calendars?.find(
      (c) => c.text === "Meeting"
    );
    setMeetingCalendar(rmeetingCalendar);
  };

  useEffect(() => {
    if (currentProject) {
      fetchMeetingCalendar();
    }
  }, [currentProject]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setToDeleteMeeting(null);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const ITEM_HEIGHT = 48;
  const options = [
    {
      text: t("VIDEO_CONFERENCING_PAGE.MEET_NOW"),
      icon: <VideoCameraBackOutlined style={{ height: 18, width: 18 }} />,
      action: () => {
        handleClose();
        handleMeetingNow(selectedMeeting);
      },
    },
    {
      text: t("SERVICES.VIDEO_CONFERENCING.COPY_LINK"),
      icon: <ContentCopy style={{ height: 18, width: 18 }} />,
      action: () => {
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_EXTERNAL_MEET_URL}?meeting=${selectedMeeting.roomUid}`
        );
        toast.success(t("VIDEO_CONFERENCING_PAGE.COPIED_EXTERNAL_URL"));
      },
    },
    {
      text: t("SERVICES.VIDEO_CONFERENCING.VIEW_AND_EDIT"),
      icon: <CreateOutlined style={{ height: 18, width: 18 }} />,
      action: () => {
        setViewMode(false);
        setOpenEditModal(true);
      },
    },
    {
      text: t("VIDEO_CONFERENCING_PAGE.ADD_TO_CALENDAR"),
      icon: <CalendarTodayOutlined style={{ height: 18, width: 18 }} />,
      action: async () => {
        try {
          if (selectedMeeting.eventId) {
            toast.info(t("VIDEO_CONFERENCING_PAGE.ALREADY_ADDED_TO_CALENDAR"));
          } else {
            const eventData = {
              text: `Meeting: ${selectedMeeting.roomName}`,
              startDate: selectedMeeting.startAt,
              endDate: selectedMeeting.endAt,
              details: `${
                selectedMeeting.password
                  ? `Password: ${selectedMeeting.password}`
                  : ""
              } \nUrl: ${process.env.REACT_APP_EXTERNAL_MEET_URL}?meeting=${
                selectedMeeting.roomUid
              }`,
              calendar: meetingCalendar,
              color: "#6D4BCE",
              allDay: false,
              projectId: currentProject?.id,
              recurring: selectedMeeting.frecency && selectedMeeting.frecency !== 'ONCE'
                ? selectedMeeting.frecency
                : null,
              roomUid: selectedMeeting.roomUid,
            };
            const response = await createEvent(eventData);
            const { data: resEventData } = response.data;

            await updateMeetingRoom(selectedMeeting.id, {
              eventId: resEventData.event.id,
            });
            await refetchUpcomingMeetings();
            toast.success(t("VIDEO_CONFERENCING_PAGE.ADDED_TO_CALENDAR"));
          }
        } catch (error) {
          toast.error(error.message);
        }
      },
    },
    {
      text: t("VIDEO_CONFERENCING_PAGE.MOVE_TO_HISTORY"),
      icon: <Inventory2Outlined style={{ height: 18, width: 18 }} />,
      action: async () => {
        try {
          await updateMeetingRoom(selectedMeeting.id, { isArchived: true });
          handleClose();
          await refetchMeetingRoomHistory();
          await refetchUpcomingMeetings();
          toast.success(
            t("VIDEO_CONFERENCING_PAGE.MOVED_TO_HISTORY_SUCCESSFULLY")
          );
        } catch (error) {
          toast.error(error.message);
        }
      },
    },
    {
      text: t("ACTIONS.DELETE"),
      icon: <DeleteOutlineOutlined style={{ height: 18, width: 18 }} />,
      action: () => {
        setOpenDeleteModal(true);
        setToDeleteMeeting(selectedMeeting);
      },
    },
  ];

  const historyOptions = [
    {
      text: t("SERVICES.VIDEO_CONFERENCING.VIEW"),
      icon: <CreateOutlined style={{ height: 18, width: 18 }} />,
      action: () => {
        setViewMode(true);
        setOpenEditModal(true);
      },
    },
    {
      text: t("ACTIONS.DELETE"),
      icon: <DeleteOutlineOutlined style={{ height: 18, width: 18 }} />,
      action: () => {
        setOpenDeleteModal(true);
        setToDeleteMeeting(selectedMeeting);
      },
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMeetingIndex(null);
  };

  const { data: currentProject } = useCurrentProject();
  const { data: historyMeetings, refetch: refetchMeetingRoomHistory } =
    useMeetingRoomHistory({
      projectId: currentProject.id,
      to: new Date(dayjs().subtract(1, "day").startOf("day").format()),
    });

  const { data: upcomingMeetings, refetch: refetchUpcomingMeetings } =
    useUpcomingMeetings({
      projectId: currentProject.id,
      from: new Date(dayjs().startOf("day").format()),
    });

  const historyMeetingGroups = useMemo(() => {
    if (historyMeetings?.length) {
      const ordered = orderBy(historyMeetings, "startAt", "desc");
      const groupByDate = groupBy(ordered, (m) => {
        return dayjs(m.startAt).format(SECONDARY_DATE_FORMAT);
      });

      return groupByDate;
    }
    return {};
  }, [historyMeetings]);

  const upcomingMeetingGroups = useMemo(() => {
    if (upcomingMeetings?.length) {
      const ordered = orderBy(upcomingMeetings, "startAt", "asc");
      const groupByDate = groupBy(ordered, (m) => {
        return dayjs(m.startAt).format(SECONDARY_DATE_FORMAT);
      });

      return groupByDate;
    }
    return {};
  }, [upcomingMeetings]);

  const handleMeetingNow = (meeting) => {
    setSearchParams({ meeting: meeting.roomUid });
    dispatch(handleOpenMeetingPageModal());
  };

  const handleDeleteMeetingRoom = async () => {
    try {
      await deleteMeetingRoom(toDeleteMeeting.id);
      await refetchUpcomingMeetings();
      await refetchMeetingRoomHistory();
      setToDeleteMeeting(null);
      handleCloseDeleteModal();
      toast.success(t("VIDEO_CONFERENCING_PAGE.DELETED_SUCCESSFULLY"));
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="video-conferencing_rightside">
      <div className="video-conferencing_rightside-tabs">
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label={t("SERVICES.VIDEO_CONFERENCING.UPCOMING")} />
          <Tab label={t("SERVICES.VIDEO_CONFERENCING.HISTORY")} />
        </Tabs>
      </div>

      <div className="video-conferencing_rightside-tabpanels">
        <TabPanel value={value} index={0} className="meetings-tabpanels">
          {Object.entries(upcomingMeetingGroups).map(
            ([key, meetings], pIndex) => (
              <div className="meeting" key={`upcoming-${key}`}>
                <div className="meeting_time">
                  {key === dayjs().format(SECONDARY_DATE_FORMAT)
                    ? t("COMMON.TODAY")
                    : key}
                </div>

                {meetings.map((meeting, index) => (
                  <div
                    className="meeting_card"
                    style={
                      selectedMeetingIndex === `${pIndex}-${index}`
                        ? {
                            borderWidth: 2,
                            borderColor: "#51A3FF",
                          }
                        : {}
                    }
                  >
                    <div className="meeting_card-icon">
                      <Avatar
                        {...stringAvatar(meeting.roomName)}
                        sx={{ width: 30, height: 30, fontSize: 14, mr: 1 }}
                      />
                    </div>

                    <div className="meeting_card-details">
                      <div className="meeting_card-details-name">
                        {meeting.roomName}
                      </div>
                      <div className="meeting_card-details-date">
                        {renderMeetingTime(meeting)}
                      </div>
                      <div className="meeting_card-details-description">
                        {meeting.description}
                      </div>
                    </div>

                    <div
                      className="meet-now-button"
                      style={
                        selectedMeetingIndex === `${pIndex}-${index}`
                          ? { display: "block" }
                          : {}
                      }
                    >
                      <Button
                        sx={{
                          backgroundColor: "#51A3FF",
                          fontSize: 13,
                          width: 120,
                          borderRadius: 30,
                          height: 35,
                          boxShadow: "none",
                          paddingX: "10px",
                          paddingY: "14px",
                          textTransform: "none",
                          fontWeight: "900 !important",
                        }}
                        variant="contained"
                        onClick={() => handleMeetingNow(meeting)}
                      >
                        {t("VIDEO_CONFERENCING_PAGE.MEET_NOW")}
                      </Button>
                    </div>

                    <div className="meeting_actions">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e);
                          setSelectedMeetingIndex(`${pIndex}-${index}`);
                          setSelectedMeeting(meeting);
                        }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <StyledMenu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock={true}
                        slotProps={{
                          paper: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                              boxShadow: "none",
                            },
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option.text} onClick={option.action}>
                            <ListItemIcon>{option.icon}</ListItemIcon>
                            <ListItemText>{option.text}</ListItemText>
                          </MenuItem>
                        ))}
                      </StyledMenu>
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
        </TabPanel>
        <TabPanel value={value} index={1} className="meetings-tabpanels">
          {Object.entries(historyMeetingGroups).map(
            ([key, meetings], pIndex) => (
              <div className="meeting" key={`history-${key}`}>
                <div className="meeting_time">{key}</div>

                {meetings.map((meeting, index) => (
                  <div
                    className="meeting_card"
                    style={
                      selectedMeetingIndex === `${pIndex}-${index}`
                        ? {
                            borderWidth: 2,
                            borderColor: "#51A3FF",
                          }
                        : {}
                    }
                  >
                    <div className="meeting_card-icon">
                      <Avatar
                        {...stringAvatar(meeting.roomName)}
                        sx={{ width: 30, height: 30, fontSize: 14, mr: 1 }}
                      />
                    </div>

                    <div className="meeting_card-details">
                      <Box className="meeting_card-details-name font-bold">
                        {meeting.roomName}
                      </Box>
                      <Box className="meeting_card-details-date">
                        {renderMeetingTime(meeting)}
                      </Box>
                      <Box className="meeting_card-details-description">
                        {meeting.description}
                      </Box>
                    </div>

                    <div className="meeting_actions">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e);
                          setSelectedMeetingIndex(`${pIndex}-${index}`);
                          setSelectedMeeting(meeting);
                        }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <StyledMenu
                        id="history-actions"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock={true}
                        slotProps={{
                          paper: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                              boxShadow: "none",
                            },
                          },
                        }}
                      >
                        {historyOptions.map((option) => (
                          <MenuItem key={option.text} onClick={option.action}>
                            <ListItemIcon>{option.icon}</ListItemIcon>
                            <ListItemText>{option.text}</ListItemText>
                          </MenuItem>
                        ))}
                      </StyledMenu>
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
        </TabPanel>

        {selectedMeeting && (
          <Modal
            hideBackdrop
            open={openEditModal}
            onClose={handleCloseEditModal}
            className="cloud-page__header_invoice-manager-details_add-modal"
          >
            <EditMeetingForm
              meeting={selectedMeeting}
              handleCloseEditModal={handleCloseEditModal}
              viewMode={viewMode}
            />
          </Modal>
        )}

        <Modal
          hideBackdrop
          open={openDelete}
          onClose={handleCloseDeleteModal}
          className="cloud-page__header_invoice-manager-details_add-modal"
        >
          <Box
            sx={{
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 3,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              sx={{ marginBottom: 3 }}
              className="mb-5"
              variant="h6"
              component="h6"
            >
              {t("VIDEO_CONFERENCING_PAGE.DELETE_CONFIRMATION", {
                name: toDeleteMeeting?.roomName,
              })}
            </Typography>
            <Button
              sx={{ marginRight: 2 }}
              variant="outlined"
              color="inherit"
              onClick={handleCloseDeleteModal}
            >
              {t("ACTIONS.CANCEL")}
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              variant="outlined"
              color="error"
              onClick={() => handleDeleteMeetingRoom()}
            >
              {t("ACTIONS.DELETE")}
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default VideoConferencingRightSide;
