import { useQuery } from "@tanstack/react-query";
import { getMeetingHistory, getUpcomingMeetings } from "../api/Api";

const fetchUpcomingMeetings = async ({ projectId, from }) => {
  const response = await getUpcomingMeetings({
    projectId,
    from,
  });
  const { meetingRooms } = response.data;
  return meetingRooms ?? [];
};

const fetchMeetingHistory = async ({ projectId, to }) => {
  const response = await getMeetingHistory({
    projectId,
    to,
  });
  const { meetingRooms } = response.data;
  return meetingRooms ?? [];
};

const useMeetingRoomHistory = ({ projectId, to }) => {
  return useQuery({
    queryKey: ['meetingHistory', projectId],
    queryFn: () => fetchMeetingHistory({ projectId, to }),
    enabled: !!projectId && !!to,
    staleTime: 5 * 60 * 1000 // 5mins
  })
}

const useUpcomingMeetings = ({ projectId, from }) => {
  return useQuery({
    queryKey: ['upcommingMeetings', projectId],
    queryFn: () => fetchUpcomingMeetings({ projectId, from }),
    enabled: !!projectId && !!from
  })
}

export { useMeetingRoomHistory, useUpcomingMeetings };
