import firstVideoSource from "../../../assets/images/register/first-vid.gif";
import secondVideoSource from "../../../assets/images/register/second-vid.gif";
import finalVideoSource from "../../../assets/images/register/third-vid.gif";
import enterIcon from "../../../assets/images/register/enter.svg";
import driveIcon from "../../../assets/images/my-services/drive.png";
import tasksIcon from "../../../assets/images/my-services/tasks.png";
import invoiceIcon from "../../../assets/images/my-services/invoice-manager.png";
import calendarIcon from "../../../assets/images/my-services/calendar.png";
import notesIcon from "../../../assets/images/my-services/notes.png";
import emailIcon from "../../../assets/images/my-services/email.png";
import videoConferencingIcon from "../../../assets/images/my-services/video-conference.png";
import todosIcon from "../../../assets/images/my-services/todos.png";

import { motion, AnimatePresence } from "framer-motion";
import { TypeAnimation } from "react-type-animation";
import Button from "@mui/material/Button";
import { FormControl } from "@mui/material";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "./register-mobile.scss";
import { useTranslation } from "react-i18next";

const RegisterMobile = ({
  isEnded,
  isAiWritting,
  secondVideoRef,
  verifying,
  verfiyingFirstStep,
  hasVerifyCode,
  setIsAiWritting,
  setShowVerifyingInput,
  showVerifyingInput,
  setHasVerifyCode,
  notRegisteredEmail,
  addShakeClass,
  setEmail,
  email,
  handleRequestAccess,
  setNotRegisteredEmail,
  setAddShakeClass,
  codeIsUsed,
  verifyCodeText,
  emailForWrongCode,
  handleCodeEntry,
  setVerifyCodeText,
  setEmailForWrongCode,
  codeIsCorrect,
  emailForWrongCodeIsEntered,
  setEmailForWrongCodeIsEntered,
  isFirstStep,
  setShowInput,
  submitName,
  name,
  setName,
  isSecondStep,
  handleVerifyEmail,
  isThirdStep,
  company,
  setCompany,
  submitCompany,
  setIsFinalStep,
  isForthStep,
  setSwiper,
  setRedirect,
  setStep,
  isFinalStep,
  finalVideoRef,
}) => {
  const { t } = useTranslation();

  return (
    <div className="register-page-mobile">
      <div className="register-page-mobile__wrapper">
        <AnimatePresence>
          {!isEnded && (
            <motion.div
              key="end"
              initial={{ opacity: 1, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 0.5 }}
              className="register-page-mobile__content"
            >
              <>
                <div className="register-page-mobile__content__quit">
                  <AnimatePresence>
                    <motion.div
                      key="quit"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1, delay: 5 }}
                      className="register-page-mobile__content__quit-container"
                    >
                      <button
                        onClick={() =>
                          (window.location.href = "https://unlyme.com/")
                        }
                      >
                        {t('ACTIONS.QUIT')}
                      </button>
                    </motion.div>
                  </AnimatePresence>
                </div>

                <div className="register-page-mobile__content__motion">
                  <AnimatePresence>
                    {!isFinalStep && isAiWritting && (
                      <motion.div
                        key="firstVideo"
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, transition: { duration: 1.5 } }}
                        transition={{ duration: 1 }}
                        className="register-page-mobile__content__motion-item"
                      >
                        <img src={firstVideoSource} alt="first video" width="400" height="400"></img>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <AnimatePresence>
                    {!isFinalStep && !isAiWritting && (
                      <motion.div
                        key="secondVideo"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, transition: { delay: 1 } }}
                        transition={{ duration: 2 }}
                        className="register-page-mobile__content__motion-item"
                      >
                        <img src={secondVideoSource} alt="second video" width="400" height="400"></img>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <AnimatePresence>
                    <motion.div
                      key="secondVideo"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0, transition: { delay: 1 } }}
                      transition={{ duration: 2 }}
                      className="register-page__content__motion-item"
                      style={{ display: isForthStep ? 'block' : 'none' }}
                    >
                      <img src={finalVideoSource} alt="final video" width="400" height="400"></img>
                    </motion.div>
                  </AnimatePresence>
                </div>
              </>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="register-page-mobile__content__info">
        <AnimatePresence>
          {verifying && verfiyingFirstStep && hasVerifyCode === null && (
            <>
              <motion.div
                key="verifyingFirstText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.WELCOME'),
                    () => {
                      setShowVerifyingInput(true);
                      setIsAiWritting(false);
                    },
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>

              {showVerifyingInput && (
                <motion.div
                  key="firstVerifyingInput"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                  className="register-page-mobile__content__info_form"
                >
                  <div className="register-page-mobile__content__info_form-btns">
                    <Button
                      style={{ width: 125 }}
                      className="register-page-mobile__content__info_form-btns_no"
                      onClick={() => {
                        setHasVerifyCode(false);
                      }}
                    >
                      {t('COMMON.NO')}
                    </Button>
                    <Button
                      className="register-page-mobile__content__info_form-btns_yes"
                      onClick={() => {
                        setHasVerifyCode(true)
                      }}
                    >
                      {t('COMMON.YES')}
                    </Button>
                  </div>
                </motion.div>
              )}
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {verifying && hasVerifyCode && (
            <>
              <motion.div
                key="hasVerifyCodeText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.ENTER_CODE'),

                    () => {
                      setShowVerifyingInput(true);
                      setIsAiWritting(false);
                    },
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>

              <motion.div
                key="verifyingSecondInput"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 4 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
                className="register-page-mobile__content__info_form"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (verifyCodeText.length > 0) {
                      handleCodeEntry(verifyCodeText);
                    }
                  }}
                >
                  <Input
                    className="register-page-mobile__content__info_form-input"
                    type="text"
                    value={verifyCodeText}
                    onChange={(e) => setVerifyCodeText(e.target.value)}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (verifyCodeText.length > 0) {
                            handleCodeEntry(verifyCodeText);
                          }
                        }}
                      >
                        <motion.button
                          className="register-page-mobile__content__info_form-enter"
                          whileHover={{ scale: 1.1 }}
                          transition={{ duration: 0.3 }}
                        >
                          <img src={enterIcon} alt="enter" />
                        </motion.button>
                      </InputAdornment>
                    }
                  />
                </form>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {verifying && hasVerifyCode === false && !notRegisteredEmail && (
            <>
              <motion.div
                key="hasNotVerifyCodeText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.PROVDE_YOUR_EMAIL'),
                    () => {
                      setShowVerifyingInput(true);
                      setIsAiWritting(false);
                    },
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>

              <motion.div
                key="hasNotVerifyCodeInput"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 7 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
                className={`register-page-mobile__content__info_form ${
                  addShakeClass && "shake"
                }`}
              >
                <FormControl>
                  <Input
                    className="register-page-mobile__content__info_form-input"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          let regex =
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                          if (regex.test(email)) {
                            handleRequestAccess();
                            setNotRegisteredEmail(true);
                          } else {
                            setAddShakeClass(true);

                            setTimeout(() => {
                              setAddShakeClass(false);
                            }, 1500);
                          }
                        }}
                      >
                        <motion.button
                          className="register-page-mobile__content__info_form-enter"
                          whileHover={{ scale: 1.1 }}
                          transition={{ duration: 0.3 }}
                        >
                          <img src={enterIcon} alt="enter" />
                        </motion.button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {verifying && hasVerifyCode === false && notRegisteredEmail && (
            <>
              <motion.div
                key="hasNotVerifyCodeEmailText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.THANK_YOU_FOR_YOUR_INTEREST'),
                    () => {
                      setIsAiWritting(false);
                    },
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying &&
            codeIsUsed &&
            isEnded === false &&
            !emailForWrongCode && (
              <>
                <motion.div
                  key="incorrectCodeText"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <TypeAnimation
                    className="register-page-mobile__content__info_text"
                    style={{ whiteSpace: "pre-line" }}
                    sequence={[
                      () => {
                        setIsAiWritting(true);
                      },
                      2000,
                      t('REGISTER.CODE_ALREADY_USED'),

                      () => {
                        setShowVerifyingInput(true);
                        setIsAiWritting(false);
                      },
                    ]}
                    wrapper="p"
                    speed={60}
                    cursor={false}
                    repeat={false}
                  />
                </motion.div>

                <motion.div
                  key="provideEmailButton"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 9 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                  className="register-page-mobile__content__info_form"
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (verifyCodeText.length > 0) {
                        handleCodeEntry(verifyCodeText);
                      }
                    }}
                  >
                    <Input
                      className="register-page-mobile__content__info_form-input"
                      type="text"
                      value={verifyCodeText}
                      onChange={(e) => setVerifyCodeText(e.target.value)}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            if (verifyCodeText.length > 0) {
                              handleCodeEntry(verifyCodeText);
                            }
                          }}
                        >
                          <motion.button
                            className="register-page-mobile__content__info_form-enter"
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: 0.3 }}
                          >
                            <img src={enterIcon} alt="enter" />
                          </motion.button>
                        </InputAdornment>
                      }
                    />
                  </form>

                  <div className="register-page-mobile__content__info_form-btns">
                    <Button
                      className="register-page-mobile__content__info_form-btns_yes"
                      onClick={() => {
                        setEmailForWrongCode(true);
                      }}
                    >
                      {t('REGISTER.PROVIDE_AN_EMAIL')}
                    </Button>
                  </div>
                </motion.div>
              </>
            )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying &&
            !codeIsUsed &&
            !codeIsCorrect &&
            isEnded === false &&
            !emailForWrongCode && (
              <>
                <motion.div
                  key="incorrectCodeText"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <TypeAnimation
                    className="register-page-mobile__content__info_text"
                    style={{ whiteSpace: "pre-line" }}
                    sequence={[
                      () => {
                        setIsAiWritting(true);
                      },
                      2000,
                      t('REGISTER.ENTERED_WRONG_CODE'),
                      () => {
                        setShowVerifyingInput(true);
                        setIsAiWritting(false);
                      },
                    ]}
                    wrapper="p"
                    speed={60}
                    cursor={false}
                    repeat={false}
                  />
                </motion.div>

                <motion.div
                  key="provideEmailButton"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 9 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                  className="register-page-mobile__content__info_form"
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (verifyCodeText.length > 0) {
                        handleCodeEntry(verifyCodeText);
                      }
                    }}
                  >
                    <Input
                      className={`register-page-mobile__content__info_form-input ${
                        addShakeClass && "shake"
                      }`}
                      type="text"
                      value={verifyCodeText}
                      onChange={(e) => setVerifyCodeText(e.target.value)}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            if (verifyCodeText.length > 0) {
                              handleCodeEntry(verifyCodeText);
                            }
                          }}
                        >
                          <motion.button
                            className="register-page-mobile__content__info_form-enter"
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: 0.3 }}
                          >
                            <img src={enterIcon} alt="enter" />
                          </motion.button>
                        </InputAdornment>
                      }
                    />
                  </form>

                  <div className="register-page-mobile__content__info_form-btns">
                    <Button
                      className="register-page-mobile__content__info_form-btns_yes"
                      onClick={() => {
                        setEmailForWrongCode(true);
                      }}
                    >
                      {t('REGISTER.PROVIDE_AN_EMAIL')}
                    </Button>
                  </div>
                </motion.div>
              </>
            )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying &&
            !codeIsCorrect &&
            isEnded === false &&
            emailForWrongCode &&
            !emailForWrongCodeIsEntered && (
              <>
                <motion.div
                  key="provideEmailText"
                  initial={{ opacity: 0.5 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 2 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <TypeAnimation
                    className="register-page-mobile__content__info_text"
                    style={{ whiteSpace: "pre-line" }}
                    sequence={[
                      () => {
                        setIsAiWritting(true);
                      },
                      2000,
                      t('REGISTER.ENTER_EMAIL'),

                      () => {
                        setShowVerifyingInput(true);
                        setIsAiWritting(false);
                      },
                    ]}
                    wrapper="p"
                    speed={60}
                    cursor={false}
                    repeat={false}
                  />
                </motion.div>

                <motion.div
                  key="provideEmailInput"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 4 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                  className={`register-page-mobile__content__info_form  ${
                    addShakeClass && "shake"
                  }`}
                >
                  <FormControl>
                    <Input
                      className="register-page-mobile__content__info_form-input"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            let regex =
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                            if (regex.test(email)) {
                              handleRequestAccess();
                              setEmailForWrongCodeIsEntered(true);
                            } else {
                              setAddShakeClass(true);

                              setTimeout(() => {
                                setAddShakeClass(false);
                              }, 1500);
                            }
                          }}
                        >
                          <motion.button
                            className="register-page-mobile__content__info_form-enter"
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: 0.3 }}
                          >
                            <img src={enterIcon} alt="enter" />
                          </motion.button>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </motion.div>
              </>
            )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying &&
            !codeIsCorrect &&
            isEnded === false &&
            emailForWrongCodeIsEntered && (
              <>
                <motion.div
                  key="emailForWrongCodeIsEnteredText"
                  initial={{ opacity: 0.5 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 2 }}
                  exit={{ opacity: 0, transition: { duration: 1 } }}
                >
                  <TypeAnimation
                    className="register-page-mobile__content__info_text"
                    style={{ whiteSpace: "pre-line" }}
                    sequence={[
                      () => {
                        setIsAiWritting(true);
                      },
                      2000,
                      t('REGISTER.THANK_YOU_FOR_YOUR_INTEREST'),

                      () => {
                        setIsAiWritting(false);
                      },
                    ]}
                    wrapper="p"
                    speed={60}
                    cursor={false}
                    repeat={false}
                  />
                </motion.div>
              </>
            )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying && codeIsCorrect && isEnded === false && isFirstStep && (
            <>
              <motion.div
                key="firstText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.WHAT_IS_YOUR_NAME'),

                    () => {
                      setShowInput(true);
                      setIsAiWritting(false);
                    },
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>

              <motion.div
                key="firstInput"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 4 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
                className="register-page-mobile__content__info_form"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (name.length > 0) {
                      submitName();
                    }
                  }}
                >
                  <Input
                    className="register-page-mobile__content__info_form-input"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (name.length > 0) {
                            submitName();
                          }
                        }}
                      >
                        <motion.button
                          className="register-page-mobile__content__info_form-enter"
                          whileHover={{ scale: 1.1 }}
                          transition={{ duration: 0.3 }}
                        >
                          <img src={enterIcon} alt="enter" />
                        </motion.button>
                      </InputAdornment>
                    }
                  />
                </form>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying && codeIsCorrect && isEnded === false && isSecondStep && (
            <>
              <motion.div
                key="secondText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.ENTER_EMAIL_WITH_NAME', { name: name }),

                    () => {
                      setShowInput(true);
                      setIsAiWritting(false);
                    },
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>

              <motion.div
                key="secondInput"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 4 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
                className={`register-page-mobile__content__info_form  ${
                  addShakeClass && "shake"
                }`}
              >
                <FormControl>
                  <Input
                    className="register-page-mobile__content__info_form-input"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        onClick={handleVerifyEmail}
                      >
                        <motion.button
                          className="register-page-mobile__content__info_form-enter"
                          whileHover={{ scale: 1.1 }}
                          transition={{ duration: 0.3 }}
                        >
                          <img src={enterIcon} alt="enter" />
                        </motion.button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying && codeIsCorrect && isEnded === false && isThirdStep && (
            <>
              <motion.div
                key="thirdText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.WHAT_IS_PROJECT_NAME'),

                    () => {
                      setShowInput(true);
                      setIsAiWritting(false);
                    },
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>

              <motion.div
                key="thirdInput"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 4 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
                className="register-page-mobile__content__info_form"
              >
                <form onSubmit={submitCompany}>
                  <FormControl>
                    <Input
                      className="register-page-mobile__content__info_form-input"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end" onClick={submitCompany}>
                          <motion.button
                            className="register-page-mobile__content__info_form-enter"
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: 0.3 }}
                          >
                            <img src={enterIcon} alt="enter" />
                          </motion.button>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </form>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!verifying && codeIsCorrect && isEnded === false && isForthStep && (
            <>
              <motion.div
                key="forthText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <TypeAnimation
                  className="register-page-mobile__content__info_text"
                  style={{ whiteSpace: "pre-line" }}
                  sequence={[
                    () => {
                      setIsAiWritting(true);
                    },
                    2000,
                    t('REGISTER.CREATING_WORKSPACE', { name: name }),

                    () => {
                      setIsAiWritting(false);
                      setIsFinalStep(true);
                      setRedirect(true);
                    },

                    setTimeout(() => {
                      setStep(true);
                    }, 10000),
                  ]}
                  wrapper="p"
                  speed={60}
                  cursor={false}
                  repeat={false}
                />
              </motion.div>

              {!isAiWritting && (
                <>
                  <motion.div
                    key="line"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                  >
                    <div className="register-page-mobile__content__info_line">
                      <p></p>
                    </div>
                  </motion.div>

                  <motion.div
                    key="icons"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1.5 }}
                  >
                    <Swiper
                      onSwiper={setSwiper}
                      watchSlidesProgress={false}
                      slidesPerView={3}
                      // spaceBetween={20}
                      className="mySwiper register-page-mobile__content__info_list"
                      centeredSlides={true}
                      // loop={true}
                      // rewind={true}
                      autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                    >
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={calendarIcon} alt="calendar" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={notesIcon} alt="notes" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={emailIcon} alt="emails" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={driveIcon} alt="drive" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={tasksIcon} alt="tasks" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img
                          src={videoConferencingIcon}
                          alt="vodeo conferences"
                        />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={invoiceIcon} alt="invoice" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={todosIcon} alt="todos" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={calendarIcon} alt="calendar" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={notesIcon} alt="notes" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={emailIcon} alt="emails" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={driveIcon} alt="drive" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={tasksIcon} alt="tasks" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img
                          src={videoConferencingIcon}
                          alt="vodeo conferences"
                        />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={invoiceIcon} alt="invoice" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={todosIcon} alt="todos" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={calendarIcon} alt="calendar" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={notesIcon} alt="notes" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={emailIcon} alt="emails" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={driveIcon} alt="drive" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={tasksIcon} alt="tasks" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img
                          src={videoConferencingIcon}
                          alt="vodeo conferences"
                        />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={invoiceIcon} alt="invoice" />
                      </SwiperSlide>
                      <SwiperSlide className="register-page-mobile__content__info_list-item">
                        <img src={todosIcon} alt="todos" />
                      </SwiperSlide>
                    </Swiper>
                  </motion.div>
                </>
              )}
            </>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default RegisterMobile;
