import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import List from "./components/List";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash";

import InputContainer from "./components/InputContainer";
import { toast } from "react-toastify";
import store from "./utils/store";
import StoreApi from "./utils/storeApi";
import initData from "./utils/initTask";
import { useCurrentProject } from "../../../hooks/projects";
import {
  createTask,
  updateTask,
  useCurrentTask,
} from "../../../hooks/tasks.hook";
import { useProjectUsers } from "../../../hooks/users.hook";
import { useCurrentUser } from "../../../hooks/auth.hook";
import { useDebounce } from "../../../utils/TimmerDebounce";

const TasksContent = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  const { data: currentProject } = useCurrentProject();
  const { data: currentTask, refetch: refetchTask } = useCurrentTask(
    currentProject.id
  );
  const { data: currentUser } = useCurrentUser();
  const { refetch: refetchUsers } = useProjectUsers(currentProject.id);

  const [data, setData] = useState(null);
  const debouncedData = useDebounce(data, 500);

  useEffect(() => {
    if (currentProject) {
      refetchTask();
      refetchUsers();
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentProject && currentTask === null) {
      createTask(currentProject.id, initData);
      setTimeout(() => {
        refetchTask();
      }, [2000])
    }
    if (currentProject && currentTask) {
      setData(currentTask.task);
    }
  }, [currentTask]);

  const updateTaskCallback = useCallback(async () => {
      await updateTask(currentTask.id, debouncedData);
  }, [debouncedData]);

  useEffect(() => {
    if (debouncedData && currentProject && currentTask) {
      updateTaskCallback();
    }
  }, [debouncedData, updateTaskCallback]);

  const addMoreCard = (
    title,
    listId,
    description,
    comments,
    checklist,
    tags,
    members,
    color,
    files,
    imageFile
  ) => {
    if (!title) {
      toast.error("Please write a title!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const newCardId = uuid();
    const newCard = {
      id: newCardId,
      title,
      description,
      comments,
      checklist,
      tags,
      members,
      color,
      // files,
      // imageFile
    };

    const list = data.lists[listId];
    list.cards = [...list.cards, newCard];

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const removeCard = (index, listId) => {
    const list = data.lists[listId];

    list.cards.splice(index, 1);

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateCard = (
    title,
    description,
    tags,
    members,
    checklist,
    color,
    index,
    listId,
    imageFile
  ) => {
    const list = data.lists[listId];
    list.cards[index].title = title;
    list.cards[index].description = description;
    list.cards[index].tags = tags;
    list.cards[index].members = members;
    list.cards[index].checklist = checklist;
    list.cards[index].color = color;
    list.cards[index].imageFile = imageFile;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateCardDescription = (description, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].description = description;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateCardTitle = (title, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].title = title;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateCardColor = (color, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].color = color;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateCardMembers = (members, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].members = members;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateCardTags = (tags, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].tags = tags;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateTags = (newTags) => {
    setData({
      ...data,
      tags: newTags
    });
  }

  const updateCardImageUploaded = (imageFile, dataURL, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].files = {
      imageFile: imageFile,
      dataURL: dataURL,
    };

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const removeCardImageUploaded = (index, listId) => {
    const list = data.lists[listId];
    list.cards[index].files = null;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };

    setData(newState);
  };

  const addMoreList = (title) => {
    if (!title) {
      return;
    }

    const newListId = uuid();
    const newList = {
      id: newListId,
      title,
      cards: [],
    };
    const newState = {
      listIds: [...data.listIds, newListId],
      lists: {
        ...data.lists,
        [newListId]: newList,
      },
    };
    setData(newState);
  };

  const updateListTitle = (title, listId) => {
    const list = data.lists[listId];
    list.title = title;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };

    setData(newState);
  };

  const deleteList = (listId) => {
    const lists = data.lists;
    const listIds = data.listIds;

    delete lists[listId];

    listIds.splice(listIds.indexOf(listId), 1);

    const newState = {
      lists: lists,
      listIds: listIds,
    };

    setData(newState);
  };

  const addComment = (
    card,
    commentText,
    index,
    listId,
    day,
    month,
    year,
    time,
    file
  ) => {
    if (!commentText) {
      toast.error("Please write comment", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    console.log("file", file);

    const list = data.lists[listId];

    const newCard = list.cards[index];

    const comment = {
      id: uuid(),
      text: commentText,
      day: day,
      month: month,
      year: year,
      time: time,
      file: file,
      user: {
        id: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
      },
    };

    // if(newCard.comments) {
    //   newCard.comments.push(comment);
    // } else {
    //   newCard.comments=[];
    newCard.comments.push(comment);
    // }

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };

    setData(newState);
  };

  const updateCardCommentImageUploaded = (
    imageFile,
    dataURL,
    index,
    listId,
    commentId
  ) => {
    const list = data.lists[listId];

    const mComment = list.cards[index].comments.find(
      (comment) => comment.id === commentId
    )
    if (mComment) {
      mComment.file = {
        imageFile: imageFile,
        dataURL: dataURL,
      };

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };
      setData(newState);
    }
  };

  const removeCommentImageUploaded = (index, listId, commentId) => {
    const list = data.lists[listId];

    const listComments = list.cards[index].comments.filter(
      (comment) => comment.id === commentId
    )

    if (listComments && listComments.lenght > 0) {
      listComments[0].file = null;

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };

      setData(newState);
    }
  };

  const removeComment = (commentId, index, listId) => {
    const list = data.lists[listId];
    const card = list.cards[index];
    const commentIndex = card.comments.findIndex(
      (comment) => comment.id === commentId
    );

    if (commentIndex !== -1) {
      card.comments.splice(commentIndex, 1);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };

      setData(newState);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (type === "list") {
      const newListIds = data.listIds;

      newListIds.splice(source.index, 1);
      newListIds.splice(destination.index, 0, draggableId);

      const newState = {
        ...data,
        listIds: newListIds,
      };
      setData(newState);

      return;
    }

    const sourceList = data.lists[source.droppableId];
    const destinationList = data.lists[destination.droppableId];
    const draggingCard = sourceList.cards.filter(
      (card) => card.id === draggableId
    )[0];

    if (source.droppableId === destination.droppableId) {
      sourceList.cards.splice(source.index, 1);
      destinationList.cards.splice(destination.index, 0, draggingCard);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [sourceList.id]: destinationList,
        },
      };
      setData(newState);
    } else {
      sourceList.cards.splice(source.index, 1);
      destinationList.cards.splice(destination.index, 0, draggingCard);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [sourceList.id]: sourceList,
          [destinationList.id]: destinationList,
        },
      };

      setData(newState);
    }
  };

  const addChecklist = (card, newChecklist, index, listId) => {
    const list = data.lists[listId];

    const newCard = list.cards[index];

    const checklist = {
      id: newChecklist.id,
      title: newChecklist.title,
      lists: newChecklist.lists,
    };

    newCard.checklist.push(checklist);

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };

    setData(newState);
  };

  const addChecklistItem = (card, checklistItemText, index, listId) => {
    const list = data.lists[listId];
    const newChecklist = card.checklist[index];

    const newItem = {
      id: uuid(),
      title: checklistItemText,
      checked: false,
    };

    newChecklist.lists.push(newItem);

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
  };

  const handleRemoveChecklist = (card, checklistId, listId) => {
    const list = data.lists[listId];

    const checklistIndex = card.checklist.findIndex(
      (checklist) => checklist.id === checklistId
    );

    if (checklistIndex !== -1) {
      card.checklist.splice(checklistIndex, 1);

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };

      setData(newState);
    }
  };

  const updateCardChecklistItems = (checklist, checklistItem, listId) => {
    const list = data.lists[listId];

    const checklistItemIndex = checklist.lists.findIndex(
      (item) => item.id === checklistItem.id
    );

    if (checklistItemIndex !== -1) {
      checklist.lists[checklistItemIndex].checked =
        !checklist.lists[checklistItemIndex].checked;

      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };

      setData(newState);
    }
  };

  if (!data) {
    return <></>;
  }

  return (
    <div
      className={`tasks-page  ${
        firstPopupTab ? "tasks-page-zoomout-one" : ""
      } ${secondPopupTab ? "tasks-page-zoomout-two" : ""}`}
    >
      <Grid container spacing={3}>
        <Grid
          item
          // lg={secondPopupTab ? 12 : 9}
          // md={secondPopupTab ? 12 : 9}
          xs={12}
        >
          <div className="tasks-page_main">
            <div className="tasks-page_main_list">
              <StoreApi.Provider
                value={{
                  addMoreCard,
                  addMoreList,
                  updateListTitle,
                  removeCard,
                  updateCard,
                  updateCardDescription,
                  deleteList,
                  addComment,
                  updateCardTitle,
                  updateCardColor,
                  updateCardMembers,
                  updateCardTags,
                  updateCardImageUploaded,
                  removeCardImageUploaded,
                  removeComment,
                  addChecklist,
                  addChecklistItem,
                  handleRemoveChecklist,
                  updateCardChecklistItems,
                  updateCardCommentImageUploaded,
                  removeCommentImageUploaded,
                  updateTags
                }}
              >
                <div className="tasks-page_main_list-actions">
                  {/* <InputContainer type="list" /> */}
                  {/* <InputContainer  listId='list-1' type="card" /> */}
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="tasks"
                    type="list"
                    direction="horizontal"
                  >
                    {(provided, snapshot) => (
                      <div
                        className="wrapper"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        // style={{
                        //   background: snapshot.isDraggingOver
                        //     ? "lightblue"
                        //     : "lightgrey",
                        // }}
                      >
                        {data.listIds.map((listId, index) => {
                          const list = data.lists[listId];

                          return (
                            <List
                              list={list}
                              key={listId}
                              index={index}
                              data={data}
                            />
                          );
                        })}

                        <div>
                          <InputContainer type="list" />
                        </div>

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </StoreApi.Provider>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TasksContent;
