import { useQuery } from "@tanstack/react-query";
import { getEvents } from "../api/Api";

const STALE_TIME = 60 * 60 * 1000; // 1hr

const fetchEvents = async (projectId) => {
  const response = await getEvents({ projectId });
  const { data } = response.data;
  return data?.events ?? [];
}

const useEvents = (projectId) => {
  return useQuery({
    queryKey: ['events', projectId],
    queryFn: () => fetchEvents(projectId),
    enabled: !!projectId,
    staleTime: STALE_TIME
  })
}

export { useEvents }
