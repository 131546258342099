import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import storeTasks from "../../../tasks/utils/store";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import plusIcon from "../../../../../assets/images/my-services/plus.svg";
import expandIcon from "../../../../../assets/images/my-services/expand.svg";
import calendarIcon from "../../../../../assets/images/my-services/videoconference/calendar.svg";
import meetIcon from "../../../../../assets/images/my-services/videoconference/video.svg";
import copyIcon from "../../../../../assets/images/my-services/videoconference/copy.svg";
import editIcon from "../../../../../assets/images/my-services/videoconference/edit-3.svg";
import calendarGrayIcon from "../../../../../assets/images/my-services/videoconference/calendar-gray.svg";
import archiveIcon from "../../../../../assets/images/my-services/videoconference/archive.svg";
import deleteIcon from "../../../../../assets/images/my-services/videoconference/trash.svg";
import emptyListIcon from "../../../../../assets/images/my-services/videoconference/emptylist.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelector, useDispatch } from "react-redux";
import {
  handleOpenEmailsWidgetModal,
  handleCloseEmailsWidgetModal,
  handleOpenMeetingPageModal,
  handleCloseMeetingPageModal,
  handleOpenVideoConferencing,
} from "../../../../../redux/app/appsModalSlice";

import TasksPage from "../../../tasks/TasksPage";
import { Box, Button, Typography } from "@mui/material";
import { useCurrentProject } from "../../../../../hooks/projects";
import {
  useMeetingRoomHistory,
  useUpcomingMeetings,
} from "../../../../../hooks/meetingRoom.hook";
import dayjs from "dayjs";
import { stringAvatar } from "../../../../../utils/AvatarUtil";
import { renderMeetingTime } from "../../../../../utils/TimeUltil";
import { useSearchParams } from "react-router-dom";
import MeetingPage from "../../../video-conferencing/meeting-page/MeetingPage";
import { toast } from "react-toastify";
import { createEvent, deleteMeetingRoom, updateMeetingRoom } from "../../../../../api/Api";
import VideoConferencingPage from "../../../video-conferencing/main-page/VideoConferencingPage";
import { openNewMeeting } from "../../../../../redux/app/popupSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const VideoConferencingBlock = () => {
  const { data: currentProject } = useCurrentProject();
  const { data: upcomingMeetings, refetch: refetchUpcomingMeetings } =
    useUpcomingMeetings({
      projectId: currentProject?.id,
      from: new Date(dayjs().startOf("day").format()),
    });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openMeetingPageModal = useSelector(
    (state) => state.appsModal.openMeetingPageModal
  );

  const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  const appsModal = useSelector((state) => state.appsModal.openAppsModal);

  const todayMeetings = useMemo(() => {
    if (upcomingMeetings && upcomingMeetings.length) {
      return upcomingMeetings.filter((um) =>
        dayjs().isSame(um.startAt, "date")
      );
    }
    return [];
  }, [upcomingMeetings]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  let [, setSearchParams] = useSearchParams();
  const [openDelete, setOpenDeleteModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [toDeleteMeeting, setToDeleteMeeting] = useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMeetingNow = (meeting) => {
    setSearchParams({ meeting: meeting.roomUid });
    dispatch(handleOpenMeetingPageModal());
  };

  const handleOpenMeetingModal = () => {
    setSelectedMeeting(null);
    dispatch(handleOpenVideoConferencing());
  }

  const handleOpenMeetNow = () => {
    setSelectedMeeting(null);
    dispatch(openNewMeeting(true));
    setSearchParams({ action: 'meet-now' })
    dispatch(handleOpenVideoConferencing());
  }

  const handleOnClickMeetingNow = () => {
    handleClose();
    handleMeetingNow(selectedMeeting);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_EXTERNAL_MEET_URL}?meeting=${selectedMeeting.roomUid}`
    );
    toast.success(t("VIDEO_CONFERENCING_PAGE.COPIED_EXTERNAL_URL"));
  };

  const handleAddToCalendar = async () => {
    try {
      if (selectedMeeting.eventId) {
        toast.info(t("VIDEO_CONFERENCING_PAGE.ALREADY_ADDED_TO_CALENDAR"));
      } else {
        const eventData = {
          text: `Meeting: ${selectedMeeting.roomName}`,
          startDate: selectedMeeting.startAt,
          endDate: selectedMeeting.endAt,
          details: `${
            selectedMeeting.password
              ? `Password: ${selectedMeeting.password}`
              : ""
          } \nUrl: ${process.env.REACT_APP_EXTERNAL_MEET_URL}?meeting=${
            selectedMeeting.roomUid
          }`,
          calendar: "meeting",
          color: "#6D4BCE",
          allDay: false,
          projectId: currentProject?.id,
          recurring: selectedMeeting.frecency ? selectedMeeting.frecency : null,
          roomUid: selectedMeeting.roomUid,
        };
        const response = await createEvent(eventData);
        const { data: resEventData } = response.data;

        await updateMeetingRoom(selectedMeeting.id, {
          eventId: resEventData.event.id,
        });
        await refetchUpcomingMeetings();
        toast.success(t("VIDEO_CONFERENCING_PAGE.ADDED_TO_CALENDAR"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleArchive = async () => {
    try {
      await updateMeetingRoom(selectedMeeting.id, { isArchived: true });
      handleClose();
      await refetchUpcomingMeetings();
      toast.success(t("VIDEO_CONFERENCING_PAGE.MOVED_TO_HISTORY_SUCCESSFULLY"));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOnClickDelete = () => {
    setOpenDeleteModal(true);
    setToDeleteMeeting(selectedMeeting);
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setToDeleteMeeting(null);
  };

  const handleDeleteMeetingRoom = async () => {
    try {
      await deleteMeetingRoom(toDeleteMeeting.id);
      await refetchUpcomingMeetings();
      setToDeleteMeeting(null);
      handleCloseDeleteModal();
      toast.success(t("VIDEO_CONFERENCING_PAGE.DELETED_SUCCESSFULLY"));
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div
        className={`my-services__video-conference
        ${
          // openNotesWidgetModal ||
          appsModal
            ? // openCalendarWidgetModal ||
              // openTasksWidgetModal ||
              // openWorkDriveWidgetModal
              "back-transparent"
            : ""
        }`}
      >
        <div className="my-services__video-conference_header">
          <div className="my-services__video-conference_header-view">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="my-services__video-conference_header-view_tab"
              // variant="scrollable"
              // scrollButtons="auto"
              allowScrollButtonsMobile
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              <Tab
                label={
                  <p>
                    {t("SERVICES.VIDEO_CONFERENCING.UPCOMMING_TODAY")}{" "}
                    <span className="unread-num">
                      {upcomingMeetings?.length}
                    </span>
                  </p>
                }
              />
              {/* <Tab label='Sent' />
              <Tab label='Trash' /> */}
            </Tabs>
          </div>

          <div className="react-grid-dragHandleArea"></div>

          <div className="my-services__video-conference_header-more">
            <IconButton aria-label="calendar">
              <img src={calendarIcon} />
            </IconButton>

            <IconButton aria-label="add" onClick={handleOpenMeetNow}>
              <img src={plusIcon} />
            </IconButton>

            <IconButton aria-label="expand" onClick={handleOpenMeetingModal}>
              <img src={expandIcon} />
            </IconButton>
          </div>
        </div>

        <div className="my-services__video-conference_content">
          <TabPanel
            value={value}
            index={0}
            className="my-services__video-conference_content-list"
          >
            {todayMeetings?.length > 0 &&
              todayMeetings?.map((meeting) => (
                <div
                  className="my-services__video-conference_content-item"
                  key={meeting.id}
                >
                  <div className="my-services__video-conference_content-item_icon">
                    <Avatar {...stringAvatar(meeting.roomName)}></Avatar>
                  </div>

                  <div className="my-services__video-conference_content-item_details">
                    <div className="my-services__video-conference_content-item_details-name">
                      {meeting.roomName}
                    </div>

                    <div className="my-services__video-conference_content-item_details-info">
                      <div className="my-services__video-conference_content-item_details-info-date">
                        {renderMeetingTime(meeting, true)}
                      </div>
                      <div className="my-services__video-conference_content-item_details-info-description">
                        {meeting.description}
                      </div>
                    </div>
                  </div>

                  <div className="my-services__video-conference_content-item_meet">
                    <Button
                      onClick={() => handleMeetingNow(meeting)}
                      className="my-services__video-conference_content-item_meet-btn"
                    >
                      {t("VIDEO_CONFERENCING_PAGE.MEET_NOW")}
                    </Button>
                  </div>

                  <div className="my-services__video-conference_content-item_actions">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClick(e);
                        setSelectedMeeting(meeting);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </div>
                </div>
              ))}

            {!todayMeetings?.length > 0 && (
              <div className="my-services__video-conference_content-emptylist">
                <div className="my-services__video-conference_content-emptylist-img">
                  <img src={emptyListIcon} alt="empty list" />
                </div>

                <p className="my-services__video-conference_content-emptylist-text">
                  {t("SERVICES.VIDEO_CONFERENCING.NO_TODAY_MEETINGS")}
                </p>
              </div>
            )}
          </TabPanel>
        </div>
      </div>

      <Menu
        // id="long-menu"
        className="videoconferenceblock-menu-popup"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
      >
        <MenuItem onClick={handleOnClickMeetingNow}>
          <Button variant="outlined" startIcon={<img src={meetIcon} />}>
            {t("VIDEO_CONFERENCING_PAGE.MEET_NOW")}
          </Button>
        </MenuItem>

        <MenuItem onClick={handleCopyLink}>
          <Button variant="outlined" startIcon={<img src={copyIcon} />}>
          {t("SERVICES.VIDEO_CONFERENCING.COPY_LINK")}
          </Button>
        </MenuItem>

        {/* <MenuItem
          onClick={handleClose}
        >
          <Button variant="outlined" startIcon={<img src={editIcon} />}>
            View / Edit
          </Button>
        </MenuItem> */}

        <MenuItem onClick={handleAddToCalendar}>
          <Button variant="outlined" startIcon={<img src={calendarGrayIcon} />}>
            {t("VIDEO_CONFERENCING_PAGE.ADD_TO_CALENDAR")}
          </Button>
        </MenuItem>

        <MenuItem onClick={handleArchive}>
          <Button variant="outlined" startIcon={<img src={archiveIcon} />}>
            {t("ACTIONS.ARCHIVE")}
          </Button>
        </MenuItem>

        <MenuItem onClick={handleOnClickDelete}>
          <Button variant="outlined" startIcon={<img src={deleteIcon} />}>
            {t("ACTIONS.DELETE")}
          </Button>
        </MenuItem>
      </Menu>

      <Modal
        open={openMeetingPageModal}
        onClose={handleCloseMeetingPageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`meeting-page-modal ${
          firstPopupTab ? "meeting-page-modal-shift-one" : ""
        } ${secondPopupTab ? "meeting-page-modal-shift-two" : ""}`}
        disableEnforceFocus
      >
        <div className="meeting-page-modal-container">
          <MeetingPage conferenceName={""} />
        </div>
      </Modal>

      <Modal
        hideBackdrop
        open={openDelete}
        onClose={handleCloseDeleteModal}
        className="cloud-page__header_invoice-manager-details_add-modal"
      >
        <Box
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 3,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ marginBottom: 3 }}
            className="mb-5"
            variant="h6"
            component="h6"
          >
            {t("VIDEO_CONFERENCING_PAGE.DELETE_CONFIRMATION", {
              name: toDeleteMeeting?.roomName,
            })}
          </Typography>
          <Button
            sx={{ marginRight: 2 }}
            variant="outlined"
            color="inherit"
            onClick={handleCloseDeleteModal}
          >
            {t("ACTIONS.CANCEL")}
          </Button>
          <Button
            sx={{ marginRight: 2 }}
            variant="outlined"
            color="error"
            onClick={() => handleDeleteMeetingRoom()}
          >
            {t("ACTIONS.DELETE")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default VideoConferencingBlock;
