import React, { useState } from 'react';
import './clients-popups.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';






const ClientsRecordPopup = ({ handleCloseClientsRecordPopup, data}) => {
  const { t } = useTranslation();

  return (
    <div className='clients-invoicerecordpopup'>
      <div className='clients-invoicerecordpopup-header'>
        <div className='clients-invoicerecordpopup-header-title'>
          <p>{t("INVOICE_MANAGER_PAGE.CLIENTS_RECORD")}</p>
        </div>

        <div className='clients-invoicerecordpopup-header-btn'>
          <IconButton onClick={handleCloseClientsRecordPopup}>
            <CloseIcon />
          </IconButton>
        </div>

      </div>

      <div className='clients-invoicerecordpopup-list'>
        <table className='clients-invoicerecordpopup-table'>
            <tbody className='clients-invoicerecordpopup-table-body'>
                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.COMPANY_ID")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>#{data.id}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.COMPANY_NAME")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.company}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.ACCOUNT_OWNER")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.owner}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.EMAIL")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.email}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.CATEGORY")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.category}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.PROJECTS")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.project}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.HEAD_COLUMNS.DESCRIPTION")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.description}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.BILLING_ADDRESS")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.billing}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.APP_MODULES")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.modules}</td>
                </tr>

                <tr className='clients-invoicerecordpopup-table-body-tr'>
                    <td className='clients-invoicerecordpopup-table-body-tr-title'>{t("INVOICE_MANAGER_PAGE.MORE_INFORMATION")}</td>
                    <td className='clients-invoicerecordpopup-table-body-tr-value'>{data.info}</td>
                </tr>
            </tbody>
        </table>
      </div>

    </div>



  );
}


export default ClientsRecordPopup;
