import { useQuery } from "@tanstack/react-query";
import { listNotes, createNote as createNoteApi } from "../api/Api";
import moment from "moment";

const fetchNotes = async (projectId) => {
  const response = await listNotes(projectId);
  const { notes } = response.data;

  const formatData = notes?.map(p => {
    const updatedAt = new Date(p.updatedAt);
    return {
      ...p,
      members: p.members || [],
      day: updatedAt.getDay(),
      month: updatedAt.getMonth(),
      year: updatedAt.getFullYear(),
      time: moment(updatedAt).format('HH:mm')
    }
  })

  return formatData ?? [];
};

const createNote = async (payload) => {
  return createNoteApi(payload);
};

const useNotes = (projectId) => {
  return useQuery({
    queryKey: ["notes", projectId],
    queryFn: () => fetchNotes(projectId),
    enabled: !!projectId,
  });
};

export { useNotes, createNote };
