import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomListHeader = () => {
    const { t } = useTranslation()
    return (
        <div className='work-drive-page_main_list-header'>
            <div></div>
            <div></div>
            <div>{t("COMMON.SIZE")}</div>
            <div>{t("COMMON.TIME.SIZE")}</div>
        </div>
    );
};

export default CustomListHeader;
