import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getProjects, getProjectServices } from "../api/Api";

const STALE_TIME = 60 * 60 * 1000; // 1hr

const fetchProjects = async () => {
  const response = await getProjects();
  const { projects } = response.data;

  const formatData = projects?.map(p => ({
    projectUserId: p.id,
    services: p.services,
    ...p.projects,
    status: p.status
  }))

  return formatData ?? [];
}

const fetchProjectServices = async (projectId) => {
  if (!projectId) {
    return [];
  }
  const response = await getProjectServices(projectId);

  const { services } = response.data;

  return services ?? []
}

const useProjects = () => {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => fetchProjects(),
    staleTime: STALE_TIME
  })
}

const useSetCurrentProject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (project) => project,
    onSuccess: (project) => {
      queryClient.setQueryData(
        ["currentProject"],
        () => {
          localStorage.setItem('currentProject', JSON.stringify(project));
          return project;
        }
      );
    },
  });
}

const useCurrentProject = () => {
  return useQuery({
    queryKey: ['currentProject'],
    staleTime: STALE_TIME
  })
}

const useProjectServices = (projectId) => {
  return useQuery({
    queryKey: ['projectServices', projectId],
    queryFn: () => fetchProjectServices(projectId),
  })
}

export { useProjects, useCurrentProject, useSetCurrentProject, useProjectServices }
