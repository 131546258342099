import { createPlugin } from "@fullcalendar/core";
import {
  intersectRanges,
  startOfDay,
  addDays,
  sliceEventStore,
  formatDayString,
  sortEventSegs,
} from "@fullcalendar/core/internal";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";

const eventRangesToSegs = (eventRanges, dayRanges, props) => {
  let segs = [];

  for (let eventRange of eventRanges) {
    segs.push(...eventRangeToSegs(eventRange, dayRanges, props));
  }

  return segs;
};

const eventRangeToSegs = (eventRange, dayRanges, props) => {
  let dateEnv = dayjs();
  let { nextDayThreshold } = props;
  let range = eventRange.range;
  let allDay = eventRange.def.allDay;
  let dayIndex;
  let segRange;
  let seg;
  let segs = [];

  for (dayIndex = 0; dayIndex < dayRanges.length; dayIndex += 1) {
    segRange = intersectRanges(range, dayRanges[dayIndex]);

    if (segRange) {
      seg = {
        component: this,
        eventRange,
        start: segRange.start,
        end: segRange.end,
        isStart:
          eventRange.isStart &&
          segRange.start.valueOf() === range.start.valueOf(),
        isEnd:
          eventRange.isEnd && segRange.end.valueOf() === range.end.valueOf(),
        dayIndex,
      };

      segs.push(seg);

      // detect when range won't go fully into the next day,
      // and mutate the latest seg to the be the end.
      if (
        !seg.isEnd &&
        !allDay &&
        dayIndex + 1 < dayRanges.length &&
        dayjs(range.end).diff(
          dateEnv.add(dayRanges[dayIndex + 1].start, nextDayThreshold)
        ) > 0
      ) {
        seg.end = range.end;
        seg.isEnd = true;
        break;
      }
    }
  }

  return segs;
};

const groupSegsByDay = (segs) => {
  let segsByDay = []; // sparse array
  let i;
  let seg;

  for (i = 0; i < segs.length; i += 1) {
    seg = segs[i];
    (segsByDay[seg.dayIndex] || (segsByDay[seg.dayIndex] = [])).push(seg);
  }

  return segsByDay;
};

const computeDateVars = (dateProfile) => {
  let dayStart = startOfDay(dateProfile.renderRange.start);
  let viewEnd = dateProfile.renderRange.end;
  let dayDates = [];
  let dayRanges = [];

  while (dayStart < viewEnd) {
    dayDates.push(dayStart);

    dayRanges.push({
      start: dayStart,
      end: addDays(dayStart, 1),
    });

    dayStart = addDays(dayStart, 1);
  }

  return { dayDates, dayRanges };
};

const eventStoreToSegs = (props, eventStore, eventUiBases, dayRanges) => {
  return eventRangesToSegs(
    sliceEventStore(
      eventStore,
      eventUiBases,
      props.dateProfile.activeRange,
      props.nextDayThreshold
    ).fg,
    dayRanges,
    props
  );
};

const AgendaCustomView = (props) => {
  let { dayDates, dayRanges } = computeDateVars(props.dateProfile);
  let eventSegs = eventStoreToSegs(
    props,
    props.eventStore,
    props.eventUiBases,
    dayRanges
  );

  let segsByDay = groupSegsByDay(eventSegs);

  return (
    <Grid container xs={12}>
      {segsByDay.map((segs, index) => {
        let dayStr = formatDayString(dayDates[index]);
        let daySegs = sortEventSegs(segs, "ASC");

        const monthDate = dayjs(dayStr).format("MMMM D");
        const weekday = dayjs(dayStr).format("dddd");

        return (
          <Grid
            className="agenda-grid"
            container
            item
            xs={12}
            paddingY={2}
            style={{ borderBottom: "1px solid #edeff0" }}
          >
            <Grid item xs={1}>
              <Typography variant="p" fontSize={14} fontWeight={500}>
                {monthDate}
              </Typography>
              <br />
              <Typography variant="p" fontSize={12}>
                {weekday}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              {daySegs.map((daySeg) => {
                const offset = dayjs(daySeg.eventRange.instance.range.start).utcOffset();
                const startTime = dayjs(
                  daySeg.eventRange.instance.range.start
                ).subtract(offset, 'minutes').format("HH:mm");
                const endTime = dayjs(
                  daySeg.eventRange.instance.range.end
                ).subtract(offset, 'minutes').format("HH:mm");
                const text = daySeg.eventRange.def.title;
                const calendarColor = daySeg.eventRange.ui.backgroundColor;
                const eventColor = daySeg.eventRange.ui.borderColor;
                const eventClick =
                  daySeg.eventRange.def.extendedProps.handleEventClick;

                return (
                  <Grid
                    onClick={() =>
                      eventClick({
                        event: {
                          _def: {
                            extendedProps: daySeg.eventRange.def.extendedProps,
                            publicId: daySeg.eventRange.def.publicId
                          },
                        },
                      })
                    }
                    className="agenda-event-content"
                    paddingX={3}
                    paddingY={1}
                    container
                    item
                    xs={12}
                    marginBottom={2}
                    alignItems="center"
                  >
                    <Grid item xs={1}>
                      <Typography variant="p" fontSize={12}>
                        {startTime}
                      </Typography>
                      <br />
                      <Typography variant="p" fontSize={12}>
                        {endTime}
                      </Typography>
                    </Grid>
                    <Grid item xs={0.5}>
                      <div aria-hidden="true" class="fc-list-event-graphic">
                        <div
                          class="fc-list-event-dot"
                          style={{ borderColor: calendarColor }}
                        ></div>
                      </div>
                    </Grid>
                    <Grid item display="flex" xs={10}>
                      <Typography variant="p">
                        <div>{text}</div>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default createPlugin({
  views: {
    listMonth: AgendaCustomView,
  },
});
