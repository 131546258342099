import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CalendarPage from "../../../calendar/CalendarPage";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  handleCloseCalendarWidgetModal,
} from "../../../../../redux/app/appsModalSlice";
import plusIcon from "../../../../../assets/images/my-services/plus.svg";
import expandIcon from "../../../../../assets/images/my-services/expand.svg";
import { getEvents } from "../../../../../api/Api";
import { useCurrentProject } from "../../../../../hooks/projects";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useEvents } from "../../../../../hooks/events.hook";
import { DEFAULT_DATE_FORMAT, SECONDARY_DATE_FORMAT } from "../../../../../constants/datetime";
import emptyListIcon from '../../../../../assets/images/my-services/videoconference/emptylist.svg';

const CalendarBlock = () => {
  const [events, setEvents] = useState([]);
  const { data: currentProject } = useCurrentProject();
  let [, setSearchParams] = useSearchParams();
  const [currentView, setCurrentView] = useState('day');

  const { data: eventsData } = useEvents(currentProject?.id);

  const fullcalendarEvents = useMemo(() => {
    return eventsData?.map((event) => ({
      ...event,
      title: event.text || "(No title)",
      start: event.startDate,
      end: event.endDate,
      backgroundColor: `${event.color}4C`,
      textColor: event.color
    }));
  }, [eventsData]);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [monthHeaderToolbar, setMonthHeaderToolbar] = useState({
    left: "timeGridDay,listWeek,dayGridMonth",
    center: "",
    right: "",
  });

  const handleViewChange = (view) => {
    setCurrentView(view);
    if (view.view.type === "dayGridMonth") {
      setMonthHeaderToolbar({
        left: "timeGridDay,listWeek,dayGridMonth",
        center: "",
        right: "title prev next",
      });
    } else {
      setMonthHeaderToolbar({
        left: "timeGridDay,listWeek,dayGridMonth",
        center: "",
        right: "",
      });
    }
  };

  const currentTime = new Date().toISOString().slice(11, 16);

  const firstPopupTab = useSelector((state) => state.popup.firstPopupTab);
  const secondPopupTab = useSelector((state) => state.popup.secondPopupTab);

  const openCalendarWidgetModal = useSelector(
    (state) => state.appsModal.openCalendarWidgetModal
  );
  const openNotesWidgetModal = useSelector(
    (state) => state.appsModal.openNotesWidgetModal
  );
  const openTasksWidgetModal = useSelector(
    (state) => state.appsModal.openTasksWidgetModal
  );
  const openWorkDriveWidgetModal = useSelector(
    (state) => state.appsModal.openWorkDriveWidgetModal
  );
  const appsModal = useSelector((state) => state.appsModal.openAppsModal);

  const handleCloseCalendarModal = () => {
    dispatch(handleCloseCalendarWidgetModal());
  };

  const customDayHeaderContent = (info) => {
    const dayNum = new Date(info.date).getDate();
    const month = new Date(info.date).toLocaleString("en", {
      month: "long",
    });
    const year = new Date(info.date).getFullYear();
    const dayText = new Date(info.date).toLocaleString("en", {
      weekday: "long",
    });

    return (
      <>
        <span>{dayText}</span>, {dayjs(info.date).format(SECONDARY_DATE_FORMAT)}
      </>
    );
  };

  const customWeekHeaderContent = (info) => {
    const dayNum = new Date(info.date).getDate();
    const today = new Date().getDate();
    const dayText = new Date(info.date).toLocaleString("en", {
      weekday: "long",
    });
    const month = new Date(info.date).toLocaleString("en", {
      month: "long",
    });
    const year = new Date(info.date).getFullYear();

    return (
      <>
        <a className={today === dayNum ? "today" : ""}>
          <span>{dayText}</span>, {dayjs(info.date).format(SECONDARY_DATE_FORMAT)}
        </a>
      </>
    );
  };

  const customMonthHeaderContent = (info) => {
    const dayText = new Date(info.date).toLocaleString("en", {
      weekday: "short",
    });

    return <>{dayText.slice(0, 1)}</>;
  };

  const customAgendaHeaderContent = (info) => {
    const dayNum = new Date(info.date).getDate();
    const dayText = new Date(info.date).toLocaleString("en", {
      weekday: "long",
    });
    const month = new Date(info.date).toLocaleString("en", {
      month: "long",
    });

    return (
      <>
        <div>
          <span>{month}</span> <span>{dayNum}</span>
        </div>

        <div>
          <span>{dayText}</span>
        </div>
      </>
    );
  };

  const handleOpenCreateEventPopup = () => {
    const calendarButton = document.querySelector('[aria-label="Calendar"]');
    calendarButton.click();

    const createEventButtonInterval = setInterval(() => {
      const createEventButton = document.querySelector(
        '[aria-label="calendar create event"]'
      );

      if (createEventButton) {
        createEventButton.click();

        clearInterval(createEventButtonInterval);
      }
    }, 500);
  };

  const handleEventClick = (eventInfo) => {
    const eventId = eventInfo.event._def.publicId;
    const calendarButton = document.querySelector('[aria-label="Calendar"]');
    calendarButton.click();
    if (eventInfo.view.type === "timeGridDay") {
      setSearchParams({ eventId: eventId, view: "timeGridDay" });
    } else if (eventInfo.view.type === "listWeek") {
      setSearchParams({ eventId: eventId, view: "timeGridWeek" });
    } else {
      setSearchParams({ eventId: eventId });
      const createEventButtonInterval = setInterval(() => {
        const createEventButton = document.querySelector(
          '[id="event-dashboard-connector"]'
        );

        if (createEventButton) {
          createEventButton.click();

          clearInterval(createEventButtonInterval);
        }
      }, 500);
    }
  };

  const handleOpenMainCalendar = () => {
    let mainView = 'month';

    switch (currentView.view.type) {
      case "dayGridMonth":
        mainView = 'month';
        break;
      case "listWeek":
        mainView = 'week';
        break;
      case "timeGridDay":
        mainView = 'day';
        break;
      default:
        mainView = 'month';
    }

    const calendarButton = document.querySelector('[aria-label="Calendar"]');
    calendarButton.click();

    const createEventButtonInterval = setInterval(() => {
      const createEventButton = document.querySelector(
        `[id="event-dashboard-connector-open-${mainView}-view"]`
      );

      if (createEventButton) {
        createEventButton.click();

        clearInterval(createEventButtonInterval);
      }
    }, 100);
  }

  // start check there is week view event or not
  const customNoEventWeekView = () => {
    return (
      <div className="calendar-widget__emptyevent">
        <div className="calendar-widget__emptyevent-img">
          <img src={emptyListIcon} alt='empty list' />
        </div>

        <p className="calendar-widget__emptyevent-text">
          You don't have any events for this week.
        </p>
      </div>
    );
  }

  return (
    <>
      <div
        className={`my-services__calendar ${
          openCalendarWidgetModal ||
          appsModal ||
          openNotesWidgetModal ||
          openTasksWidgetModal ||
          openWorkDriveWidgetModal
            ? "back-transparent"
            : ""
        }`}
      >
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          headerToolbar={monthHeaderToolbar}
          viewDidMount={handleViewChange}
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            day: "Day",
            list: "Week",
          }}
          views={{
            dayGridMonth: {
              // name of view
              titleFormat: { year: "numeric", month: "long" },
              dayHeaderContent: customMonthHeaderContent,
            },

            timeGridDay: {
              dayHeaderContent: customDayHeaderContent,
              titleFormat: { day: "2-digit", year: "numeric", month: "long" },
              slotEventOverlap: false,
              displayEventTime: true,
              slotLabelFormat: (e) => {
                return dayjs(e.start.marker).format("HH:mm");
              },
              eventTimeFormat: (e) => {
                const offset = e.start?.marker ? dayjs(e.start.marker).utcOffset() : 0;
                const start = e.start?.marker ? dayjs(e.start.marker).subtract(offset, 'minutes').format("HH:mm") : '';
                return start;
              },
            },

            listWeek: {
              dayHeaderContent: customWeekHeaderContent,
              titleFormat: { year: "numeric", month: "short" },
              noEventsContent: customNoEventWeekView,
              eventTimeFormat: (e) => {
                const offset = e.start?.marker ? dayjs(e.start.marker).utcOffset() : 0;
                const start = e.start?.marker ? dayjs(e.start.marker).subtract(offset, 'minutes').format("HH:mm") : '';
                const end = e.end?.marker ? dayjs(e.end.marker).subtract(offset, 'minutes').format("HH:mm") : '';
                return `${start}-${end}`;
              },
            },

            listMonth: {
              dayHeaderContent: customAgendaHeaderContent,
            },
          }}
          firstDay={1}
          scrollTime={currentTime}
          events={fullcalendarEvents}
          eventTimeFormat={{
            hour: "2-digit",
            hour12: false,
            minute: "2-digit",
            meridiem: "short",
          }}
          allDaySlot={false}
          allDayText="All day"
          initialView="timeGridDay"
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          fixedWeekCount={false}
          weekends={true}
          navLinks={true}
          selectOverlap={true}
          nowIndicator={true}
          showNonCurrentDates={true}
          displayEventTime={true}
          displayEventEnd={true}
          aspectRatio={2}
          eventClick={handleEventClick}
        />

        <div className="react-grid-dragHandleArea"></div>

        <div className="my-services__calendar_more-btn">
          <IconButton aria-label="add" onClick={handleOpenCreateEventPopup}>
            <img src={plusIcon} />
          </IconButton>

          <IconButton aria-label="expand" onClick={handleOpenMainCalendar}>
            <img src={expandIcon} />
          </IconButton>
        </div>
      </div>

      <Modal
        open={openCalendarWidgetModal}
        onClose={handleCloseCalendarModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="apps-modal"
        disableEnforceFocus
      >
        <div
          className={`apps-modal-container ${
            firstPopupTab ? "firstPopupShow" : ""
          } ${secondPopupTab ? "secondPopupShow" : ""}`}
        >
          <CalendarPage handleCloseCalendarModal={handleCloseCalendarModal} />
        </div>
      </Modal>
    </>
  );
};

export default CalendarBlock;
