import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getMeetingRoomByRoomUid,
  verifyPasswordMeetingRoom,
} from "../../../../api/Api";
import { toast } from "react-toastify";
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const MeetVerifyPage = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [room, setRoom] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const roomUid = searchParams.get("meeting");

  const getRoomInfo = async () => {
    try {
      const response = await getMeetingRoomByRoomUid(roomUid);

      if (response.data) {
        const roomData = formatRoomData(response.data.meetingRoom);
        setRoom(roomData);
      }
    } catch (error) {
      console.log("err", error);
    toast.error(t("VIDEO_CONFERENCING.ERROR_LOAD_ROOM"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const formatRoomData = (roomData) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const mtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startAt = dayjs(roomData.startAt).tz(mtimezone);
    let endAt = null;
    if (roomData.endAt) {
      endAt = dayjs(roomData.endAt).tz(mtimezone);
    }

    return {
      ...roomData,
      startAt: startAt,
      endAt: endAt
    };
  };

  useEffect(() => {
    if (roomUid) {
      getRoomInfo();
    }
  }, [roomUid]);

  const roomStarted = useMemo(() => {
    if (dayjs().isAfter(room?.startAt)) {
      return true;
    }
    return false;
  }, [room]);

  const roomEnded = useMemo(() => {
    if (room?.endAt === null) {
      return false;
    }
    if (dayjs().isAfter(room?.endAt)) {
      return true;
    }
    return false;
  }, [room])

  const isRequirePassword = useMemo(() => {
    if (room) {
      const isUsePassword = room.roomUid.split("-")[1];
      return isUsePassword === "RP";
    }
    return true;
  }, [room]);

  useEffect(() => {
    if (!isRequirePassword && roomStarted && !roomEnded) {
      window.location.href = `https://${process.env.REACT_APP_VIDEO_CONNECTOR_DOMAIN}/${room.roomName}`;
    }
  }, [isRequirePassword]);

  const schema = yup
    .object({
      password: yup
        .string()
        .min(8, "Password must contain at least 8 characters")
        .max(32)
        .required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (payload) => {
    try {
      setIsVerifying(true);
      const response = await verifyPasswordMeetingRoom(
        room.id,
        payload.password
      );
      const { data } = response;
      setIsVerifying(false);
      if (data?.isValid) {
        // redirect to meeting room
        toast.success(t("VIDEO_CONFERENCING.VALID_PASSWORD"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        window.location.href = `https://${process.env.REACT_APP_VIDEO_CONNECTOR_DOMAIN}/${room.roomName}`;
      } else {
        toast.error(t("VIDEO_CONFERENCING.INVALID_PASSWORD"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log('errr', error);
      setIsVerifying(false);
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  if (!roomStarted) {
    return (
      <div className="signin">
        <div className="signin-wrapper">
          <div className="cloud signin__block">
            <div className="signin__subtitle">
              {t("VIDEO_CONFERENCING.NOT_STARTED")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (roomEnded) {
    return (
      <div className="signin">
        <div className="signin-wrapper">
          <div className="cloud signin__block">
            <div className="signin__subtitle">
              {t("VIDEO_CONFERENCING.ENDED")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="signin">
      <div className="signin-wrapper">
        <div className="cloud signin__block">
          <div className="signin__logo">{t("VIDEO_CONFERENCING.VERIFY")}</div>
          <div className="signin__subtitle">
            {t("VIDEO_CONFERENCING.SUBTITLE")}
          </div>
          {isRequirePassword && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="site-input-label">
                {t("VIDEO_CONFERENCING.REQUIRED_PASSWORD")}
              </label>
              <div className="signin__form-group" style={{ marginBottom: 25 }}>
                <label className="site-input-label">
                  {t("SIGNIN.PASSWORD")}
                </label>
                <OutlinedInput
                  style={{ width: "100%" }}
                  error={!!errors.password}
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{ fontSize: 16, padding: 0 }}
                        onClick={toggleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityOutlined style={{ fontSize: 16 }} />
                        ) : (
                          <VisibilityOffOutlined style={{ fontSize: 16 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.password?.message && (
                  <Box sx={{ display: "flex", alignItems: "center" }} mt={1}>
                    <ErrorOutlineIcon
                      fontSize="14"
                      sx={{ mr: "5px", color: "#EF4051" }}
                    />
                    <p className="site-input-error">
                      {errors.password?.message}
                    </p>
                  </Box>
                )}

                <div className="signin__form-group login-submit">
                  <LoadingButton
                    className="btn btn--w-100"
                    type="submit"
                    loading={isVerifying}
                    variant="contained"
                    sx={{
                      borderRadius: 35,
                      fontSize: 15,
                      color: "#FFF",
                      fontWeight: "500 !important",
                      height: 45,
                      background: "#51A3FF",
                      textTransform: "none",
                      boxShadow: "none",
                    }}
                  >
                    {t("SIGNIN.SUBMIT_BUTTON")}
                  </LoadingButton>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default MeetVerifyPage;
