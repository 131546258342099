import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  handleCloseAppsModal,
  handleOpenAppModalIsFullPage,
  handleCloseAppModalIsFullPage,
} from "../../../redux/app/appsModalSlice";
import { useSelector } from "react-redux";
import expandIcon from "../../../assets/images/header/expand.png";
import compressIcon from "../../../assets/images/header/compress.png";

const TasksDetailsBar = () => {
  const dispatch = useDispatch();
  const appIsFullPage = useSelector(
    (state) => state.appsModal.appModalIsFullPage
  );

  const openFullPage = () => {
    dispatch(handleOpenAppModalIsFullPage());
  };

  const closeFullPage = () => {
    dispatch(handleCloseAppModalIsFullPage());
  };

  return (
    <div className="cloud-page__header_tasks-page_details-close">
      {!appIsFullPage ? (
        <IconButton aria-label="expand" onClick={openFullPage}>
          <img width={22} height={22} src={expandIcon} alt="expand" />
        </IconButton>
      ) : (
        <IconButton aria-label="compress" onClick={closeFullPage}>
          <img width={22} height={22} src={compressIcon} alt="compress" />
        </IconButton>
      )}

      <IconButton
        aria-label="delete"
        onClick={() => dispatch(handleCloseAppsModal())}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default TasksDetailsBar;
